/* eslint-disable import/no-duplicates */
/* eslint-disable boundaries/element-types */
import { type ComponentPropsWithoutRef } from 'react'

import { cx } from 'class-variance-authority'
import { differenceInMinutes, format, isToday } from 'date-fns'
import { ru } from 'date-fns/locale'

import { ReservationStatusEnum } from '@/entities/reservations'
import { Reservation } from '@/features/reservation'
import { minutesToHourMinutes, nowWithTimezone } from '@/shared/lib'
import { Card, Dot, Icon } from '@/shared/ui'

import { ReservationProgress } from '../ReservationProgress'

import css from './MobileReservationTimeCard.module.css'

type MobileReservationTimeProps = ComponentPropsWithoutRef<'div'> & {
  startDate: Date
  endDate?: Date | null
  status?: ReservationStatus
  isProgressBar?: boolean
  reservationId?: number
}

export default function MobileReservationTimeCard({
  startDate,
  endDate,
  status,
  className,
  reservationId,
  isProgressBar = false
}: MobileReservationTimeProps) {
  const timeDifferenceFromEnd = endDate
    ? differenceInMinutes(endDate, startDate)
    : 0
  const timeDifferenceLeftUntilStart = differenceInMinutes(
    startDate,
    nowWithTimezone()
  )

  const [resHours, resMinutes] = minutesToHourMinutes(timeDifferenceFromEnd)
  const [leftUntilStartHours, leftUntilStartMinutes] = minutesToHourMinutes(
    timeDifferenceLeftUntilStart
  )
  const isLeftOneHoursUntilStart =
    timeDifferenceLeftUntilStart <= 60 && timeDifferenceLeftUntilStart > 0

  return (
    <Card className={cx(css.card, className)}>
      <div className={css.card__body}>
        <div className={css.card__header}>
          <div className={css.card__column}>
            <h4 className={css.card__title}>
              {`${isToday(startDate) ? 'Сегодня, ' : ''}${format(
                startDate,
                isToday(startDate) ? 'EEEEEE' : 'EEEE',
                {
                  locale: ru
                }
              )}`}
            </h4>
            <span className={css.card__text}>
              {format(startDate, 'dd.MM.yyyy')}
            </span>
          </div>
          <div className={css.card__column}>
            <div className={css['card__end-time']}>
              {isLeftOneHoursUntilStart &&
                status === ReservationStatusEnum.confirmed && (
                  <span className={css.card__text_second}>
                    {`через - ${leftUntilStartHours}:${leftUntilStartMinutes}`}
                  </span>
                )}
              <span className={css.card__text}>
                {endDate ? `до ${format(endDate, 'HH:mm')}` : 'По ситуации'}
              </span>
            </div>
            <div className={cx(css['card__start-time'], css.card__text_second)}>
              {endDate && (
                <>
                  <span>{`${resHours ? `${resHours}ч` : ''}${
                    resMinutes ? ` ${resMinutes}м` : ''
                  }`}</span>
                  <Dot />
                </>
              )}
              <span>{format(startDate, 'HH:mm')}</span>
            </div>
          </div>
        </div>
        {isProgressBar && (
          <ReservationProgress
            status={status}
            start={startDate}
            end={endDate}
          />
        )}
      </div>
      {reservationId && (
        <Reservation.MobileTransfer
          variant="tertiary"
          size="lg"
          reservationId={reservationId}
          className={css.button}
        >
          {status === ReservationStatusEnum.inProgress ? (
            <>
              <Icon name="clockPlus" />
              Продлить
            </>
          ) : (
            <>
              <Icon name="clockTransfer" />
              Перенести
            </>
          )}
        </Reservation.MobileTransfer>
      )}
    </Card>
  )
}
