import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { reservationApi } from '../api/reservationApi'
import { isReservationLate } from '../lib'

export enum ReservationByIdPageSteps {
  VIEW,
  EDIT
}

type ReservationByIdSliceState = {
  step: ReservationByIdPageSteps
  reservation?: Reservation
  isLate?: boolean
}

const initialState: ReservationByIdSliceState = {
  step: ReservationByIdPageSteps.VIEW
}

export const reservationByIdSlice = createSlice({
  name: 'reservationById',
  initialState,
  reducers: {
    setReservationByIdPageStep(
      state,
      { payload }: PayloadAction<ReservationByIdPageSteps>
    ) {
      state.step = payload
    },
    resetReservationByIdState: () => initialState
  },
  extraReducers: (builder) => {
    const setReservation = (
      state: ReservationByIdSliceState,
      { payload }: PayloadAction<Reservation>
    ) => {
      state.reservation = payload
      state.isLate = isReservationLate(payload.end_date)
    }

    builder
      .addMatcher(
        reservationApi.endpoints.reservation.matchFulfilled,
        setReservation
      )
      .addMatcher(
        reservationApi.endpoints.updateReservation.matchFulfilled,
        setReservation
      )
  }
})

export const { resetReservationByIdState, setReservationByIdPageStep } =
  reservationByIdSlice.actions
