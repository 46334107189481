import { Reservation } from '@/features/create-update-reservation'
import { Button, Icon } from '@/shared/ui'

import css from './GuestCommentsActions.module.css'

type GuestCommentsActionsProps = {
  guest: Guest
}

export default function GuestCommentsActions({
  guest
}: GuestCommentsActionsProps) {
  return (
    <div className={css.actions}>
      <Reservation.MobileInit size="xs" variant="primary" guest={guest}>
        Забронировать <Icon name="bookmark" />
      </Reservation.MobileInit>
      <Button size="xs">
        Коммент <Icon name="message" />
      </Button>
    </div>
  )
}
