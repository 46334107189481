import { useLocation, useNavigate } from 'react-router-dom'

import {
  ReservationSkipStep,
  resetReservation,
  setReservationGuest,
  setReservationIsNewGuest,
  setReservationSkipStep
} from '@/entities/create-update-reservation'
import {
  SearchGuestSteps,
  setSearchGuestPhone,
  setSearchGuestStep
} from '@/entities/search-guests'
import { formatName, formatPhoneNumberRU, PATHS } from '@/shared/lib'
import { useAppDispatch } from '@/shared/model'
import { Badge, Button, Card, Icon, Tag } from '@/shared/ui'

import css from './CallsCard.module.css'

type CallsCardProps = {
  guest: TelephonyGuest | null
  status: CallStatus
  phoneNewGuest: null | string
  onCloseDrawer: () => void
}

export function CallsCard({
  guest,
  status,
  phoneNewGuest,
  onCloseDrawer
}: CallsCardProps) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  const linkGuest = () => {
    if (!guest) return

    navigate(`${PATHS.guest}/${guest.id}`)
    onCloseDrawer()
  }

  const linkNewGuest = () => {
    if (!phoneNewGuest) return

    dispatch(setSearchGuestPhone(phoneNewGuest))
    navigate(PATHS.guestCreate)
    onCloseDrawer()
  }

  const linkNewReservation = async () => {
    const addGuest = () => {
      if (guest) {
        dispatch(
          setReservationGuest({
            id: guest.id,
            last_name: guest.last_name ?? '',
            first_name: guest.first_name ?? '',
            tags: guest.tags,
            phone: guest.phone,
            comment: guest.comment,
            is_anonymous: false
          })
        )
        if (guest.first_name) {
          dispatch(setReservationSkipStep(ReservationSkipStep.GUEST))
        } else {
          dispatch(setSearchGuestStep(SearchGuestSteps.two))
        }
      } else {
        dispatch(setReservationIsNewGuest(true))
        phoneNewGuest && dispatch(setSearchGuestPhone(phoneNewGuest))
      }
    }

    if (pathname === PATHS.newReservation) {
      dispatch(resetReservation())
      addGuest()
    } else {
      addGuest()
      navigate(PATHS.newReservation)
    }
    onCloseDrawer()
  }

  return (
    <Card className={css.card}>
      <div className={css.card__info}>
        <div className={css.card__info_left}>
          <div className={css.card__guest}>
            <p>
              {guest
                ? formatName(guest.first_name, guest.last_name)
                : 'Новый гость'}
            </p>
            {guest?.tags[0] && (
              <Tag
                className={css.header__badge}
                size="xxs"
                tag={guest.tags[0]}
              />
            )}
            {guest && guest.tags.length > 1 && (
              <Badge size="xxs">+{guest.tags.length - 1}</Badge>
            )}
          </div>
          <p className={css.card__phone}>
            {formatPhoneNumberRU(guest ? guest.phone : phoneNewGuest)}
          </p>
        </div>
        {status === 'calling' && (
          <Badge variant="green">
            <Icon name="phoneCall" />
            Входящий звонок
          </Badge>
        )}
        {status === 'current' && (
          <Badge variant="yellow">
            <Icon name="phone" />
            Идёт разговор
          </Badge>
        )}
      </div>
      <div className={css.card__buttons}>
        {guest ? (
          <Button
            variant="tertiary"
            size="sm"
            contentClassName={css.button__content}
            onClick={linkGuest}
          >
            Карточка пользователя
            <Icon name="user" size={20} />
          </Button>
        ) : (
          <Button
            variant="tertiary"
            size="sm"
            contentClassName={css.button__content}
            onClick={linkNewGuest}
          >
            Создать карточку гостя
            <Icon name="plus" size={20} />
          </Button>
        )}
        <Button
          variant="primary"
          size="sm"
          contentClassName={css.button__content}
          onClick={linkNewReservation}
        >
          Создать бронь
          <Icon name="plus" size={20} />
        </Button>
      </div>
    </Card>
  )
}
