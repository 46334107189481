/* eslint-disable import/no-duplicates */
import { useState } from 'react'

import { cx } from 'class-variance-authority'
import {
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  format,
  isBefore,
  isEqual,
  startOfDay,
  startOfWeek
} from 'date-fns'
import { ru } from 'date-fns/locale'

import {
  selectReservationDate,
  setReservationDate
} from '@/entities/create-update-reservation'
import { nowWithTimezone } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon, Label } from '@/shared/ui'

import css from './ReservationDatePicker.module.css'

type ReservationDatePickerProps = {
  isInProgress?: boolean
}

export function ReservationDatePicker({
  isInProgress = false
}: ReservationDatePickerProps) {
  const [page, setPage] = useState(0)
  const selectedDate = useAppSelector(selectReservationDate)
  const dispatch = useAppDispatch()
  const week = addWeeks(nowWithTimezone(), page)
  const weekInterval = eachDayOfInterval({
    start: startOfWeek(week, { weekStartsOn: 1 }),
    end: endOfWeek(week, { weekStartsOn: 1 })
  })

  const nextWeek = () => {
    setPage((prev) => prev + 1)
  }

  const prevWeek = () => {
    setPage((prev) => prev - 1)
  }

  const renderMonth = () => {
    const firstMonth = weekInterval[0].getMonth()
    const lastMonth = weekInterval[weekInterval.length - 1].getMonth()
    const firstYear = weekInterval[0].getFullYear()
    const lastYear = weekInterval[weekInterval.length - 1].getFullYear()

    if (firstYear !== lastYear) {
      return `${format(weekInterval[0], 'LLLL yyyy', { locale: ru })} -
        ${format(weekInterval[weekInterval.length - 1], 'LLLL yyyy', {
          locale: ru
        })}`
    }

    if (firstMonth !== lastMonth) {
      return `${format(weekInterval[0], 'LLLL', { locale: ru })} -
        ${format(weekInterval[weekInterval.length - 1], 'LLLL yyyy', {
          locale: ru
        })}`
    }

    return format(weekInterval[0], 'LLLL yyyy', { locale: ru })
  }

  const selectDateHandler = (date: Date) => {
    dispatch(setReservationDate(date))
  }

  return (
    <div className={css.picker}>
      <div className={css.picker__header}>
        <Label className={css.picker__title}>{renderMonth()}</Label>
        <Button size="icon-sm" variant="tertiary" onClick={prevWeek}>
          <Icon name="arrowLeft" size={20} />
        </Button>
        <Button size="icon-sm" variant="tertiary" onClick={nextWeek}>
          <Icon name="arrowRight" size={20} />
        </Button>
      </div>
      <div className={css.picker__week}>
        {weekInterval.map((date) => (
          <div key={date.getTime()} className={css.picker__week__item}>
            <span className={css.picker__date}>
              {format(date, 'iiiiii', { locale: ru })}
            </span>
            <Button
              size="icon-sm"
              className={cx(css.picker__day, {
                [css.picker__day_active]:
                  selectedDate && isEqual(date, startOfDay(selectedDate))
              })}
              disabled={
                isBefore(date, startOfDay(nowWithTimezone())) || isInProgress
              }
              onClick={() => selectDateHandler(date)}
            >
              {format(date, 'd')}
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}
