import { type ComponentPropsWithoutRef, type PropsWithChildren } from 'react'

import { selectTableToChangeTo } from '@/entities/map'
import { useUpdateReservationMutation } from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import { Button, type ButtonStyleProps } from '@/shared/ui'

type ChangeTableButtonProps = ComponentPropsWithoutRef<'button'> &
  ButtonStyleProps &
  PropsWithChildren & {
    reservationId: number
    updateReservationsList?: (
      reservationId: number,
      isDelete: boolean,
      data?: Partial<Reservation>
    ) => void
  }

export function ChangeTableButton({
  reservationId,
  children,
  updateReservationsList,
  ...props
}: ChangeTableButtonProps) {
  const tableToChangeTo = useAppSelector(selectTableToChangeTo)
  const addressId = useAppSelector(selectSelectedAddressId)
  const [update, { isLoading }] = useUpdateReservationMutation()

  const changeTablesHandler = () => {
    if (!reservationId || Number.isNaN(+reservationId) || !addressId) return
    update({
      addressId,
      reservationId: +reservationId,
      body: {
        places: [...tableToChangeTo.map((table) => table.id)]
      }
    })
    updateReservationsList?.(reservationId, false, {
      main_table_number: tableToChangeTo[0].item_number
    })
  }

  return (
    <Button isLoading={isLoading} onClick={changeTablesHandler} {...props}>
      {children}
    </Button>
  )
}
