/* eslint-disable boundaries/element-types */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { hallsApi } from '@/entities/halls'

type NewWaitingListRecordSliceState = {
  step: NewWaitingListRecordStep
  guest?: BaseGuest
  comment?: string
  hallId?: number
  duration?: number | null
  personsCount: number
}

export enum NewWaitingListRecordSteps {
  one = 1,
  two = 2
}

const initialState: NewWaitingListRecordSliceState = {
  step: 1,
  personsCount: 1,
  duration: 30
}

export const newWaitingListRecordSlice = createSlice({
  name: 'newWaitingListRecord',
  initialState,
  reducers: {
    addNewWaitingListRecordGuest: (
      state,
      { payload }: PayloadAction<BaseGuest>
    ) => {
      state.guest = payload
    },
    setNewWaitingListRecordStep(
      state,
      { payload }: PayloadAction<NewWaitingListRecordStep>
    ) {
      state.step = payload
    },
    setNewWaitingListHallId(state, { payload }: PayloadAction<number>) {
      state.hallId = payload
    },
    setNewWaitingListPersonsCount: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.personsCount = payload
    },
    setNewWaitingListDuration: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.duration = payload
    },
    resetNewWaitingListRecord: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      hallsApi.endpoints.halls.matchFulfilled,
      (state: NewWaitingListRecordSliceState, { payload }) => {
        state.hallId = payload[0]?.id
      }
    )
  }
})

export const {
  setNewWaitingListRecordStep,
  addNewWaitingListRecordGuest,
  resetNewWaitingListRecord,
  setNewWaitingListHallId,
  setNewWaitingListPersonsCount,
  setNewWaitingListDuration
} = newWaitingListRecordSlice.actions
