/* eslint-disable boundaries/element-types */

import { type MouseEvent, useState } from 'react'

import {
  Button,
  type ButtonStyleProps,
  Icon,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'
import { MobileRequestConfirmProcess } from '@/widgets/requests'

type SitGuestVaulProps = ButtonStyleProps & {
  id: number
  className?: string
}

export default function SitGuestVaul({
  id,
  className,
  ...props
}: SitGuestVaulProps) {
  const [open, setOpen] = useState(false)

  const successHandler = () => {
    setOpen(false)
  }

  const toggleDialogHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  return (
    <Vaul open={open} onClose={() => setOpen(false)}>
      <VaulTrigger asChild>
        <Button className={className} onClick={toggleDialogHandler} {...props}>
          <Icon name="restaurant" />
          Выбрать столик
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen onClose={toggleDialogHandler}>
        <MobileRequestConfirmProcess
          requestId={id}
          onComplete={successHandler}
        />
      </VaulContent>
    </Vaul>
  )
}
