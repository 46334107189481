import { type MouseEvent, type PropsWithChildren, useState } from 'react'

import { useDeleteReservationMutation } from '@/entities/reservation'
import {
  selectCanEditAddress,
  selectSelectedAddressId
} from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  type ButtonStyleProps
} from '@/shared/ui'

type DeleteReservationProps = ButtonStyleProps &
  PropsWithChildren & {
    reservationId: number | string | undefined
    className?: string
    updateReservationsList?: (
      reservationId: number,
      isDelete: boolean,
      data?: Partial<Reservation>
    ) => void
    onSuccess?: () => void
  }

export function DeleteReservation({
  children,
  reservationId,
  size,
  className,
  updateReservationsList,
  onSuccess,
  ...props
}: DeleteReservationProps) {
  const [open, setOpen] = useState(false)
  const addressId = useAppSelector(selectSelectedAddressId)
  const canEditAddress = useAppSelector(selectCanEditAddress)

  const [deleteReservation, { isLoading }] = useDeleteReservationMutation()

  const toggleDialogHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  const deleteReservationHandler = async (e: MouseEvent) => {
    e.stopPropagation()
    if (!reservationId || Number.isNaN(+reservationId) || !addressId) return

    try {
      await deleteReservation({
        addressId,
        reservationId: +reservationId
      })
      updateReservationsList?.(+reservationId, true)
      setOpen(false)
      onSuccess?.()
    } catch {
      // do nothing
    }
  }

  return (
    <AlertDialog open={open}>
      <AlertDialogTrigger asChild>
        <Button
          onClick={toggleDialogHandler}
          disabled={!canEditAddress}
          variant="transparentRed"
          size={size}
          className={className}
          {...props}
        >
          {children}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Вы уверены ?</AlertDialogTitle>
          <AlertDialogDescription>
            Если вы&nbsp;завершите, то&nbsp;удалите информацию о&nbsp;брони, это
            действие будет невозможно отменить
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              onClick={deleteReservationHandler}
            >
              Удалить
            </Button>
          </AlertDialogAction>
          <AlertDialogCancel disabled={isLoading} onClick={toggleDialogHandler}>
            Отмена
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
