import { type Dispatch, type SetStateAction, useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import {
  useDeleteGuestMutation,
  useUpdateGuestMutation
} from '@/entities/guest'
import { selectSelectedChainId } from '@/entities/session'
import { TagsPicker } from '@/entities/tags'
import { useAppSelector } from '@/shared/model'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Icon,
  Input,
  PhoneInput,
  VaulFooter
} from '@/shared/ui'

import {
  type EditGuestFormSchema,
  editGuestFormSchema
} from '../model/editGuestFormSchema'

import css from './EditGuestForm.module.css'

type EditGuestFormProps = {
  guest: Guest
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function EditGuestForm({ guest, setOpen }: EditGuestFormProps) {
  const chainId = useAppSelector(selectSelectedChainId)

  const { id, first_name, last_name, phone, tags } = guest

  const form = useForm<EditGuestFormSchema>({
    resolver: zodResolver(editGuestFormSchema),
    defaultValues: {
      firstName: first_name,
      lastName: last_name,
      phone: phone ?? '',
      tags: tags.map((tag) => String(tag.id))
    }
  })

  const [updateGuest, { isLoading: isUpdating, isSuccess: isUpdateSuccess }] =
    useUpdateGuestMutation()
  const [deleteGuest, { isLoading: isDeleting }] = useDeleteGuestMutation()

  useEffect(() => {
    if (isUpdateSuccess) setOpen(false)
  }, [isUpdateSuccess])

  const submitHandler = ({
    firstName,
    lastName,
    phone,
    tags
  }: EditGuestFormSchema) => {
    if (!chainId) return

    updateGuest({
      chainId,
      guestId: id,
      body: {
        first_name: firstName,
        last_name: lastName,
        phone: `+${phone}`,
        tags
      }
    })
  }

  const deleteGuestHandler = () => {
    if (!chainId) return
    deleteGuest({ chainId, guestId: id })
  }

  const isLoading = isUpdating || isDeleting

  return (
    <div className={css.form}>
      <h2 className={css.form__title}>Изменение гостя</h2>
      <Form {...form}>
        <form
          className={css.form__content}
          onSubmit={form.handleSubmit(submitHandler)}
          noValidate
        >
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Введите имя" label="Имя" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder="Введите фамилию"
                    label="Фамилия"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <PhoneInput
                    value={field.value}
                    onValueChange={field.onChange}
                    placeholder="Введите телефон"
                    label="Телефон"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tags"
            render={({ field }) => (
              <FormItem>
                <FormLabel className={css.tags__label}>Тип</FormLabel>
                <FormControl>
                  <TagsPicker {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <VaulFooter>
            <div className={css.form__actions}>
              <Button
                type="submit"
                size="xs"
                variant="primary"
                disabled={isLoading}
                isLoading={isUpdating}
              >
                Сохранить
                <Icon name="check" />
              </Button>
              <Button
                type="button"
                size="xs"
                variant="red"
                disabled={isLoading}
                isLoading={isDeleting}
                onClick={deleteGuestHandler}
              >
                Удалить
                <Icon name="trash" />
              </Button>
            </div>
          </VaulFooter>
        </form>
      </Form>
    </div>
  )
}
