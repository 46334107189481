import { minutesInHour } from 'date-fns'

export const config = {
  HTTP_API_ENDPOINT: import.meta.env.VITE_HTTP_API_ENDPOINT,
  WS_API_ENDPOINT: import.meta.env.VITE_WS_API_ENDPOINT,
  FEATURE_FLAG: Boolean(+import.meta.env.VITE_FEATURE_FLAG),
  FEATURE_FLAG_LOGS: Boolean(+import.meta.env.VITE_FEATURE_FLAG_LOGS),
  DEMO_CHAIN_ID: +import.meta.env.VITE_DEMO_CHAIN_ID,
  DEBOUNCE_DELAY: 500, // ms
  TIMER_COUNTDOWN_SEC: 900, // s
  WORKING_HOURS_START: 0,
  WORKING_HOURS_END: 24,
  MIN_RESERVATION_TIME_MIN: 30,
  MAX_PERSONS_COUNT: 6,
  SPLIT_HOUR_BY: 60,
  SPLITTED_HOUR_INTERVAL: Math.floor(minutesInHour / 60),
  GROUP_HOUR_COEFFICIENT: 15,
  TIMELINE_CELL_WIDTH: 80,
  PICKERS_SPLIT_HOUR_BY: 4,
  PICKERS_INTERVAL: 15,
  MIN_DURATION_INTERVAL_MIN: 60,
  MAX_MOBILE_WIDTH: 600,
  DEFAULT_ERROR_MES: {
    title: 'Что то пошло не так',
    message: 'Произошла ошибка на сервере...'
  }
} as const

export const INFINITE_SCROLL_RES = 30
export const MAX_PHONE_SIZE = 11
export const MIN_COMING_SOON_TIME = 60
export const MIN_RECENTLY_COMPLETED_TIME = 60
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const NODE_ENV = import.meta.env.VITE_NODE_ENV ?? 'development'
export const TOAST_CLOSE_TIME = 2000 // ms
