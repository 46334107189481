import { type Ref, useEffect, useState } from 'react'

import { isMobile } from 'react-device-detect'
import { IMaskMixin } from 'react-imask'

import {
  selectReservationPersonsCount,
  setReservationPersonsCount
} from '@/entities/create-update-reservation'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon, Input, Label } from '@/shared/ui'

import css from './PersonsPicker.module.css'

const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }) => (
  <Input {...props} ref={inputRef as Ref<HTMLInputElement>} />
))

export function PersonsPicker() {
  const [isClickButton, setIsClickButton] = useState(false)
  const personsCount = useAppSelector(selectReservationPersonsCount)
  const dispatch = useAppDispatch()

  const inputChangeHandler = (persons: string) => {
    if (!isClickButton) {
      dispatch(setReservationPersonsCount(+persons))
    }
    setIsClickButton(false)
  }

  const onAddPersonHandler = () => {
    if (!personsCount) return

    setIsClickButton(true)
    dispatch(setReservationPersonsCount(personsCount + 1))
  }

  const onSubPersonHandler = () => {
    if (!personsCount) return

    setIsClickButton(true)
    dispatch(setReservationPersonsCount(personsCount - 1))
  }

  useEffect(() => {
    if (!personsCount || personsCount === 0) {
      dispatch(setReservationPersonsCount(1))
    }
  }, [personsCount])

  return (
    <div className={css.picker}>
      <Label className={css.picker__label}>Кол-во персон</Label>
      <div className={css.picker__counter}>
        <Button
          type="button"
          variant="tertiary"
          size={isMobile ? 'icon' : 'icon-sm'}
          disabled={!personsCount || personsCount === 1}
          onClick={onSubPersonHandler}
        >
          <Icon name="minus" size={20} />
        </Button>
        <MaskedStyledInput
          className={css.input}
          mask={Number}
          onAccept={inputChangeHandler}
          overwrite
          value={String(personsCount)}
          min={1}
          max={99}
        />
        <Button
          type="button"
          variant="tertiary"
          size={isMobile ? 'icon' : 'icon-sm'}
          disabled={!personsCount || personsCount === 99}
          onClick={onAddPersonHandler}
        >
          <Icon name="plus" size={20} />
        </Button>
      </div>
    </div>
  )
}
