import { type Dispatch, type SetStateAction } from 'react'

import { format } from 'date-fns'

import { Reservation } from '@/features/create-update-reservation'
import { Icon } from '@/shared/ui'

import css from './EmptyTable.module.css'

type EmptyTableProps = {
  nextReservationTime?: Date
  setOpen: Dispatch<SetStateAction<boolean>>
  table: MapItem
}

export default function EmptyTable({
  nextReservationTime,
  setOpen,
  table
}: EmptyTableProps) {
  return (
    <div className={css.free}>
      <span className={css.free__message}>
        Столик свободен до{' '}
        {nextReservationTime
          ? format(nextReservationTime, 'HH:mm')
          : 'конца дня'}
      </span>
      <Reservation.MobileInit
        setOpen={setOpen}
        size="xs"
        variant="primary"
        table={table}
      >
        Забронировать
        <Icon name="bookmark" />
      </Reservation.MobileInit>
    </div>
  )
}
