/* eslint-disable import/no-duplicates */
import { type ApexOptions } from 'apexcharts'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { formatRuWord } from '@/shared/lib'

import { weekdays } from '../../lib'
import {
  chartGlobal,
  globalOptions,
  xaxisGlobal,
  yaxisGlobalStyles
} from '../config'
import { ChartTooltip } from '../tooltipConfig'

export const options: ApexOptions = {
  ...globalOptions,
  plotOptions: {
    bar: {
      borderRadius: 6,
      borderRadiusApplication: 'end',
      columnWidth: '60%'
    }
  },
  colors: ['var(--accent-color)'],
  chart: {
    ...chartGlobal
  },
  grid: {
    borderColor: 'var(--background-color)'
  },
  yaxis: {
    min: 0,
    tickAmount: 9,
    labels: {
      ...yaxisGlobalStyles
    }
  },
  xaxis: {
    ...xaxisGlobal,
    type: 'category',
    tickAmount: 6,
    tickPlacement: 'on',
    categories: weekdays,
    labels: {
      style: {
        cssClass: 'text_xs text_normal',
        colors: Array(7).fill('var(--gray-4)')
      },
      datetimeFormatter: {
        month: 'dd.MM',
        day: 'dd.MM'
      },
      formatter: (value) => {
        if (!value) return ''
        const date = new Date(value)
        const formattedDate = format(date, 'iiiiii', { locale: ru })
        return formattedDate.slice(0, 1).toUpperCase() + formattedDate.slice(1)
      }
    }
  },
  tooltip: {
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      const avg = Math.floor(
        series[seriesIndex].reduce((acc: number, cur: number) => acc + cur, 0) /
          series[seriesIndex].length
      )
      const cur = series[seriesIndex][dataPointIndex]
      const diff = cur - avg
      const curMessage = `${cur} ${formatRuWord(cur, [
        'гость',
        'гостя',
        'гостей'
      ])}`
      const diffMessage = `${diff > 0 ? '+' : ''}${diff} от среднего`

      return ChartTooltip(curMessage, diffMessage)
    }
  },
  states: {
    active: {
      filter: {
        type: 'none'
      }
    }
  }
}

export const series = [
  {
    name: 'new_guests',
    data: [23, 12, 13, 32, 18, 20, 14]
  }
]
