/* eslint-disable boundaries/element-types */
import {
  type Dispatch,
  type FormEvent,
  type SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'

import { cx } from 'class-variance-authority'

import { Button, Card, Icon, Textarea } from '@/shared/ui'

import css from './MobileTextAreaComments.module.css'

type MobileTextAreaCommentsProps = {
  commentUpdate: UserComment | null
  isReservation?: boolean
  setCommentUpdate: Dispatch<SetStateAction<UserComment | null>>
  updateCommentHandle: (id: number, text: string) => Promise<void>
  createCommentHandle: (text: string) => Promise<void>
}

export default function MobileTextAreaComments({
  commentUpdate,
  setCommentUpdate,
  updateCommentHandle,
  createCommentHandle
}: MobileTextAreaCommentsProps) {
  const [value, setValue] = useState<string>('')
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      setValue('')
      if (commentUpdate) {
        setCommentUpdate(null)
        await updateCommentHandle(commentUpdate.id, value)
      } else {
        await createCommentHandle(value)
      }
    } catch {
      // do nothing
    }
  }

  const cancelUpdateComment = () => {
    setCommentUpdate(null)
    setValue('')
  }

  useEffect(() => {
    if (commentUpdate && textareaRef.current) {
      setValue(commentUpdate.text)
    }
  }, [commentUpdate])

  return (
    <form className={css.form} onSubmit={onSubmit}>
      {commentUpdate && (
        <Card className={css.edit}>
          <Icon name="pencil" />
          {commentUpdate.text}
          <Button
            type="button"
            variant="transparent"
            className={css.edit__close}
            onClick={cancelUpdateComment}
          >
            <Icon name="x" />
          </Button>
        </Card>
      )}
      <div
        className={cx(css.container, {
          [css.container_focused]: value
        })}
      >
        <Textarea
          ref={textareaRef}
          className={css.textarea}
          placeholder="Введите ваш комментарий"
          autoResize
          value={value}
          setValue={setValue}
        />
        {value && (
          <Button
            className={css.button_send}
            variant="primary"
            size="icon"
            type="submit"
            disabled={!value || value === commentUpdate?.text}
          >
            <Icon name="send" />
          </Button>
        )}
      </div>
    </form>
  )
}
