import {
  formatAge,
  formatBirthDate,
  formatNumber,
  formatPrice
} from '@/shared/lib'
import { Badge } from '@/shared/ui'

import css from './GuestInfoBadges.module.css'

interface SerializedGuestInfo {
  total_revenue: string
  average_bill: string
  visit_count: string
  reservation_count: string
  canceled_visits: string
  age: string
  birthdate: string
}

const guestInfoConfig: Array<{
  name: keyof SerializedGuestInfo
  label: string
}> = [
  { name: 'total_revenue', label: 'Выручка' },
  { name: 'average_bill', label: 'Средний чек' },
  { name: 'visit_count', label: 'Посещений' },
  { name: 'reservation_count', label: 'Броней' },
  { name: 'canceled_visits', label: 'Отмен' },
  { name: 'age', label: 'Возраст' },
  { name: 'birthdate', label: 'Дата рождения' }
]

type GuestInfoBadgesProps = {
  guest: Guest
}

export default function GuestInfoBadges({ guest }: GuestInfoBadgesProps) {
  const {
    total_revenue,
    average_bill,
    visit_count,
    canceled_visits,
    birthdate
  } = guest

  const serializedGuestInfo: SerializedGuestInfo = {
    total_revenue: formatPrice(total_revenue),
    average_bill: formatPrice(average_bill),
    visit_count: formatNumber(visit_count),
    reservation_count: formatNumber(visit_count),
    canceled_visits: formatNumber(canceled_visits),
    age: formatAge(birthdate),
    birthdate: formatBirthDate(birthdate)
  }

  return (
    <div className={css.info}>
      {guestInfoConfig.map(({ name, label }) => (
        <Badge key={name} className={css.badge} size="md">
          <div className={css.badge__content}>
            <span className={css.badge__value}>
              {serializedGuestInfo[name]}
            </span>
            <span className={css.badge__label}>{label}</span>
          </div>
        </Badge>
      ))}
    </div>
  )
}
