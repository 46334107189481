import { cx } from 'class-variance-authority'

import css from './Cell.module.css'

interface RowsProps {
  day?: number
  isCurrentDay?: boolean
  isSelectedDay?: boolean
  isSelectStart?: boolean
  isStartDaySelected?: boolean
  onClick?: (day?: number) => void
}

export function Cell({
  day,
  isCurrentDay,
  isSelectedDay,
  isSelectStart,
  isStartDaySelected,
  onClick
}: RowsProps) {
  return (
    <td className={css.root} onClick={() => onClick?.(day)}>
      <div
        className={cx(css.cell, {
          [css.cell_pointer]: day,
          [css.cell_current_day]: isCurrentDay,
          [css.cell_selected_day]: isSelectedDay,
          [css.cell_select_start]: isSelectStart,
          [css.cell_start_day]: isStartDaySelected
        })}
      >
        {day ?? null}
      </div>
    </td>
  )
}
