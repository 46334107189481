import { minutesToHourMinutes } from './minutesToHourMinutes'

export const formatHoursAndMinutes = (hours: number, minutes: number) => {
  return `${hours}:${String(minutes).padStart(2, '0')}`
}

export const formatMinToHoursAndMin = (min: number) => {
  const [hours, minutes] = minutesToHourMinutes(min)
  return `${hours}:${String(minutes).padStart(2, '0')}`
}
