import { eachHourOfInterval, startOfToday } from 'date-fns'

import { splitHours } from '@/shared/lib'

export function generateSteps(hourStart: number, hourEnd: number) {
  const startDate = startOfToday()
  const endDate = startOfToday()

  startDate.setHours(hourStart)
  endDate.setHours(hourEnd - 1)

  const interval = {
    start: startDate,
    end: endDate
  }

  return splitHours(eachHourOfInterval(interval), 6)
}
