import { differenceInMinutes, format, parseISO } from 'date-fns'

import { formatDuration, formatPrice } from '@/shared/lib'
import { Button, Card, Dot } from '@/shared/ui'

import css from './MobileVisitCard.module.css'

interface SerializedVisitInfo {
  main_table_number: string
  persons_count: number
  total_bill: string
}

function formatVisitTime(start: string, end: string) {
  return `${format(parseISO(start), 'HH:mm')} - ${format(
    parseISO(end),
    'HH:mm'
  )} (${formatDuration(differenceInMinutes(parseISO(end), parseISO(start)))})`
}

const visitInfoConfig: Array<{
  name: keyof SerializedVisitInfo
  label: string
}> = [
  { name: 'main_table_number', label: 'Столик' },
  { name: 'persons_count', label: 'Персоны' },
  { name: 'total_bill', label: 'Сумма' }
]

type MobileVisitCardProps = {
  visit: Visit
}

export default function MobileVisitCard({ visit }: MobileVisitCardProps) {
  const {
    id,
    main_table_number,
    persons_count,
    total_bill,
    start_date,
    end_date
  } = visit

  const serializedVisitInfo: SerializedVisitInfo = {
    main_table_number: `№${main_table_number}`,
    persons_count,
    total_bill: formatPrice(total_bill)
  }

  return (
    <div className={css.visit}>
      <Card className={css.visit__info}>
        <div className={css.visit__header}>
          <span>{formatVisitTime(start_date, end_date)}</span>
          <Dot />
          <span>{format(parseISO(start_date), 'dd.MM.yyyy')}</span>
        </div>
        <div className={css.table}>
          {visitInfoConfig.map(({ name, label }) => (
            <div className={css.table__row}>
              <span className={css.table__label}>{label}</span>
              <span className={css.table__value}>
                {serializedVisitInfo[name]}
              </span>
            </div>
          ))}
        </div>
      </Card>
      <Button type="button" size="xs">
        Бронь №{id}
      </Button>
    </div>
  )
}
