interface RowsProps {
  rows: JSX.Element[][]
}

export function Rows({ rows }: RowsProps) {
  return (
    <>
      {rows.map((row, idx) => {
        return <tr key={idx}>{row}</tr>
      })}
    </>
  )
}
