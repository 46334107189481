import { ReservationDatePicker as DatePicker } from './date-picker'
import { DurationPicker } from './duration-picker'
import { GoToConfirmReservationButton } from './go-to-confirm-reservation-button'
import { GoToPickGuestReservationButton } from './go-to-pick-guest-reservation-button'
import { InitNewReservationButton as Init } from './init-create-reservation'
import { MobileCloseCreate } from './mobile-close-create'
import { MobileDurationPicker } from './mobile-duration-picker'
import { InitReservationButton as MobileInit } from './mobile-init-reservation-button'
import { MobileTimePicker } from './mobile-time-picker'
import { PersonsPicker } from './persons-picker'
import { SaveReservationButton as Save } from './save-reservation-button'
import { SubmitReservationForm as Submit } from './submit-reservation-form'
import { TablePicker } from './table-picker'
import { TimePicker } from './time-picker'

export const Reservation = {
  DatePicker,
  PersonsPicker,
  DurationPicker,
  TablePicker,
  TimePicker,
  GoToPickGuestReservationButton,
  GoToConfirmReservationButton,
  Submit,
  Save,
  Init,
  MobileInit,
  MobileDurationPicker,
  MobileTimePicker,
  MobileCloseCreate
}
