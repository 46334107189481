import { type ApexOptions } from 'apexcharts'

export const globalOptions: ApexOptions = {
  theme: {
    mode: 'dark'
  },
  dataLabels: {
    enabled: false
  }
}

export const xaxisGlobal: ApexXAxis = {
  axisBorder: {
    show: false
  },
  axisTicks: {
    show: false
  }
}

export const yaxisGlobalStyles = {
  style: {
    cssClass: 'text_xs text_normal',
    colors: ['var(--gray-4)']
  }
}

export const chartGlobal: ApexChart = {
  toolbar: {
    show: false
  },
  zoom: {
    enabled: false
  },
  background: 'var(--gray-2)'
}
