import { Guest } from '@/features/guest'
import { formatName } from '@/shared/lib'
import { Tag, VaulHeader } from '@/shared/ui'

import css from './GuestVaulCardHeader.module.css'

type GuestVaulCardHeaderProps = {
  guest: Guest
}

export default function GuestVaulCardHeader({
  guest
}: GuestVaulCardHeaderProps) {
  const { first_name, last_name, tags } = guest

  return (
    <VaulHeader className={css.header}>
      {tags[0] && <Tag className={css.header__badge} size="xs" tag={tags[0]} />}
      <div className={css.header__heading}>
        <h4 className={css.header__title}>
          {formatName(first_name, last_name)}
        </h4>
        <Guest.MobileEdit guest={guest} />
      </div>
    </VaulHeader>
  )
}
