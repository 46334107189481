import { skipToken } from '@reduxjs/toolkit/dist/query'
import { cx } from 'class-variance-authority'

// import { config } from '@/app/config'
import { selectHall, selectSelectedHall, useHallsQuery } from '@/entities/halls'
import { selectSelectedAddressId } from '@/entities/session'
import { formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Skeleton, Tab, TabsGroup, TabsProvider } from '@/shared/ui'

import css from './MobileMapFooter.module.css'

// const { MAX_PERSONS_COUNT } = config

type MobileMapFooterProps = {
  start?: Date
  end?: Date
  personsCount?: number
}

export default function MobileMapFooter({
  start,
  end,
  personsCount
}: MobileMapFooterProps) {
  const dispatch = useAppDispatch()
  const addressId = useAppSelector(selectSelectedAddressId)
  const selectedHall = useAppSelector(selectSelectedHall)

  const { data, isFetching } = useHallsQuery(
    addressId
      ? {
          addressId,
          start_date: formatTimeForRequest(start),
          end_date: formatTimeForRequest(end)
          // persons:
          //   personsCount && personsCount <= MAX_PERSONS_COUNT
          //     ? personsCount
          //     : undefined
        }
      : skipToken
  )

  const valueChangeHandler = (value: string) => {
    dispatch(selectHall(+value))
  }

  const tabsHallData = data?.map((hall) => ({
    id: hall.id,
    label: hall.name,
    count: hall.count
  }))

  return (
    <div className={css.footer}>
      {!isFetching && tabsHallData && selectedHall ? (
        <TabsProvider
          value={selectedHall.toString()}
          onValueChange={valueChangeHandler}
        >
          <TabsGroup variant="borderless">
            {tabsHallData.map((tab) => (
              <Tab
                key={tab.id}
                variant="primary"
                className={css.tab}
                value={tab.id.toString()}
              >
                {tab.label}
                <span
                  className={cx(css.tab__count, {
                    [css.tab__count_active]: tab.id === selectedHall
                  })}
                >
                  {tab.count}
                </span>
              </Tab>
            ))}
          </TabsGroup>
        </TabsProvider>
      ) : (
        <div className={css.skeleton}>
          {Array(2)
            .fill(null)
            .map((_, idx) => (
              <Skeleton key={idx} className={css.skeleton__tab} />
            ))}
        </div>
      )}
    </div>
  )
}
