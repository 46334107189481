import { createSelector } from '@reduxjs/toolkit'

import { sortTables } from '../lib'

export const selectHours = (state: RootState) => state.timeline.hours
export const selectTables = createSelector(
  (state: RootState) => state.timeline.tables,
  (state: RootState) => state.timeline.sortType,
  (tables, sortType) => sortTables(tables, sortType)
)

export const selectMinutesLinePosition = (state: RootState) =>
  state.timeline.minutesLinePosition
export const selectCurDate = (state: RootState) => state.timeline.curDate
export const selectSelectedTimelineTable = (state: RootState) =>
  state.timeline.selectedTable
export const selectTimelineSort = (state: RootState) => state.timeline.sortType
export const selectTablesTimeSlots = (state: RootState) =>
  state.timeline.tablesTimeSlots

export const selectSelectedTableTimeSlots = (state: RootState) =>
  state.timeline.selectedTableTimeSlots

export const selectSelectedTimelineTables = (state: RootState) =>
  state.timeline.tables
