import { type ComponentPropsWithoutRef, type PropsWithChildren } from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import css from './Badge.module.css'

const badgeStyle = cva(css.badge, {
  variants: {
    variant: {
      default: css.badge_variantDefault,
      primary: css.badge_variantPrimary,
      tertiary: css.badge_variantTertiary,
      green: css.badge_variantGreen,
      orange: css.badge_variantOrange,
      red: css.badge_variantRed,
      tintedBlue: css.badge_variantTintedBlue,
      blue: css.badge_variantBlue,
      gray: css.badge_variantGray,
      white: css.badge_variantWhite,
      yellow: css.badge_variantYellow
    },
    size: {
      xxs: css.badge_sizeXxs,
      xs: css.badge_sizeXs,
      sm: css.badge_sizeSm,
      md: css.badge_sizeMd,
      lg: css.badge_sizeLg,
      xl: css.badge_sizeXl,
      icon: css.badge_sizeIcon
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'sm'
  }
})

export type BadgeStyleProps = VariantProps<typeof badgeStyle>

type BadgeProps = PropsWithChildren &
  ComponentPropsWithoutRef<'div'> &
  BadgeStyleProps

export function Badge({
  children,
  variant,
  size,
  className,
  ...props
}: BadgeProps) {
  return (
    <div className={badgeStyle({ variant, size, className })} {...props}>
      {children}
    </div>
  )
}
