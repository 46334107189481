import { cx } from 'class-variance-authority'

import { ReservationStatusEnum } from '@/entities/reservations'
import { Reservation } from '@/features/reservation'
import { Badge, Icon } from '@/shared/ui'

import css from './ReservationActions.module.css'

type ReservationActionsProps = {
  reservationId: number
  status: ReservationStatus | undefined
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation>
  ) => void
}

export default function ReservationActions({
  reservationId,
  status,
  updateReservationsList
}: ReservationActionsProps) {
  switch (true) {
    case status === ReservationStatusEnum.completed:
      return (
        <div className={css.container}>
          <Badge
            variant="primary"
            className={cx(css.button, css.button_completed)}
            size="xl"
          >
            Бронь завершена
          </Badge>
        </div>
      )
    case status === ReservationStatusEnum.canceled:
      return (
        <div className={css.container}>
          <Badge
            variant="primary"
            className={cx(css.button, css.button_completed)}
            size="xl"
          >
            Бронь отменена
          </Badge>
        </div>
      )
    case status === ReservationStatusEnum.confirmed:
      return (
        <div className={cx(css.container, css.container_progress)}>
          <Reservation.InProgress
            variant="default"
            size="lg"
            className={cx(css.button, css.button_progress)}
            reservationId={reservationId}
            updateReservationsList={updateReservationsList}
          >
            <Icon name="restaurant" />
            Гости пришли
          </Reservation.InProgress>
          <Reservation.Cancel
            className={css.button_cancel}
            variant="default"
            size="icon"
            reservationId={reservationId}
            updateReservationsList={updateReservationsList}
          >
            <Icon name="x" />
          </Reservation.Cancel>
        </div>
      )
    case status === ReservationStatusEnum.inProgress:
      return (
        <div className={css.container}>
          <Reservation.Complete
            variant="default"
            size="lg"
            className={cx(css.button, css.button_text)}
            reservationId={reservationId}
            updateReservationsList={updateReservationsList}
          >
            Гости ушли
            <Icon name="x" />
          </Reservation.Complete>
        </div>
      )
    case status === ReservationStatusEnum.unconfirmed:
      return (
        <div className={css.container}>
          <Badge variant="primary" className={cx(css.button)} size="xl">
            <Icon name="clock" /> Забронировано
          </Badge>
        </div>
      )
  }
}
