import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { startOfDay } from 'date-fns'

import { nowWithTimezone } from '@/shared/lib'

import { ReservationStatusEnum } from '../model/reservationStatus'

export enum ReservationSortEnum {
  default = 'default',
  inProgress = 'in_progress',
  closest = 'closest',
  requests = 'requests'
}

export enum ReservationSortDateEnum {
  StartDate = 'start_date',
  EndDate = '-start_date'
}

export enum ReservationSortTableEnum {
  Ascending = '1',
  Descending = '-1'
}

type ReservationsState = {
  search: string
  searchStatus?: ReservationStatus
  selectedInterval: IntervalData
  sortType: ReservationSortEnum
  openModalId: number | null
  sortDate?: ReservationSortDateEnum
  sortTable?: ReservationSortTableEnum
  filters?: FiltersDto
}

const initialState: ReservationsState = {
  search: '',
  selectedInterval: {
    start_date: null,
    end_date: null
  },
  searchStatus: ReservationStatusEnum.confirmed,
  sortType: ReservationSortEnum.closest,
  openModalId: null
}

export const reservationsSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    changeReservationsSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setReservationsSortType: (
      state,
      { payload }: PayloadAction<ReservationSortEnum>
    ) => {
      switch (payload) {
        case ReservationSortEnum.default:
          state.selectedInterval = {
            start_date: null,
            end_date: null
          }
          state.searchStatus = undefined
          break
        case ReservationSortEnum.inProgress:
          state.selectedInterval = {
            start_date: null,
            end_date: null
          }
          state.searchStatus = ReservationStatusEnum.inProgress
          break
        case ReservationSortEnum.closest:
          state.selectedInterval = {
            start_date: startOfDay(nowWithTimezone()),
            end_date: null
          }
          state.searchStatus = ReservationStatusEnum.confirmed
          break
      }
      state.sortType = payload
    },
    changeReservationsSelectedInterval: (
      state,
      { payload }: PayloadAction<IntervalData>
    ) => {
      state.selectedInterval = payload
    },
    setReservationsOpenModalId: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.openModalId = payload
    },
    setReservationsSortDate: (
      state,
      { payload }: PayloadAction<ReservationSortDateEnum | undefined>
    ) => {
      state.sortDate = payload
    },
    setReservationsSortTable: (
      state,
      { payload }: PayloadAction<ReservationSortTableEnum | undefined>
    ) => {
      state.sortTable = payload
    },
    setReservationsFilters: (
      state,
      { payload }: PayloadAction<FiltersDto | undefined>
    ) => {
      state.filters = payload
    },
    resetReservationsFilters: () => initialState
  }
})

export const {
  changeReservationsSearch,
  setReservationsSortType,
  changeReservationsSelectedInterval,
  setReservationsOpenModalId,
  setReservationsSortDate,
  setReservationsSortTable,
  setReservationsFilters,
  resetReservationsFilters
} = reservationsSlice.actions
