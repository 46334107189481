import { useParams } from 'react-router-dom'

import { selectSelectedTables, selectTableToChangeTo } from '@/entities/map'
import { useUpdateReservationMutation } from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import { Button, Icon } from '@/shared/ui'

export default function SplitTablesButton() {
  const { id: reservationId } = useParams()
  const addressId = useAppSelector(selectSelectedAddressId)
  const tableToChangeTo = useAppSelector(selectTableToChangeTo)
  const curTable = useAppSelector(selectSelectedTables)

  const [update, { isLoading }] = useUpdateReservationMutation()

  const newPlaces = curTable.filter(
    (table) => table.id !== tableToChangeTo[0]?.id
  )

  const splitTablesHandler = () => {
    if (!reservationId || Number.isNaN(+reservationId) || !addressId) return
    update({
      addressId,
      reservationId: +reservationId,
      body: {
        places: newPlaces.map((table) => table.id)
      }
    })
  }

  return (
    <Button
      variant="secondary"
      isLoading={isLoading}
      onClick={splitTablesHandler}
    >
      Разделить
      <Icon name="split" />
    </Button>
  )
}
