export const selectReservationsSearch = (state: RootState) =>
  state.reservations.search
export const selectReservationsSelectedInterval = (state: RootState) =>
  state.reservations.selectedInterval

export const selectReservationsSortType = (state: RootState) =>
  state.reservations.sortType

export const selectReservationsSearchStatus = (state: RootState) =>
  state.reservations.searchStatus

export const selectReservationsOpenModalId = (state: RootState) =>
  state.reservations.openModalId

export const selectReservationsSortDate = (state: RootState) =>
  state.reservations.sortDate

export const selectReservationsSortTable = (state: RootState) =>
  state.reservations.sortTable

export const selectReservationsFilters = (state: RootState) =>
  state.reservations.filters
