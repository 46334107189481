import {
  Tab,
  TabContent,
  TabsGroup,
  TabsProvider,
  VaulFooter
} from '@/shared/ui'

import { GuestCommentsActions } from './GuestCommentsActions'
import { GuestCommentsList } from './GuestCommentsList'
import { GuestVisitsActions } from './GuestVisitsActions'
import { GuestVisitsList } from './GuestVisitsList'

import css from './GuestTabs.module.css'

type GuestTabsProps = {
  guest: Guest
}

export default function GuestTabs({ guest }: GuestTabsProps) {
  return (
    <TabsProvider className={css.tabs} defaultValue="comments">
      <TabsGroup className={css.tabs__list}>
        <Tab value="comments">Комментарии</Tab>
        <Tab value="visits">История посещений</Tab>
      </TabsGroup>
      <TabContent value="comments">
        <div className={css.tabs__content}>
          <GuestCommentsList guestId={guest.id} />
          <VaulFooter>
            <GuestCommentsActions guest={guest} />
          </VaulFooter>
        </div>
      </TabContent>
      <TabContent value="visits">
        <div className={css.tabs__content}>
          <GuestVisitsList guestId={guest.id} />
          <VaulFooter>
            <GuestVisitsActions guest={guest} />
          </VaulFooter>
        </div>
      </TabContent>
    </TabsProvider>
  )
}
