/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { type ComponentPropsWithoutRef } from 'react'

import { cx } from 'class-variance-authority'

import { formatName, formatPhoneNumberRU } from '@/shared/lib'
import { Card, Dot, Icon } from '@/shared/ui'

import { GuestTags } from '../GuestTags'

import css from './MobileGuestCard.module.css'

type MobileGuestCardProps = ComponentPropsWithoutRef<'div'> & {
  guest: BaseGuest
  personsCount: number
  comment?: string | null
}

export default function MobileGuestCard({
  guest,
  personsCount,
  comment,
  className
}: MobileGuestCardProps) {
  const { first_name, last_name, phone, tags } = guest

  return (
    <Card className={cx(css.card, className)}>
      <GuestTags
        tags={tags}
        className={css.card__badge}
        size="xs"
        showCount={1}
        onlyFirstOne
      />
      <h4 className={css.card__title}>{formatName(first_name, last_name)}</h4>
      <div className={css.card__info}>
        <span>{formatPhoneNumberRU(phone)}</span>
        <Dot />
        <div className={css.card__persons}>
          {personsCount}
          <Icon name="users" size={12} />
        </div>
      </div>
      <h5 className={css.card__comment}>{comment || '-'}</h5>
    </Card>
  )
}
