export const ChartTooltip = (heading: string, diffMessage: string) => {
  return `
      <div class="apex-area-chart-tooltip">
        <span class="text_xl text_bold">
          ${heading}
        </span>
        <span class="text_base text_normal text_gray3">
          ${diffMessage}
        </span>
      </div>
  `
}
