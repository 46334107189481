/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable boundaries/element-types */
import { type ComponentPropsWithoutRef } from 'react'

import { cx } from 'class-variance-authority'
import { format } from 'date-fns'

import { Badge, Icon } from '@/shared/ui'

import { getSourceText } from '../../lib'

import css from './MobileReservationBadgeCarousel.module.css'

type MobileReservationBadgeCarouselProps = ComponentPropsWithoutRef<'div'> & {
  personsCount: number
  tableNumber?: string
  source: ReservationSource
  linkName: string | null
  deposit?: null
  startDate: Date
  tags?: SimpleTag[]
}

export default function MobileReservationBadgeCarousel({
  personsCount,
  tableNumber,
  source,
  linkName,
  deposit,
  startDate,
  className,
  tags
}: MobileReservationBadgeCarouselProps) {
  return (
    <div className={cx(css.card, className)}>
      <div className={css.card__scroll}>
        {tableNumber && (
          <Badge className={css.card__badge} variant="orange">
            <Icon name="table" />
            {`№${tableNumber}`}
          </Badge>
        )}
        <Badge className={css.card__badge} variant="gray">
          <Icon name="users" />
          {personsCount}
        </Badge>
        {deposit && (
          <Badge variant="tintedBlue" className={css.card__badge}>
            <Icon name="banknote" />
            {deposit}
          </Badge>
        )}

        <Badge className={css.card__badge}>
          <Icon name="announcement" />
          {getSourceText(source, linkName)}
        </Badge>
        <Badge className={css.card__badge}>
          <Icon name="calendar" />
          {format(startDate, 'dd.LL.yyyy')}
        </Badge>
        {tags?.map((tag) => (
          <Badge key={tag.id} className={css.card__badge}>
            {tag.name}
          </Badge>
        ))}
      </div>
    </div>
  )
}
