import 'dayjs/locale/ru'

import dayjs from 'dayjs'

import { Rows } from './Rows/Rows'

import { getDaysCellsArray, getEmptyCellsArray, getRowsArray } from '../utils'

dayjs.locale('ru')

interface BodyProps {
  monthNumber: number
  year: number
  selectedInterval: IntervalState
  setInterval: SetInterval
}
const dateFormat = 'YYYY-MM-DD'
const dayFormat = 'DD'
const monthYearFormat = 'YYYYMM'

export function Body({
  monthNumber,
  year,
  selectedInterval,
  setInterval
}: BodyProps) {
  const formatMonthNumber = `0${monthNumber}`.slice(-2)
  const dateString = `${year}${formatMonthNumber}`
  const selectedMonth = dayjs(dateString, monthYearFormat).toDate()

  const daysInMonth = dayjs(selectedMonth).endOf('month').format(dayFormat)

  const startOfWeek = dayjs(selectedMonth).startOf('week')

  const startOfWeekDate = dayjs(startOfWeek).startOf('week')
  const isWeekStartInSameMonth = startOfWeekDate.isSame(selectedMonth, 'month')

  const startOfWeekDay = !isWeekStartInSameMonth
    ? startOfWeekDate.format(dayFormat)
    : 0

  const daysInPrevMonth = dayjs(startOfWeek).endOf('month').format(dayFormat)

  const endOfSelectedMonth = dayjs(selectedMonth)
    .endOf('month')
    .format(dateFormat)

  const endOfWeekDate = dayjs(endOfSelectedMonth).endOf('week')
  const isWeekEndInSameMonth = endOfWeekDate.isSame(selectedMonth, 'month')

  const endOfWeekDay = !isWeekEndInSameMonth
    ? endOfWeekDate.format(dayFormat)
    : 0

  const setIntervalHandler = (date: string) => {
    const { start_date, end_date } = selectedInterval
    if (start_date && end_date) setInterval(dayjs(date).toDate(), null)
    if (start_date && !end_date) {
      const payload = [start_date, dayjs(date).toDate()].sort(
        (a: Date, b: Date) => (dayjs(a).isAfter(b) ? 1 : -1)
      )
      setInterval(...(payload as [Date, Date]))
    }
  }

  const prevDaysData = {
    start: +startOfWeekDay,
    end: +daysInPrevMonth,
    interval: 1
  }

  const nextDaysData = {
    start: 1,
    end: +endOfWeekDay,
    interval: 1
  }

  const daysData = {
    start: 1,
    end: +daysInMonth,
    interval: 1,
    showingMonth: selectedMonth,
    selectedInterval,
    setIntervalHandler
  }

  const prevDays = getEmptyCellsArray(prevDaysData)
  const nextDays = getEmptyCellsArray(nextDaysData)

  const days = getDaysCellsArray(daysData)

  const totalSlots = [...prevDays, ...days, ...nextDays]

  const rows = getRowsArray(totalSlots, 7)

  return (
    <tbody>
      <Rows rows={rows} />
    </tbody>
  )
}
