/* eslint-disable import/no-duplicates */
import { add, format, isSameDay } from 'date-fns'
import { ru } from 'date-fns/locale'

import { nowWithTimezone } from '@/shared/lib'

export const generateDateLabel = (date: Date): string => {
  const now = nowWithTimezone()
  const isToday = isSameDay(date, now)
  const isTomorrow = isSameDay(date, add(now, { days: 1 }))

  let label = format(date, 'eeeeee, dd.MM', { locale: ru })

  if (isToday) {
    label = format(date, 'Сегодня, dd.MM', { locale: ru })
  }

  if (isTomorrow) {
    label = format(date, 'Завтра, dd.MM', { locale: ru })
  }
  return label
}
