import { ChangeTableButton as ChangeTable } from './change-table'
import { ChangeTableButton as ChangeTableNoInvalidate } from './change-table-no-invalidate'
import { MergeTablesButton as MergeTables } from './merge-tables'
import { MergeTablesButton as MergeTablesNoInvalidate } from './merge-tables-no-invalidate'
import { SplitTablesButton as SplitTables } from './split-tables'
import { UnselectChangeTablesTo } from './unselect-change-tables-to'

export const MapActions = {
  UnselectChangeTablesTo,
  ChangeTable,
  MergeTables,
  SplitTables,
  ChangeTableNoInvalidate,
  MergeTablesNoInvalidate
}
