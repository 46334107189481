import { getArrMinMax, getAvg } from '@/shared/lib'

export const getRangeBarData = (
  x: string[],
  arr: number[][]
): RangeBarData[] => {
  return arr.map((valArr, idx) => ({
    x: x[idx],
    y: getArrMinMax(valArr),
    goals: [
      {
        name: 'Avg',
        strokeHeight: 27,
        strokeWidth: 0,
        strokeLineCap: 'round',
        strokeColor: '#fff',
        value: getAvg(arr[idx])
      }
    ]
  }))
}
