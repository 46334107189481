import { useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { useGuestQuery } from '@/entities/guest'
import {
  selectSelectedAddressId,
  selectSelectedChainId
} from '@/entities/session'
import { formatTimeForRequest, nowWithTimezone } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { Skeleton, TemplateDates, VaulFooter } from '@/shared/ui'

import { GuestVaulCardContent } from './GuestVaulCardContent'
import { GuestVaulCardHeader } from './GuestVaulCardHeader'

import css from './GuestVaulContent.module.css'

type GuestVaulContentProps = {
  guestId?: number | string
}

export default function GuestVaulContent({ guestId }: GuestVaulContentProps) {
  const chain = useAppSelector(selectSelectedChainId)
  const address = useAppSelector(selectSelectedAddressId)
  const now = nowWithTimezone()

  const [selectedDateInterval] = useState<IntervalData>({
    start_date: TemplateDates.allTimeStartDate,
    end_date: now
  })

  const { data, isFetching } = useGuestQuery(
    guestId && !Number.isNaN(+guestId) && chain && address
      ? {
          chain,
          guestId: +guestId,
          address,
          start_date: formatTimeForRequest(selectedDateInterval.start_date),
          end_date: formatTimeForRequest(selectedDateInterval.end_date)
        }
      : skipToken,
    { refetchOnMountOrArgChange: true }
  )

  if (isFetching || !data) {
    return (
      <div className={css.skeleton}>
        <div className={css.skeleton__header}>
          <Skeleton className={css.skeleton__badge} />
          <Skeleton className={css.skeleton__title} />
        </div>
        <Skeleton className={css.skeleton__phone} />
        <Skeleton className={css.skeleton__tags} />
        <Skeleton className={css.skeleton__tabs} />
        <VaulFooter>
          <div className={css.skeleton__actions}>
            <Skeleton className={css.skeleton__button} />
            <Skeleton className={css.skeleton__button} />
          </div>
        </VaulFooter>
      </div>
    )
  }

  return (
    <div className={css.card}>
      <GuestVaulCardHeader guest={data} />
      <GuestVaulCardContent guest={data} />
    </div>
  )
}
