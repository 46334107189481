import { isReservationLate } from '../lib'

export const selectReservation = (state: RootState) =>
  state.reservationById.reservation

export const selectIsReservationLate = (state: RootState) => {
  if (state.reservationById.reservation) {
    return isReservationLate(state.reservationById.reservation.end_date)
  } else {
    return false
  }
}

export const selectReservationPageStep = (state: RootState) =>
  state.reservationById.step

export const selectReservationByIdTables = (state: RootState) =>
  state.reservationById.reservation?.places
