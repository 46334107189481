import * as React from 'react'

import { cx } from 'class-variance-authority'
import { Drawer as DrawerUI } from 'vaul'

import { Icon } from '../Icon'

import css from './Drawer.module.css'

const Drawer = DrawerUI.Root

const DrawerTrigger = DrawerUI.Trigger

const DrawerPortal = DrawerUI.Portal

type DrawerOverlayRef = React.ElementRef<typeof DrawerUI.Overlay>
type DrawerOverlayProps = React.ComponentPropsWithoutRef<
  typeof DrawerUI.Overlay
>

const DrawerOverlay = React.forwardRef<DrawerOverlayRef, DrawerOverlayProps>(
  ({ className, children, ...props }, ref) => (
    <DrawerUI.Overlay
      ref={ref}
      className={cx(css.overlay, className)}
      {...props}
    />
  )
)
DrawerOverlay.displayName = 'DrawerOverlay'

type DrawerContentRef = React.ElementRef<typeof DrawerUI.Content>
type DrawerContentProps = React.ComponentPropsWithoutRef<
  typeof DrawerUI.Content
> & { withCloseButton?: boolean; onClose?: (e: React.MouseEvent) => void }

const DrawerContent = React.forwardRef<DrawerContentRef, DrawerContentProps>(
  (
    { className, children, withCloseButton = false, onClose, ...props },
    ref
  ) => (
    <DrawerPortal>
      <DrawerOverlay />
      <DrawerUI.Content
        ref={ref}
        className={cx(css.content, className)}
        onClick={(e) => e.stopPropagation()}
        {...props}
      >
        {children}
        {withCloseButton && (
          <DrawerUI.Close className={css.content__close} onClick={onClose}>
            <Icon name="arrowLeft" size={20} />
          </DrawerUI.Close>
        )}
      </DrawerUI.Content>
    </DrawerPortal>
  )
)
DrawerContent.displayName = 'DrawerContent'

export { Drawer, DrawerTrigger, DrawerPortal, DrawerOverlay, DrawerContent }
