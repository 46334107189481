import { useState } from 'react'

import { add, addDays, endOfDay } from 'date-fns'

import {
  changeReservationsSelectedInterval,
  selectReservationsSelectedInterval
} from '@/entities/reservations'
import { nowWithTimezone } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, DatePickerList, TemplateDates } from '@/shared/ui'
import { VaulBody, VaulFooter, VaulHeader } from '@/shared/ui/Vaul'

import css from './DatePickerVaul.module.css'

type DatePickerVaulProps = {
  onOpen: (value: boolean) => void
}

export default function DatePickerVaul({ onOpen }: DatePickerVaulProps) {
  const selectedInterval = useAppSelector(selectReservationsSelectedInterval)
  const [selectedDate, selectDate] = useState<Date | undefined>(
    selectedInterval.start_date ?? undefined
  )
  const dispatch = useAppDispatch()

  const changeData = () => {
    dispatch(
      changeReservationsSelectedInterval({
        start_date: selectedDate ?? null,
        end_date: selectedDate
          ? add(endOfDay(selectedDate), { hours: 5 })
          : null
      })
    )
    onOpen(false)
  }

  return (
    <div className={css.content}>
      <VaulHeader className={css.header}>
        <h4 className={css.header__title}>Выберите дату</h4>
      </VaulHeader>
      <VaulBody>
        <DatePickerList
          selectDate={selectDate}
          selectedDate={selectedDate}
          startDate={TemplateDates.allTimeStartDate}
          endDate={addDays(nowWithTimezone(), 30)}
        />
        <VaulFooter>
          <Button
            className={css.button}
            variant="primary"
            size="lg"
            onClick={changeData}
          >
            Выбрать
          </Button>
        </VaulFooter>
      </VaulBody>
    </div>
  )
}
