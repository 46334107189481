import { GuestComment, useGuestCommentsQuery } from '@/entities/comments'
import { Error, InfoBox, Loading } from '@/shared/ui'

import css from './GuestCommentsList.module.css'

type GuestCommentsListProps = {
  guestId: number
}

export default function GuestCommentsList({ guestId }: GuestCommentsListProps) {
  const { data, isLoading, isError, refetch } = useGuestCommentsQuery({
    guestId
  })

  if (isLoading || !data) {
    return <Loading />
  }

  if (isError) {
    return <Error refetch={refetch} />
  }

  if (data.length === 0) {
    return <InfoBox noIcon>Ничего не найдено</InfoBox>
  }

  return (
    <div className={css.comments}>
      {data.map((comment) => (
        <GuestComment key={comment.id} comment={comment} />
      ))}
    </div>
  )
}
