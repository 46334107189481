import { useEffect } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

// import { config } from '@/app/config'
import {
  selectReservationEnd,
  // selectReservationPersonsCount,
  selectReservationStart,
  setReservationTables
} from '@/entities/create-update-reservation'
import { selectSelectedHallWithInfo } from '@/entities/halls'
import {
  Map,
  selectIsTakeTable,
  selectSelectedTables,
  selectTables,
  selectTableToChangeTo,
  useMapTablesQuery
} from '@/entities/map'
import { selectSelectedAddressId } from '@/entities/session'
import { useTimelineTablesWSQuery } from '@/entities/timeline'
import { dateToDateWithTimezone, formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Error, Loading } from '@/shared/ui'

export function TablePicker() {
  const dispatch = useAppDispatch()
  const addressId = useAppSelector(selectSelectedAddressId)
  const selectedTables = useAppSelector(selectSelectedTables)
  const selectedTableToChangeTo = useAppSelector(selectTableToChangeTo)
  const selectedStartDate = useAppSelector(selectReservationStart)
  const selectedEndDate = useAppSelector(selectReservationEnd)
  const selectedHall = useAppSelector(selectSelectedHallWithInfo)
  const isTakeTable = useAppSelector(selectIsTakeTable)

  const {
    data: tables,
    isFetching: isTablesFetching,
    isError: tablesError,
    refetch: refetchTables
  } = useMapTablesQuery(
    selectedHall
      ? {
          hallId: selectedHall.id,
          start_date: selectedStartDate
            ? formatTimeForRequest(dateToDateWithTimezone(selectedStartDate))
            : undefined,
          end_date: selectedEndDate
            ? formatTimeForRequest(dateToDateWithTimezone(selectedEndDate))
            : undefined
        }
      : skipToken
  )

  const {
    data: doubleResponse,
    isError: reservationsError,
    refetch: refetchReservations
  } = useTimelineTablesWSQuery(
    selectedStartDate && addressId
      ? {
          addressId,
          date: formatTimeForRequest(selectedStartDate).split(' ')[0]
        }
      : skipToken
  )

  useEffect(() => {
    dispatch(
      setReservationTables(
        isTakeTable ? selectedTableToChangeTo : selectedTables
      )
    )
  }, [selectedTables, selectedTableToChangeTo])

  useEffect(() => {
    if (selectedHall) {
      dispatch(selectTables([]))
    }
  }, [selectedHall])

  if (isTablesFetching || !doubleResponse?.length) {
    return <Loading />
  }

  if (tablesError) {
    return <Error refetch={refetchTables} />
  }

  if (reservationsError) {
    return <Error refetch={refetchReservations} />
  }

  return (
    <Map
      tables={tables}
      reservations={doubleResponse?.[doubleResponse.length - 1].reservations}
      clickableDisabled={false}
      sliceStartDate={selectedStartDate}
      sliceEndDate={selectedEndDate}
      isNoInfo
      isPickTable
      withDepositsInfo
      withComingSoon={false}
      withRecentlyCompleted
    />
  )
}
