import { type MouseEvent, useState } from 'react'

import { cx } from 'class-variance-authority'

import { MobileComments, MobileTextAreaComments } from '@/entities/reservation'
import { isPWA } from '@/shared/lib'
import {
  Button,
  type ButtonStyleProps,
  Vaul,
  VaulBody,
  VaulContent,
  VaulFooter,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'

import css from './CommentsVaul.module.css'

type CommentsVaulProps = ButtonStyleProps & {
  comments?: UserComment[]
  isErrorComments?: boolean
  isLoadingComments?: boolean
  deleteCommentHandle: (id: number) => Promise<void>
  updateCommentHandle: (id: number, text: string) => Promise<void>
  createCommentHandle: (text: string) => Promise<void>
}

export function CommentsVaul({
  comments,
  isErrorComments,
  isLoadingComments,
  deleteCommentHandle,
  updateCommentHandle,
  createCommentHandle
}: CommentsVaulProps) {
  const [open, setOpen] = useState(false)
  const [commentUpdate, setCommentUpdate] = useState<UserComment | null>(null)

  const toggleVaulHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen(!open)
  }

  return (
    <Vaul open={open} onOpenChange={(open) => !open && setOpen(false)}>
      <VaulTrigger asChild>
        <Button
          className={css.button}
          contentClassName={css.button__content}
          onClick={toggleVaulHandler}
        >
          Ваш комментарий
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen>
        <div className={css.content}>
          <VaulHeader>
            <h4 className={css.title}>Комментарии</h4>
          </VaulHeader>
          <VaulBody className={css.body}>
            <div
              className={cx(css.body__content, {
                [css.body__content_pwa]: isPWA()
              })}
            >
              <MobileComments
                comments={comments}
                isLoadingComments={isLoadingComments}
                isErrorComments={isErrorComments}
                deleteCommentHandle={deleteCommentHandle}
                setCommentUpdate={setCommentUpdate}
              />
            </div>
          </VaulBody>
          <VaulFooter>
            <MobileTextAreaComments
              commentUpdate={commentUpdate}
              setCommentUpdate={setCommentUpdate}
              updateCommentHandle={updateCommentHandle}
              createCommentHandle={createCommentHandle}
            />
          </VaulFooter>
        </div>
      </VaulContent>
    </Vaul>
  )
}
