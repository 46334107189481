/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useParams } from 'react-router-dom'

import { useReservationRequestQuery } from '@/entities/reservation-request'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'

import { RequestPickTableStep } from './pick-table'
import { RequestConfirmSuccessScreen } from './success-screen'

type MobileRequestConfirmProcessProps = {
  requestId?: number
  onComplete?: () => void
}

export default function MobileRequestConfirmProcess({
  requestId = undefined,
  onComplete = undefined
}: MobileRequestConfirmProcessProps) {
  const addressId = useAppSelector(selectSelectedAddressId)
  const { id: reservationReqId } = useParams()

  const [step, setStep] = useState(1)

  const id = requestId || reservationReqId

  const { data, isFetching } = useReservationRequestQuery(
    addressId && id && !Number.isNaN(+id)
      ? { addressId, reservationReqId: +id }
      : skipToken
  )

  if (isFetching || !data) {
    return null
  }

  switch (step) {
    case 1:
      return (
        <RequestPickTableStep
          reservationReqId={data.id}
          setStep={setStep}
          onComplete={onComplete}
        />
      )
    case 2:
      return <RequestConfirmSuccessScreen reservation={data} />
    default:
  }
}
