import { useState } from 'react'

import { cx } from 'class-variance-authority'
import { format } from 'date-fns'

import { config } from '@/app/config'
import {
  selectReservationDuration,
  selectReservationStart,
  setReservationEnd
} from '@/entities/create-update-reservation'
import { formatRuWord } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  Icon,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import { EndTime } from './EndTime/EndTime'

import css from './DurationPicker.module.css'

const { MIN_RESERVATION_TIME_MIN, PICKERS_INTERVAL } = config

const MAX_MINUTES = 4 * 60 + 45

export function DurationPicker() {
  const [open, setOpen] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const selectedDuration = useAppSelector(selectReservationDuration)
  const selectedStart = useAppSelector(selectReservationStart)
  const [isDurationInPopover, setIsDurationInPopover] = useState<boolean>(false)
  const [isEndTimeInPopover, setIsEndTimeInPopover] = useState<boolean>(false)
  const quickPanelMinutes = Array.from({ length: 3 }).map(
    (_, i) => (i + 1) * 60
  )
  const fullPanelMinutes = Array.from({
    length: MAX_MINUTES / PICKERS_INTERVAL - 2
  }).reduce<number[]>(
    (acc, _, i) => {
      acc.push(acc[i] + PICKERS_INTERVAL)

      return acc
    },
    [MIN_RESERVATION_TIME_MIN]
  )

  const pickDurationHandler = (duration?: number | null) => {
    setIsDurationInPopover(false)
    setIsEndTimeInPopover(false)
    dispatch(setReservationEnd(duration))
  }

  const pickDurationInPopoverHandler = (duration?: number) => {
    setOpen(false)
    setIsDurationInPopover(true)
    setIsEndTimeInPopover(false)
    dispatch(setReservationEnd(duration))
  }

  return (
    <div className={css.picker}>
      <Label className={css.picker__title}>Длительность</Label>
      {selectedStart ? (
        <>
          <div className={cx(css.picker__quick, css.picker__quick_first)}>
            {quickPanelMinutes.map((minute) => (
              <Button
                variant="tertiary"
                className={cx(css.button, css.button__quick, {
                  [css.button_active]:
                    minute === selectedDuration && !isDurationInPopover
                })}
                key={minute}
                onClick={() => pickDurationHandler(minute)}
              >
                {`${minute / 60} ${formatRuWord(minute / 60, [
                  'час',
                  'часа',
                  'часов'
                ])}`}
              </Button>
            ))}
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="tertiary"
                  className={cx(css.button, {
                    [css.button_active]: isDurationInPopover
                  })}
                  contentClassName={css.button__all__content}
                >
                  {isDurationInPopover && selectedDuration
                    ? `${Math.floor(selectedDuration / 60)}:${(
                        selectedDuration % 60
                      )
                        .toString()
                        .padStart(2, '0')}`
                    : 'Ещё'}
                  <Icon name="clockPlus" />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                sideOffset={8}
                alignOffset={-8}
                align="start"
                side="top"
                variant="glass"
                className={css.picker__full}
              >
                {fullPanelMinutes.map((minute) => (
                  <Button
                    size="sm"
                    variant="gray"
                    className={cx(css.button, css.button__full, {
                      [css.button_active]:
                        minute === selectedDuration && isDurationInPopover,
                      [css['button__full_first-row']]: minute < 60
                    })}
                    key={minute}
                    onClick={() => pickDurationInPopoverHandler(minute)}
                  >
                    {format(new Date(0, 0, 0, 0, minute), 'H:mm')}
                  </Button>
                ))}
              </PopoverContent>
            </Popover>
          </div>
          <div className={cx(css.picker__quick, css.picker__quick_second)}>
            <Button
              variant="tertiary"
              className={cx(css.button, css.button__quick, {
                [css.button_active]: selectedDuration === null
              })}
              onClick={() => pickDurationHandler(null)}
            >
              По ситуации
            </Button>
            <EndTime
              isEndTimeInPopover={isEndTimeInPopover}
              setIsEndTimeInPopover={setIsEndTimeInPopover}
              setIsDurationInPopover={setIsDurationInPopover}
            />
          </div>
        </>
      ) : (
        <span className={css.info}>Выберите время старта</span>
      )}
    </div>
  )
}
