/* eslint-disable boundaries/element-types */
import { cx } from 'class-variance-authority'
import { isAfter } from 'date-fns'

import { INFINITE_SCROLL_RES } from '@/app/config'
import {
  ReservationStatusEnum,
  selectReservationsSearch,
  selectReservationsSearchStatus,
  selectReservationsSelectedInterval
} from '@/entities/reservations'
import { selectSelectedAddressId } from '@/entities/session'
import { Reservation } from '@/features/reservation'
import { useIsDevice } from '@/shared/hooks'
import { formatTimeForRequest, nowWithTimezone } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { Badge, Icon } from '@/shared/ui'

import css from './ReservationCardActions.module.css'

type ReservationCardActionsProps = {
  id: number
  status: ReservationStatus
  endDate: Date | null
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation>
  ) => void
}

export default function ReservationCardActions({
  id,
  status,
  endDate,
  updateReservationsList
}: ReservationCardActionsProps) {
  const isSkipped = endDate && isAfter(nowWithTimezone(), endDate)
  const address = useAppSelector(selectSelectedAddressId)
  const selectedInterval = useAppSelector(selectReservationsSelectedInterval)
  const searchStatus = useAppSelector(selectReservationsSearchStatus)
  const search = useAppSelector(selectReservationsSearch)
  const { isMobile } = useIsDevice()

  const reservationsQueryParams = address
    ? {
        address,
        limit: INFINITE_SCROLL_RES,
        offset: 1 * INFINITE_SCROLL_RES,
        search,
        start_date: formatTimeForRequest(selectedInterval.start_date),
        end_date: formatTimeForRequest(selectedInterval.end_date),
        status: searchStatus ? [searchStatus] : undefined
      }
    : undefined

  switch (true) {
    case status === ReservationStatusEnum.completed:
      return (
        <div className={css.container}>
          <Badge className={cx(css.button, css.button_completed)}>
            Бронь завершена
          </Badge>
        </div>
      )
    case status === ReservationStatusEnum.canceled:
      return (
        <div className={css.container}>
          <Badge className={cx(css.button, css.button_completed)}>
            Бронь отменена
          </Badge>
        </div>
      )
    case isSkipped:
      return (
        <div className={cx(css.container, css.container_skipped)}>
          <Reservation.Complete
            variant="tertiary"
            className={cx(css.button, css.button_text)}
            reservationId={id}
            reservationsQueryParams={reservationsQueryParams}
            updateReservationsList={updateReservationsList}
          >
            <Icon name="check" />
            Завершить
          </Reservation.Complete>
          <Reservation.Cancel
            variant="tertiary"
            className={cx(css.button, css.button_text)}
            reservationId={id}
            updateReservationsList={updateReservationsList}
          >
            <Icon name="userX" />
            Гостя не было
          </Reservation.Cancel>
        </div>
      )
    case status === ReservationStatusEnum.confirmed:
      return (
        <>
          {isMobile ? (
            <div className={cx(css.container, css.container_progress)}>
              <Reservation.MobileComments
                reservationId={id}
                updateReservationsList={updateReservationsList}
              />
              <Reservation.InProgress
                variant="tertiary"
                className={cx(css.button, css.button_progress)}
                reservationId={id}
                reservationsQueryParams={reservationsQueryParams}
                updateReservationsList={updateReservationsList}
              >
                <Icon name="restaurant" />
                Гости пришли
              </Reservation.InProgress>
            </div>
          ) : (
            <Reservation.InProgress
              variant="tertiary"
              className={cx(css.button, css.button_progress)}
              reservationId={id}
              reservationsQueryParams={reservationsQueryParams}
              updateReservationsList={updateReservationsList}
            >
              <Icon name="restaurant" />
              Гости пришли
            </Reservation.InProgress>
          )}
        </>
      )

    case status === ReservationStatusEnum.inProgress:
      return (
        <>
          {isMobile ? (
            <div className={cx(css.container, css.container_complete)}>
              <Reservation.MobileComments
                reservationId={id}
                updateReservationsList={updateReservationsList}
              />
              <Reservation.Complete
                variant="tertiary"
                className={cx(css.button, css.button_text)}
                reservationId={id}
                reservationsQueryParams={reservationsQueryParams}
                updateReservationsList={updateReservationsList}
              >
                <Icon name="x" />
                Гости ушли
              </Reservation.Complete>
              {/* TODO:  */}
            </div>
          ) : (
            <Reservation.Complete
              variant="tertiary"
              className={cx(css.button, css.button_text)}
              reservationId={id}
              reservationsQueryParams={reservationsQueryParams}
              updateReservationsList={updateReservationsList}
            >
              <Icon name="x" />
              Гости ушли
            </Reservation.Complete>
          )}
        </>
      )
    case status === ReservationStatusEnum.unconfirmed:
      return (
        <div className={css.container}>
          <Badge className={cx(css.button)}>
            <Icon name="clock" />
            Забронировано
          </Badge>
        </div>
      )
  }
}
