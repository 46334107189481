import { baseApi, tagTypes } from '@/shared/api'

const { GUEST_COMMENTS_TAG, RESERVATION_COMMENTS_TAG } = tagTypes

export const commentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    guestComments: builder.query<UserComment[], { guestId: number }>({
      query: ({ guestId }) => ({
        url: `/book/chains/guests/${guestId}/comments/`
      }),
      providesTags: [GUEST_COMMENTS_TAG]
    }),
    sendGuestComment: builder.mutation<UserComment, SendGuestCommentParams>({
      query: ({ guestId, body }) => ({
        url: `/book/chains/guests/${guestId}/comments/`,
        method: 'POST',
        body
      }),
      async onQueryStarted({ guestId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          commentsApi.util.updateQueryData(
            'guestComments',
            {
              guestId
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            (draft) => {
              return [
                ...draft,
                {
                  id: Math.round(Math.random() * 10),
                  is_author: true,
                  created_at: null,
                  text: body.text,
                  author: {
                    id: Math.round(Math.random() * 10),
                    first_name: '',
                    last_name: ''
                  }
                }
              ]
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [GUEST_COMMENTS_TAG]
    }),
    updateGuestComment: builder.mutation<Comment, UpdateGuestCommentParams>({
      query: ({ guestId, commentId, body }) => ({
        url: `/book/chains/guests/${guestId}/comments/${commentId}/`,
        method: 'PATCH',
        body
      }),
      async onQueryStarted(
        { guestId, commentId, body },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          commentsApi.util.updateQueryData(
            'guestComments',
            {
              guestId
            },
            (draft) => {
              return draft.map((comment) =>
                comment.id === commentId
                  ? {
                      ...comment,
                      created_at: null,
                      text: body.text
                    }
                  : comment
              )
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [GUEST_COMMENTS_TAG]
    }),
    deleteGuestComment: builder.mutation<void, DeleteGuestCommentParams>({
      query: ({ guestId, commentId }) => ({
        url: `/book/chains/guests/${guestId}/comments/${commentId}/`,
        method: 'DELETE'
      }),
      async onQueryStarted(
        { guestId, commentId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          commentsApi.util.updateQueryData(
            'guestComments',
            {
              guestId
            },
            (draft) =>
              (draft as UserComment[]).filter(
                (comment) => comment.id !== commentId
              )
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [GUEST_COMMENTS_TAG]
    }),
    reservationComments: builder.query<
      UserComment[],
      { reservationId: number }
    >({
      query: ({ reservationId }) => ({
        url: `/book/reservations/${reservationId}/comments/`
      }),
      providesTags: [RESERVATION_COMMENTS_TAG]
    }),
    sendReservationComment: builder.mutation<
      Comment,
      SendReservationCommentParams
    >({
      query: ({ reservationId, body }) => ({
        url: `/book/reservations/${reservationId}/comments/`,
        method: 'POST',
        body
      }),
      async onQueryStarted(
        { reservationId, body },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          commentsApi.util.updateQueryData(
            'reservationComments',
            {
              reservationId
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            (draft) => {
              return [
                ...draft,
                {
                  id: Math.round(Math.random() * 10),
                  is_author: true,
                  created_at: null,
                  text: body.text,
                  author: {
                    id: Math.round(Math.random() * 10),
                    first_name: '',
                    last_name: ''
                  }
                }
              ]
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [RESERVATION_COMMENTS_TAG]
    }),
    updateReservationComment: builder.mutation<
      Comment,
      UpdateReservationCommentParams
    >({
      query: ({ reservationId, commentId, body }) => ({
        url: `/book/reservations/${reservationId}/comments/${commentId}/`,
        method: 'PATCH',
        body
      }),
      async onQueryStarted(
        { reservationId, commentId, body },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          commentsApi.util.updateQueryData(
            'reservationComments',
            {
              reservationId
            },
            (draft) => {
              return draft.map((comment) =>
                comment.id === commentId
                  ? {
                      ...comment,
                      created_at: null,
                      text: body.text
                    }
                  : comment
              )
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [RESERVATION_COMMENTS_TAG]
    }),
    deleteReservationComment: builder.mutation<
      void,
      DeleteReservationCommentParams
    >({
      query: ({ reservationId, commentId }) => ({
        url: `/book/reservations/${reservationId}/comments/${commentId}/`,
        method: 'DELETE'
      }),
      async onQueryStarted(
        { reservationId, commentId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          commentsApi.util.updateQueryData(
            'reservationComments',
            {
              reservationId
            },
            (draft) =>
              (draft as UserComment[]).filter(
                (comment) => comment.id !== commentId
              )
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: [RESERVATION_COMMENTS_TAG]
    })
  })
})

export const {
  useGuestCommentsQuery,
  useReservationCommentsQuery,
  useSendGuestCommentMutation,
  useSendReservationCommentMutation,
  useDeleteGuestCommentMutation,
  useDeleteReservationCommentMutation,
  useUpdateReservationCommentMutation,
  useUpdateGuestCommentMutation
} = commentsApi
