import { cx } from 'class-variance-authority'
import { add, differenceInMinutes, format } from 'date-fns'

import { config } from '@/app/config'
import { nowWithTimezone } from '@/shared/lib'

import css from './Hour.module.css'
const { SPLITTED_HOUR_INTERVAL, GROUP_HOUR_COEFFICIENT } = config

type HourProps = {
  hour: Date
}

export function Hour({ hour }: HourProps) {
  const now = nowWithTimezone()

  const differenceFromHour = differenceInMinutes(
    add(hour, { minutes: SPLITTED_HOUR_INTERVAL * GROUP_HOUR_COEFFICIENT }),
    now
  )

  const isCurrentTimeElement =
    differenceFromHour > 0 &&
    differenceFromHour < SPLITTED_HOUR_INTERVAL * GROUP_HOUR_COEFFICIENT

  return (
    <div
      className={cx(css.hour, {
        [css.hour_now]: isCurrentTimeElement
      })}
    >
      {format(hour, 'HH:mm')}
    </div>
  )
}
