import { format, parseISO } from 'date-fns'

import { Button, Icon } from '@/shared/ui'

import { CommentAuthor } from '../Comment/CommentAuthor'

import css from './GuestComment.module.css'

type GuestCommentProps = {
  comment: UserComment
}

export default function GuestComment({ comment }: GuestCommentProps) {
  const { text, author, created_at, is_author } = comment

  const date = created_at ? format(parseISO(created_at), 'HH:mm MM.dd') : '-'

  return (
    <div className={css.comment}>
      <div className={css.comment__content}>
        <CommentAuthor isAuthor={is_author} author={author} />
        <p className={css.comment__text}>{text}</p>
        <span className={css.comment__date}>{date}</span>
      </div>
      <Button
        size="icon"
        variant="transparent"
        className={css.comment__actions}
      >
        <Icon name="dotsHorizontal" />
      </Button>
    </div>
  )
}
