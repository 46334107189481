import { cx } from 'class-variance-authority'

import { LoadingSpinner } from './LoadingSpinner'

import css from './Loading.module.css'

export default function Loading({ fullscreen = false }) {
  return (
    <div className={cx(css.loading, { [css.loading_fullscreen]: fullscreen })}>
      <LoadingSpinner />
    </div>
  )
}
