/* eslint-disable boundaries/element-types */
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'

import { cx } from 'class-variance-authority'
import { format, parseISO } from 'date-fns'

import { formatName, isPWA } from '@/shared/lib'
import { Icon, Loading, Skeleton } from '@/shared/ui'

import { MobileCommentActions } from './MobileCommentActions'

import css from './MobileComments.module.css'

type MobileCommentsProps = {
  isPreview?: boolean
  comments?: UserComment[]
  isErrorComments?: boolean
  isLoadingComments?: boolean
  setCommentUpdate?: Dispatch<SetStateAction<UserComment | null>>
  deleteCommentHandle: (id: number) => Promise<void>
}

export default function MobileComments({
  isPreview,
  comments,
  isErrorComments = false,
  isLoadingComments = false,
  setCommentUpdate,
  deleteCommentHandle
}: MobileCommentsProps) {
  const [prevCommentsLength, setPrevCommentsLength] = useState<number | null>(
    null
  )
  const scrollRef = useRef<HTMLDivElement>(null)
  const isMount = useRef<boolean>(false)

  const renderName = (comment: UserComment) => {
    if (comment.is_author) {
      return <p>Вы</p>
    }

    if (comment.author) {
      return (
        <p>{formatName(comment.author.first_name, comment.author.last_name)}</p>
      )
    }

    return (
      <div className={css.comment__name_guest}>
        <p>Гость</p>
        <Icon name="user" />
      </div>
    )
  }

  useEffect(() => {
    if (comments) {
      if (
        !isMount.current ||
        (prevCommentsLength && comments.length > prevCommentsLength)
      ) {
        scrollRef.current?.scrollTo({ top: scrollRef.current.scrollHeight })
        isMount.current = true
      }
      setPrevCommentsLength(comments.length)
    }
  }, [comments])

  if (isLoadingComments || isErrorComments || !comments) {
    if (isPreview) {
      return (
        <div className={css.comments}>
          {Array.from({ length: 5 }).map((_, index) => (
            <Skeleton key={index} className={css.skeleton} />
          ))}
        </div>
      )
    }

    return (
      <div
        className={cx(css.body__content, {
          [css.body__content_pwa]: isPWA()
        })}
      >
        <Loading />
      </div>
    )
  }

  if (comments.length === 0) {
    return null
  }

  return (
    <div
      className={cx(css.comments, {
        [css.comments_preview]: isPreview
      })}
      ref={scrollRef}
    >
      {comments.map((comment) => (
        <div key={comment.id} className={css.comment}>
          <div className={css.comment__header}>
            <div>{renderName(comment)}</div>
            <div className={css.comment__info}>
              {comment.created_at === null ? (
                <p>Отправляется...</p>
              ) : (
                <time
                  className={css.comment__date}
                  dateTime={format(
                    parseISO(comment.created_at),
                    'dd.MM.yyyy (HH:mm)'
                  )}
                >
                  {format(parseISO(comment.created_at), 'dd.MM.yyyy (HH:mm)')}
                </time>
              )}
              {comment.is_author &&
                setCommentUpdate &&
                comment.created_at !== null && (
                  <MobileCommentActions
                    comment={comment}
                    setCommentUpdate={setCommentUpdate}
                    deleteCommentHandle={deleteCommentHandle}
                  />
                )}
            </div>
          </div>
          <p className={css.comment__text}>{comment.text}</p>
        </div>
      ))}
    </div>
  )
}
