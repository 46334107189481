import {
  selectSearchGuestPhone,
  setSearchGuestPhone
} from '@/entities/search-guests'
import { Reservation } from '@/features/create-update-reservation'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { PhoneInput, VaulHeader } from '@/shared/ui'

import css from './GuestsSearchHeader.module.css'

export default function GuestsSearchHeader() {
  const dispatch = useAppDispatch()
  const phoneNum = useAppSelector(selectSearchGuestPhone)

  const phoneInputHandler = (value: string) => {
    dispatch(setSearchGuestPhone(value))
  }

  return (
    <VaulHeader>
      <div className={css.header}>
        <PhoneInput
          icon="phone"
          variant="secondary"
          className={css.header__search}
          value={phoneNum}
          onValueChange={phoneInputHandler}
          placeholder="+7 (999) 999-99-99"
        />
        <div />
      </div>
      <Reservation.MobileCloseCreate />
    </VaulHeader>
  )
}
