export const mapHalls = (halls: RawHall[]): Hall[] => {
  return halls?.map(
    ({
      id,
      name,
      places_count,
      places_free_count,
      configurations,
      schedule
    }) => ({
      count: `${places_free_count}/${places_count}`,
      id,
      name,
      configurations,
      schedule
    })
  )
}
