/* eslint-disable boundaries/element-types */
import { useState } from 'react'

import { Card } from '@/shared/ui'

import MobileComments from './Comments/MobileComments'
import { CommentsVaul } from './CommentsVaul/CommentsVaul'
import { MobileTags } from './Tags/MobileTags'

import css from './MobileTagsAndComments.module.css'

type MobileTagsAndCommentsProps = {
  tags: SimpleTag[]
  tagsKit?: SimpleTag[]
  isErrorTagsKit?: boolean
  isLoadingTagsKit?: boolean
  isLoadingSetTags?: boolean
  isLoadingCreateTag?: boolean
  setTagsHandle: (tags: SimpleTag[]) => Promise<void>
  createTagKitHandle?: (name: string) => Promise<void>
  comments?: UserComment[]
  isLoadingComments?: boolean
  isErrorComments?: boolean
  deleteCommentHandle: (id: number) => Promise<void>
  updateCommentHandle: (id: number, text: string) => Promise<void>
  createCommentHandle: (text: string) => Promise<void>
}

export default function MobileTagsAndComments({
  tagsKit,
  tags,
  isErrorTagsKit,
  isLoadingTagsKit,
  isLoadingSetTags,
  isLoadingCreateTag,
  setTagsHandle,
  createTagKitHandle,
  comments,
  isLoadingComments,
  isErrorComments,
  deleteCommentHandle,
  updateCommentHandle,
  createCommentHandle
}: MobileTagsAndCommentsProps) {
  const [tagsState, setTagsState] = useState<SimpleTag[]>(tags)

  return (
    <Card className={css.card}>
      <div className={css.card__content}>
        <MobileTags
          tagsState={tagsState}
          tagsKit={tagsKit}
          isErrorTagsKit={isErrorTagsKit}
          isLoadingTagsKit={isLoadingTagsKit}
          isLoadingSetTags={isLoadingSetTags}
          isLoadingCreateTagKit={isLoadingCreateTag}
          setTagsState={setTagsState}
          setTagsHandle={setTagsHandle}
          createTagKitHandle={createTagKitHandle}
        />
        <MobileComments
          isPreview
          comments={comments}
          isLoadingComments={isLoadingComments}
          isErrorComments={isErrorComments}
          deleteCommentHandle={deleteCommentHandle}
        />
      </div>
      <CommentsVaul
        comments={comments}
        isLoadingComments={isLoadingComments}
        isErrorComments={isErrorComments}
        deleteCommentHandle={deleteCommentHandle}
        updateCommentHandle={updateCommentHandle}
        createCommentHandle={createCommentHandle}
      />
    </Card>
  )
}
