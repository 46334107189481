import { memo, useEffect } from 'react'

import { config } from '@/app/config'
import {
  selectCurDate,
  selectHours,
  selectTables,
  setTablesTimeSlots,
  TimeSlot
} from '@/entities/timeline'
import { useAppDispatch, useAppSelector } from '@/shared/model'

import { computeTimeSlots } from '../lib'

import css from './Reservations.module.css'
const { GROUP_HOUR_COEFFICIENT } = config

type ReservationsProps = {
  showEmptySuggestion: boolean
}

export const Reservations = memo(function Reservations({
  showEmptySuggestion
}: ReservationsProps) {
  const dispatch = useAppDispatch()
  const tables = useAppSelector(selectTables)
  const hours = useAppSelector(selectHours)
  const curDate = useAppSelector(selectCurDate)

  const tablesTimeSlots = tables.map((table) => ({
    id: table.id,
    timeSlots: computeTimeSlots(table, hours, curDate)
  }))

  useEffect(() => {
    dispatch(setTablesTimeSlots(tablesTimeSlots))
  }, [tablesTimeSlots])

  return (
    <>
      {tablesTimeSlots.map((table) => (
        <div
          key={table.id}
          className={css.root}
          style={{
            gridTemplateColumns: `repeat(
              auto-fill,
              calc(var(--timeline-cell-width) / ${GROUP_HOUR_COEFFICIENT})
            )`
          }}
        >
          {table.timeSlots.map((slot) => (
            <TimeSlot
              key={slot.id}
              slot={slot}
              showEmptySuggestion={showEmptySuggestion}
            />
          ))}
        </div>
      ))}
    </>
  )
})
