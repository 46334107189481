import { useEffect } from 'react'

export default function DynamicViewportUnits() {
  useEffect(() => {
    const handleWindowResize = () => {
      if (!window.visualViewport) return

      const dvh = window.visualViewport.height / 100
      const dvw = window.visualViewport.width / 100

      document.body.style.setProperty('--dvh', `${dvh}px`)
      document.body.style.setProperty('--dvw', `${dvw}px`)
    }

    handleWindowResize()
    window.visualViewport?.addEventListener('resize', handleWindowResize)

    return () => {
      window.visualViewport?.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return null
}
