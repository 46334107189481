import { useEffect, useState } from 'react'

import { format } from 'date-fns'

import { setMapDatetime } from '@/entities/map'
import { nowWithTimezone } from '@/shared/lib'
import { useAppDispatch } from '@/shared/model'

import { TimelineDatePicker } from '../../TimelineDatePicker'
import { TimeSwiper } from '../../TimeSwiper'

import css from './TimelineSwiper.module.css'

type TimelineSwiperProps = {
  isDatePicker?: boolean
}

export default function TimelineSwiper({
  isDatePicker = true
}: TimelineSwiperProps) {
  const dispatch = useAppDispatch()
  const [curDate, setCurDate] = useState<Date>(nowWithTimezone())
  const [activeTime, setActiveTime] = useState<Date>()

  useEffect(() => {
    const mapDatetime = new Date(curDate)

    const hours = activeTime?.getHours()
    const minutes = activeTime?.getMinutes()

    if (hours !== undefined && minutes !== undefined) {
      mapDatetime.setHours(hours)
      mapDatetime.setMinutes(minutes)
      mapDatetime.setSeconds(0)
      mapDatetime.setMilliseconds(0)
    }

    dispatch(setMapDatetime(mapDatetime))
  }, [curDate, activeTime])

  return (
    <div className={css.timeline}>
      <div className={css.timeline__info}>
        <div />
        <h2 className={css.timeline__time}>
          {activeTime ? format(activeTime, 'HH:mm') : ''}
        </h2>
        {isDatePicker ? (
          <TimelineDatePicker
            selectedDate={curDate}
            selectDate={setCurDate}
            disablePrevDays
          />
        ) : (
          <div />
        )}
      </div>
      <TimeSwiper setActiveTime={setActiveTime} />
    </div>
  )
}
