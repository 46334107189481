import { type ComponentPropsWithoutRef } from 'react'

import { cx } from 'class-variance-authority'

import css from './Skeleton.module.css'

type SkeletonProps = ComponentPropsWithoutRef<'div'>

export default function Skeleton({ className, ...props }: SkeletonProps) {
  return <div className={cx(css.skeleton, className)} {...props} />
}
