import * as Checkbox from '@radix-ui/react-checkbox'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Label } from '@/shared/ui'

import css from './ReservationsFilterMulti.module.css'

type ReservationsFilterMultiProps = {
  filter: Filter
}

export default function ReservationsFilterMulti({
  filter
}: ReservationsFilterMultiProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { label, options, key } = filter

  const checkedChangeHandler = (
    value: string,
    checked: Checkbox.CheckedState
  ) => {
    if (typeof checked === 'boolean') {
      const paramsValues = searchParams.getAll(key)
      const valueExists = Boolean(
        paramsValues.find((paramsValue) => paramsValue === value)
      )

      if (valueExists) {
        const newValues = paramsValues.filter(
          (paramValue) => paramValue !== value
        )
        searchParams.delete(key)
        newValues.forEach((newValue) => searchParams.append(key, newValue))
      } else searchParams.append(key, value)

      navigate(pathname + '?' + searchParams.toString())
    }
  }

  return (
    <div className={css.filter}>
      <Label className={css.filter__label}>{label}</Label>
      <div className={css.filter__options}>
        {options.map((option) => {
          const isChecked = Boolean(
            searchParams
              .getAll(key)
              .find((paramsValue) => paramsValue === option.value)
          )
          return (
            <Checkbox.Root
              key={option.value}
              checked={isChecked}
              onCheckedChange={(checked) =>
                checkedChangeHandler(option.value, checked)
              }
              className={css.filter__item}
            >
              {option.label}
            </Checkbox.Root>
          )
        })}
      </div>
    </div>
  )
}
