import { VaulBody } from '@/shared/ui'

import { GuestInfoBadges } from './GuestInfoBadges'
import { GuestPhone } from './GuestPhone'
import { GuestTabs } from './GuestTabs'

import css from './GuestVaulCardContent.module.css'

type GuestVaulCardContentProps = {
  guest: Guest
}

export default function GuestVaulCardContent({
  guest
}: GuestVaulCardContentProps) {
  const { phone } = guest

  return (
    <VaulBody className={css.card}>
      <GuestPhone phone={phone} />
      <GuestInfoBadges guest={guest} />
      <GuestTabs guest={guest} />
    </VaulBody>
  )
}
