import { useState } from 'react'

import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  changeShowPrompt,
  selectReservationComments,
  selectReservationDate,
  selectReservationDuration,
  selectReservationEnd,
  selectReservationGuest,
  selectReservationPersonsCount,
  selectReservationStart,
  selectReservationTables,
  selectReservationTags
} from '@/entities/create-update-reservation'
import {
  ReservationCard,
  useCreateReservationMutation
} from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { getTz } from '@/shared/api'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
  Button,
  Icon
} from '@/shared/ui'

import css from './GoToConfirmReservationButton.module.css'

export function GoToConfirmReservationButton() {
  const [open, setOpen] = useState(false)
  const addressId = useAppSelector(selectSelectedAddressId)
  const selectedDate = useAppSelector(selectReservationDate)
  const selectedGuest = useAppSelector(selectReservationGuest)
  const personsCount = useAppSelector(selectReservationPersonsCount)
  const selectedStart = useAppSelector(selectReservationStart)
  const selectedTables = useAppSelector(selectReservationTables)
  const selectedTags = useAppSelector(selectReservationTags)
  const selectedComments = useAppSelector(selectReservationComments)
  const selectedEnd = useAppSelector(selectReservationEnd)
  const selectedDuration = useAppSelector(selectReservationDuration)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [createReservation, { isLoading }] = useCreateReservationMutation()

  const tz = getTz()
  const isSubmitAvailable =
    selectedGuest &&
    selectedDate &&
    selectedStart &&
    (selectedEnd ?? selectedEnd === null) &&
    (selectedDuration ?? selectedDuration === null) &&
    personsCount &&
    selectedTables.length > 0

  const submitHandler = async () => {
    if (!addressId || !isSubmitAvailable || !tz) return

    try {
      dispatch(changeShowPrompt(false))
      const body: RequestNewReservation = {
        start_date: format(selectedStart, 'yyyy-MM-dd HH:mm'),
        end_date: selectedEnd
          ? format(selectedEnd, 'yyyy-MM-dd HH:mm')
          : undefined,
        persons_count: personsCount,
        places: selectedTables.map((table) => table.id),
        guest: selectedGuest.id,
        source: 'book',
        tags: selectedTags?.map((tag) => tag.id) as number[],
        comments: selectedComments?.map((comment) => comment.text)
      }
      await createReservation({ addressId, body })
      toast.success('Бронь успешно создана !')
      setOpen(false)
      navigate(-1)
    } catch {
      // do nothing
    }
  }

  if (
    !selectedGuest ||
    !personsCount ||
    !selectedStart ||
    selectedEnd === undefined
  ) {
    return null
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button variant="primary" size="sm" className={css.button_next}>
          Продолжить
          <Icon name="arrowRight" size={20} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent
        className={css.content}
        onClose={() => setOpen(false)}
      >
        <h1 className={css.title}>Подтвердите параметры брони</h1>
        <ReservationCard
          guest={selectedGuest}
          personsCount={personsCount}
          startDate={selectedStart}
          mainTableNumber={selectedTables
            .map((table) => table.item_number)
            .join(', ')}
          source="book"
          link={null}
          tags={selectedTags ?? []}
          endDate={selectedEnd}
        />
        <Button
          variant="primary"
          size="lg"
          className={css.button_confirm}
          onClick={submitHandler}
          isLoading={isLoading}
          disabled={!isSubmitAvailable || isLoading}
        >
          Забронировать
        </Button>
      </AlertDialogContent>
    </AlertDialog>
  )
}
