import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export enum GuestPageSteps {
  VIEW,
  EDIT
}

type GuestFilterState = {
  step: GuestPageSteps
}

const initialState: GuestFilterState = {
  step: GuestPageSteps.VIEW
}

export const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    setGuestPageStep(state, { payload }: PayloadAction<GuestPageSteps>) {
      state.step = payload
    },
    resetGuestPageRecord: () => initialState
  }
})

export const { setGuestPageStep, resetGuestPageRecord } = guestSlice.actions

export const selectGuestPageStep = (state: RootState) => state.guest.step
