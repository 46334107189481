import { type MouseEvent, type PropsWithChildren, useState } from 'react'

import { useUpdateReservationMutation } from '@/entities/reservation'
import {
  ReservationSortEnum,
  ReservationStatusEnum,
  selectReservationsSortType
} from '@/entities/reservations'
import {
  selectCanEditAddress,
  selectSelectedAddressId
} from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  type ButtonStyleProps
} from '@/shared/ui'

type CancelReservationProps = ButtonStyleProps &
  PropsWithChildren & {
    reservationId: number | string | undefined
    className?: string
    updateReservationsList?: (
      reservationId: number,
      isDelete: boolean,
      data?: Partial<Reservation>
    ) => void
  }

export function CancelReservation({
  children,
  reservationId,
  size,
  className,
  updateReservationsList,
  ...props
}: CancelReservationProps) {
  const addressId = useAppSelector(selectSelectedAddressId)
  const canEditAddress = useAppSelector(selectCanEditAddress)
  const sortType = useAppSelector(selectReservationsSortType)
  const [open, setOpen] = useState(false)

  const [cancelReservation, { isLoading }] = useUpdateReservationMutation()

  const toggleDialogHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  const cancelReservationHandler = async () => {
    if (!addressId || !reservationId || Number.isNaN(+reservationId)) return

    try {
      const data = await cancelReservation({
        addressId,
        reservationId: +reservationId,
        body: { status: ReservationStatusEnum.canceled }
      }).unwrap()
      updateReservationsList?.(
        data.id,
        ReservationSortEnum.default !== sortType,
        { status: data.status }
      )
      setOpen(false)
    } catch {
      // do nothing
    }
  }

  return (
    <AlertDialog open={open}>
      <AlertDialogTrigger asChild>
        <Button
          onClick={toggleDialogHandler}
          disabled={!canEditAddress}
          size={size}
          variant="transparentRed"
          className={className}
          {...props}
        >
          {children}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Вы уверены ?</AlertDialogTitle>
          <AlertDialogDescription>
            Если вы&nbsp;завершите, то&nbsp;статус бронирование перейдет в
            статус отмененного, изменить это действие уже будет нельзя
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              onClick={cancelReservationHandler}
            >
              Отменить
            </Button>
          </AlertDialogAction>
          <AlertDialogCancel disabled={isLoading} onClick={toggleDialogHandler}>
            Отмена
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
