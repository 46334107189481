import { baseApi, GUEST_TAG, GUESTS_TAG } from '@/shared/api'
import { formatFiltersForRequest } from '@/shared/lib'

export const guestApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    guests: builder.query<ResponseGuest, GuestsParams>({
      query: ({
        chain,
        address,
        limit,
        offset,
        phone,
        name,
        search,
        ordering,
        filters,
        is_anonymous
      }) => {
        const params = new URLSearchParams()

        params.set('address', String(address))
        params.set('limit', String(limit))
        params.set('offset', String(offset))
        if (name) params.set('name', String(name))
        if (phone) params.set('phone', String(phone))
        if (search) params.set('search', String(search))
        if (ordering) params.set('ordering', String(ordering))
        if (is_anonymous) params.set('is_anonymous', String(is_anonymous))

        return {
          url: `/book/chains/${chain}/guests/?${params.toString()}${formatFiltersForRequest(
            filters
          )}`
        }
      },
      providesTags: [GUESTS_TAG]
    }),
    anonymousGuest: builder.query<
      Guest,
      { chainId: number; addressId: number }
    >({
      query: ({ chainId, addressId }) => {
        const params = new URLSearchParams()

        params.set('address', String(addressId))
        params.set('is_anonymous', String(true))

        return {
          url: `/book/chains/${chainId}/guests/?${params.toString()}`
        }
      },
      transformResponse: (data: Guest[]) => data?.[0]
    }),
    guest: builder.query<Guest, GuestParams>({
      query: ({ chain, address, guestId, start_date, end_date }) => ({
        url: `/book/chains/${chain}/guests/${guestId}/`,
        params: {
          address,
          start_date,
          end_date
        }
      }),
      providesTags: [GUEST_TAG]
    }),
    createGuest: builder.mutation<Guest, CreateGuestParams>({
      query: ({ chainId, body }) => ({
        url: `/book/chains/${chainId}/guests/`,
        method: 'POST',
        body
      }),
      invalidatesTags: [GUESTS_TAG, GUEST_TAG]
    }),
    updateGuest: builder.mutation<NewGuest, UpdateGuestParams>({
      query: ({ chainId, guestId, body }) => ({
        url: `/book/chains/${chainId}/guests/${guestId}/`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: [GUESTS_TAG, GUEST_TAG]
    }),
    deleteGuest: builder.mutation<void, { chainId: number; guestId: number }>({
      query: ({ chainId, guestId }) => ({
        url: `/book/chains/${chainId}/guests/${guestId}/`,
        method: 'DELETE'
      }),
      invalidatesTags: [GUESTS_TAG]
    })
  })
})

export const {
  useGuestsQuery,
  useGuestQuery,
  useLazyGuestQuery,
  useCreateGuestMutation,
  useDeleteGuestMutation,
  useUpdateGuestMutation,
  useAnonymousGuestQuery
} = guestApi
