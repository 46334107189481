/* eslint-disable boundaries/element-types */

import { type Dispatch, type SetStateAction } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { customAlphabet } from 'nanoid'

import { selectProfileData, selectSelectedChainId } from '@/entities/session'
import { useGuestTagsQuery } from '@/entities/tags'
import { useAppSelector } from '@/shared/model'

import MobileTagsAndComments from '../MobileTagsAndComments'

type MobileGuestTagsAndCommentsProps = {
  tags: SimpleTag[]
  setTags: Dispatch<SetStateAction<SimpleTag[]>>
  comments: UserComment[]
  setComments: Dispatch<SetStateAction<UserComment[]>>
}

export default function MobileGuestTagsAndComments({
  tags,
  setTags,
  comments,
  setComments
}: MobileGuestTagsAndCommentsProps) {
  const chainId = useAppSelector(selectSelectedChainId)
  const profileData = useAppSelector(selectProfileData)
  const nanoid = customAlphabet('1234567890', 18)

  const {
    data: tagsKit,
    isLoading: isLoadingTagsKit,
    isError: isErrorTagsKit
  } = useGuestTagsQuery(chainId ? { chainId } : skipToken, {
    refetchOnMountOrArgChange: true
  })

  const deleteCommentHandle = async (id: number) => {
    setComments((prev) => prev.filter((comment) => comment.id !== id))
  }

  const updateCommentHandle = async (id: number, text: string) => {
    setComments((prev) =>
      prev.map((comment) =>
        comment.id === id
          ? {
              ...comment,
              text
            }
          : comment
      )
    )
  }

  const createCommentHandle = async (text: string) => {
    if (!profileData) return

    setComments((prev) => [
      ...prev,
      {
        id: Number(nanoid()),
        is_author: true,
        created_at: new Date().toISOString(),
        text,
        author: {
          id: profileData.id,
          first_name: profileData.first_name,
          last_name: profileData.last_name,
          image: profileData.image
        }
      }
    ])
  }

  const setTagsHandle = async (tags: SimpleTag[]) => {
    setTags(tags)
  }

  // const createTagKitHandle = async (name: string) => {
  //   if (!addressId) return

  //   await createTag({ addressId, body: { name } })
  // }

  return (
    <MobileTagsAndComments
      tags={tags}
      tagsKit={tagsKit?.map((tag) => ({
        id: tag.id,
        name: tag.tag
      }))}
      isErrorTagsKit={isErrorTagsKit}
      isLoadingTagsKit={isLoadingTagsKit}
      setTagsHandle={setTagsHandle}
      comments={comments}
      deleteCommentHandle={deleteCommentHandle}
      updateCommentHandle={updateCommentHandle}
      createCommentHandle={createCommentHandle}
    />
  )
}
