/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable boundaries/element-types */
import { cx } from 'class-variance-authority'
import { differenceInMinutes, isAfter, isBefore, isEqual } from 'date-fns'

import { ReservationStatusEnum } from '@/entities/reservations'
import { useUpdateComponent } from '@/shared/hooks'
import { minutesToHourMinutes, nowWithTimezone } from '@/shared/lib'

import css from './ReservationProgress.module.css'

type ReservationProgressProps = {
  start: Date
  end?: Date | null
  status?: ReservationStatus
}

const getProgress = (percent: number) => {
  switch (true) {
    case percent < 0:
      return 0
    case percent > 100:
      return 100
    default:
      return percent
  }
}

export default function ReservationProgress({
  start,
  end,
  status
}: ReservationProgressProps) {
  useUpdateComponent()

  const timeDifferenceFromEnd = end ? differenceInMinutes(end, start) : 0
  const timeDifferencePassed = differenceInMinutes(nowWithTimezone(), start)
  const timeDifferenceLeft = end
    ? differenceInMinutes(end, nowWithTimezone())
    : 0
  const timeDifferenceIsUp = end
    ? differenceInMinutes(nowWithTimezone(), end)
    : 0

  const [isUpHours, isUpMinutes] = minutesToHourMinutes(timeDifferenceIsUp)
  const [leftHours, leftMinutes] = minutesToHourMinutes(timeDifferenceLeft)

  const percent = timeDifferencePassed / (timeDifferenceFromEnd / 100)
  const progress = getProgress(percent)
  const isTableFurtherOccupied = leftHours === 0 && leftMinutes <= 10

  const isSkipped = end && isAfter(nowWithTimezone(), end)

  const isLateClient =
    end &&
    status === ReservationStatusEnum.confirmed &&
    (isEqual(nowWithTimezone(), start) ||
      (isAfter(nowWithTimezone(), start) && isBefore(nowWithTimezone(), end)))

  const statusTextRender = () => {
    if (isSkipped) {
      return 'Время закончилось'
    }
    if (isLateClient) {
      return 'Опаздывает'
    }
    if (isTableFurtherOccupied) {
      return 'Стол дальше занят'
    }
    return 'В заведении'
  }

  const leftTimeTextRender = () => {
    if (isSkipped) {
      return `-${isUpHours}:${String(isUpMinutes).padStart(2, '0')}`
    }
    if (isLateClient || isSkipped) {
      return `ожидаем - ${leftHours}:${String(leftMinutes).padStart(2, '0')}`
    }
    return `осталось - ${leftHours}:${String(leftMinutes).padStart(2, '0')}`
  }

  if (
    !isSkipped &&
    !isLateClient &&
    status !== ReservationStatusEnum.inProgress
  ) {
    return null
  }

  return (
    <div className={css.progress}>
      <div className={css.progress__info}>
        <p
          className={cx(css.progress__status, {
            [css.progress__status_red]:
              isTableFurtherOccupied || isLateClient || isSkipped
          })}
        >
          {statusTextRender()}
        </p>
        <p className={css['progress__left-time']}>{leftTimeTextRender()}</p>
      </div>
      <div className={css.progress__background}>
        {end && (
          <div
            className={cx(css.progress__line, {
              [css.progress__line_red]:
                isTableFurtherOccupied || isLateClient || isSkipped
            })}
            style={{ width: `${progress}%` }}
          />
        )}
      </div>
    </div>
  )
}
