/* eslint-disable boundaries/element-types */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  differenceInHours,
  differenceInMinutes,
  isWithinInterval,
  minutesInHour
} from 'date-fns'

import { MIN_COMING_SOON_TIME, MIN_RECENTLY_COMPLETED_TIME } from '@/app/config'
import { ReservationStatusEnum } from '@/entities/reservations'
import { dateFormat } from '@/shared/lib'

import { type TableTime } from './slice'
import { TableStatusEnum } from './tableStatus'

interface Payload {
  sliceDate: string | Date
  reservations: TransformedNewReservations
  sliceEndDate?: string | Date
}

const formatTime = (hourDiff: number, minDiff: number) =>
  `${hourDiff}:${`0${minDiff % 60}`.slice(-2)}`

export const getSliceOfTime = createAsyncThunk(
  'reservations/getSliceOfTime',
  async (data: Payload, thunkAPI) => {
    const { sliceDate, reservations, sliceEndDate } = data

    const tablesTimeData = {} as { [key: number]: TableTime }

    reservations.forEach(({ start_date, end_date, table, status, guest }) => {
      if (
        status === ReservationStatusEnum.unconfirmed ||
        status === ReservationStatusEnum.canceled
      ) {
        return
      }

      const currentTime = dateFormat(sliceDate)

      if (currentTime.getTime() < start_date.getTime()) {
        const hourDiff = differenceInHours(start_date, currentTime)
        const minDiff = differenceInMinutes(start_date, currentTime)

        const time = formatTime(hourDiff, minDiff)
        const minInDay = 24 * minutesInHour
        const timePercent = Math.round((minDiff * 100) / minInDay)
        const maxTimePercent = timePercent > 100 ? 100 : timePercent

        if (minDiff <= MIN_COMING_SOON_TIME) {
          tablesTimeData[table] = {
            time,
            timePercent: maxTimePercent,
            guest,
            status: TableStatusEnum.comingSoon
          }
        } else {
          tablesTimeData[table] = {
            time,
            timePercent: maxTimePercent,
            guest,
            status: undefined
          }
        }
      }

      if (currentTime.getTime() > end_date.getTime()) {
        const hourDiff = differenceInHours(currentTime, end_date)
        const minDiff = differenceInMinutes(currentTime, end_date)

        const time = formatTime(hourDiff, minDiff)
        const minInDay = 24 * minutesInHour
        const timePercent = Math.round((minDiff * 100) / minInDay)
        const maxTimePercent = timePercent > 100 ? 100 : timePercent

        if (minDiff <= MIN_RECENTLY_COMPLETED_TIME) {
          tablesTimeData[table] = {
            time,
            timePercent: maxTimePercent,
            guest,
            status: TableStatusEnum.recentlyCompleted
          }
        }
      }

      const reservationInProgressTime = isWithinInterval(currentTime, {
        start: start_date,
        end: end_date
      })

      if (reservationInProgressTime) {
        const hourDiff = differenceInHours(end_date, currentTime)
        const minDiff = differenceInMinutes(end_date, currentTime)
        const allTime = differenceInMinutes(end_date, start_date)

        const time = formatTime(hourDiff, minDiff)

        const timePercent = Math.round((minDiff * 100) / allTime)
        const maxTimePercent = timePercent > 100 ? 100 : timePercent

        tablesTimeData[table] = {
          time,
          timePercent: maxTimePercent,
          status: TableStatusEnum.inProgress,
          guest
        }
      }

      if (sliceEndDate) {
        const formattedSliceEndDate = dateFormat(sliceEndDate)

        const reservationStartInCurrentSlice = isWithinInterval(start_date, {
          start: currentTime,
          end: formattedSliceEndDate
        })
        const reservationEndInCurrentSlice = isWithinInterval(end_date, {
          start: currentTime,
          end: formattedSliceEndDate
        })
        if (reservationStartInCurrentSlice || reservationEndInCurrentSlice) {
          tablesTimeData[table] = {
            status: TableStatusEnum.inProgress,
            guest
          }
        }
      }
    })

    return tablesTimeData
  }
)
