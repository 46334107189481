import { type Dispatch, type SetStateAction, useState } from 'react'

import { cx } from 'class-variance-authority'
import { format, isBefore } from 'date-fns'

import {
  selectReservationEnd,
  selectReservationStart,
  setReservationEnd
} from '@/entities/create-update-reservation'
import {
  selectAllIntervalsEndTime,
  selectCurDayIntervalEndTime,
  selectCurDayIntervalIdxEndTime,
  setCurDayIntervalIdxEndTime
} from '@/entities/reservation-time-picker'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Button,
  Icon,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/shared/ui'

import css from './EndTime.module.css'

type EndTimeProps = {
  isEndTimeInPopover: boolean
  setIsEndTimeInPopover: Dispatch<SetStateAction<boolean>>
  setIsDurationInPopover: Dispatch<SetStateAction<boolean>>
}

export function EndTime({
  isEndTimeInPopover,
  setIsEndTimeInPopover,
  setIsDurationInPopover
}: EndTimeProps) {
  const [open, setOpen] = useState<boolean>(false)
  const curDayIntervalEndTime = useAppSelector(selectCurDayIntervalEndTime)
  const curDayIntervalIdxEndTime = useAppSelector(
    selectCurDayIntervalIdxEndTime
  )
  const allIntervalsEndTime = useAppSelector(selectAllIntervalsEndTime)
  const selectedStart = useAppSelector(selectReservationStart)
  const selectedEnd = useAppSelector(selectReservationEnd)
  const dispatch = useAppDispatch()

  const dayIntervalAddHandler = () => {
    dispatch(setCurDayIntervalIdxEndTime(curDayIntervalIdxEndTime + 1))
  }

  const dayIntervalSubHandler = () => {
    dispatch(setCurDayIntervalIdxEndTime(curDayIntervalIdxEndTime - 1))
  }

  const selectEndHandler = (date: Date) => {
    if (!selectedStart) return

    setOpen(false)
    setIsEndTimeInPopover(true)
    setIsDurationInPopover(false)
    if (isBefore(date, selectedStart)) {
      dispatch(
        setReservationEnd(
          (24 - selectedStart.getHours() + date.getHours()) * 60 +
            date.getMinutes() -
            selectedStart.getMinutes()
        )
      )
    } else {
      dispatch(
        setReservationEnd(
          (date.getHours() - selectedStart.getHours()) * 60 +
            date.getMinutes() -
            selectedStart.getMinutes()
        )
      )
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="tertiary"
          className={cx(css.button, css.button__quick, {
            [css.button_active]: isEndTimeInPopover
          })}
        >
          {`Время окончания${
            selectedEnd && isEndTimeInPopover
              ? ` - ${format(selectedEnd, 'H:mm')}`
              : ''
          }`}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        sideOffset={8}
        alignOffset={8}
        align="start"
        side="top"
        variant="glass"
        className={css.picker}
      >
        <div className={css.picker__header}>
          <Label className={css.picker__title}>Время окончания</Label>
          <Button
            size="icon-sm"
            variant="gray"
            onClick={dayIntervalSubHandler}
            disabled={curDayIntervalIdxEndTime === 0}
          >
            <Icon name="arrowLeft" size={20} />
          </Button>
          <Button
            size="icon-sm"
            variant="gray"
            onClick={dayIntervalAddHandler}
            disabled={
              curDayIntervalIdxEndTime === allIntervalsEndTime.length - 1
            }
          >
            <Icon name="arrowRight" size={20} />
          </Button>
        </div>
        <div className={css.picker__options}>
          {curDayIntervalEndTime.hours.map((hour) => (
            <Button
              key={hour.toISOString()}
              className={cx(css.button, {
                [css.button_active]:
                  selectedEnd &&
                  selectedEnd.getHours() === hour.getHours() &&
                  selectedEnd.getMinutes() === hour.getMinutes()
              })}
              variant="gray"
              onClick={() => selectEndHandler(hour)}
            >
              {format(hour, 'HH:mm')}
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}
