import { type ApexOptions } from 'apexcharts'

import { getArrMinMax } from '@/shared/lib'

import { getRangeBarData } from './lib'

import { formatMinAndSeconds, weekdays } from '../../lib'
import {
  chartGlobal,
  globalOptions,
  xaxisGlobal,
  yaxisGlobalStyles
} from '../config'

export const options: ApexOptions = {
  ...globalOptions,
  plotOptions: {
    bar: {
      borderRadius: 15
    }
  },
  colors: ['var(--accent-color)'],
  chart: {
    ...chartGlobal
  },
  grid: {
    borderColor: 'var(--background-color)'
  },
  yaxis: {
    min: 0,
    tickAmount: 9,
    labels: {
      ...yaxisGlobalStyles,
      formatter: (value) => {
        const min = Math.floor(value / 60)
        const sec = value % 60
        return `${min}:${sec.toString().padStart(2, '0')}`
      }
    }
  },
  xaxis: {
    ...xaxisGlobal,
    type: 'datetime',
    tickAmount: 6,
    tickPlacement: 'on',
    categories: weekdays,
    labels: {
      style: {
        cssClass: 'text_xs text_normal',
        colors: Array(7).fill('var(--gray-4)')
      },
      offsetX: -6,
      datetimeFormatter: {
        month: 'dd.MM',
        day: 'dd.MM'
      }
    }
  },
  tooltip: {
    custom: ({ _, seriesIndex, dataPointIndex, w }) => {
      const initialData =
        w.globals.initialSeries[seriesIndex].data[dataPointIndex].y

      const allAvg = w.globals.initialSeries[seriesIndex].data.map(
        (item: RangeBarData) => item.goals[0].value
      )
      const [min, max] = getArrMinMax(initialData)

      const curAvg = allAvg[dataPointIndex]

      return `
      <div class="apex-area-chart-tooltip">
        <span class="text_xl text_bold">
          Ср. ${formatMinAndSeconds(curAvg)} мин
        </span>
        <span class="text_base text_normal text_gray3">
          Макс. ${formatMinAndSeconds(max)} мин
        </span>
        <span class="text_base text_normal text_gray3">
          Мин. ${formatMinAndSeconds(min)} мин
        </span>
      </div>`
    }
  },
  states: {
    active: {
      filter: {
        type: 'none'
      }
    }
  }
}

const dataSet = [
  [45, 150],
  [85, 180],
  [110, 160],
  [150, 260],
  [105, 220],
  [150, 270],
  [150, 270]
]

export const series = [
  {
    name: 'series3',
    data: getRangeBarData(weekdays, dataSet)
  }
]
