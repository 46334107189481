import { CreateGuest as MobileCreate } from './create-mobile'
import { DeleteButton as Delete } from './delete'
import { EditButton as Edit, GuestForm as Form } from './edit'
import { EditGuest as MobileEdit } from './edit-mobile'

export const Guest = {
  Delete,
  Form,
  Edit,
  MobileCreate,
  MobileEdit
}
