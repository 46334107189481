import { differenceInMinutes, isAfter, isBefore } from 'date-fns'

import { nowWithTimezone } from '@/shared/lib'

import { TimelineSortEnum } from '../model/sortTypes'

export const sortTables = (
  stateTables: Table[],
  sortType: TimelineSortEnum
) => {
  const tables = [...stateTables]
  switch (sortType) {
    case TimelineSortEnum.default:
      return tables.sort((a, b) => a.item_number - b.item_number)
    case TimelineSortEnum.bookedASC:
      return tables.sort(
        (a, b) => a.reservations.length - b.reservations.length
      )
    case TimelineSortEnum.closest: {
      const now = nowWithTimezone()

      return tables.sort((a, b) => {
        const minDiffA = Math.min(
          ...a.reservations
            .map((res) => differenceInMinutes(res.start_date, now))
            .filter((diff) => diff >= 0)
        )

        const minDiffB = Math.min(
          ...b.reservations
            .map((res) => differenceInMinutes(res.start_date, now))
            .filter((diff) => diff >= 0)
        )

        const nowInProgressA = a.reservations.find(
          (res) =>
            isAfter(now, res.start_date) &&
            isBefore(now, res.end_date) &&
            res.status === 'in_progress'
        )

        const nowInProgressB = a.reservations.find(
          (res) =>
            isAfter(now, res.start_date) &&
            isBefore(now, res.end_date) &&
            res.status === 'in_progress'
        )

        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        if (nowInProgressA) {
          return -1
        }

        if (nowInProgressB) {
          return 1
        }

        return minDiffA - minDiffB
      })
    }

    default:
      return tables
  }
}
