/* eslint-disable boundaries/element-types */
import {
  type CSSProperties,
  memo,
  type MouseEventHandler,
  useCallback,
  useState
} from 'react'

import { cva, cx } from 'class-variance-authority'
import { isMobileOnly } from 'react-device-detect'

import { setAvailablePersonsCount } from '@/entities/take-table'
import {
  selectSelectedTimelineTables,
  selectTimelineTable,
  unselectTimelineTable
} from '@/entities/timeline'
import { useCSSImageLoading } from '@/shared/hooks'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Vaul, VaulContent, VaulTrigger } from '@/shared/ui'
import { TableVaulContent } from '@/widgets/map'

import { MapModeEnum } from '../../model/mapModes'
import {
  selectMapMode,
  selectSelectedTables,
  selectShowDeposits,
  selectTableToChangeTo
} from '../../model/selectors'
import { selectTable, type TableTime, unselectTable } from '../../model/slice'
import { TableStatusEnum } from '../../model/tableStatus'
import { MapStatus } from '../../ui/MapStatus/MapStatus'

import css from './MapItem.module.css'

function getDepositColor(
  deposits: DepositWithColors[],
  deposit: Deposit | null
) {
  if (!deposit) {
    return deposits[0].backgroundColor
  }

  return deposits.find(
    (depositWithColor) => depositWithColor.value === deposit.value
  )?.backgroundColor
}

interface MapItemProps {
  mapIdx?: number
  selectedTableId?: number
  table: ConfItem
  isNoInfo: boolean
  tableTime?: TableTime
  clickableDisabled?: boolean
  onlyView: boolean
  deposits?: DepositWithColors[]
  zoomTo?: string
  isPickTable?: boolean
  withDepositsInfo?: boolean
  withComingSoon?: boolean
  withRecentlyCompleted?: boolean
}

const mapItemStyle = cva(css.item__table, {
  variants: {
    isDisabled: {
      true: css.item__table_disabled,
      false: ''
    },
    isSelected: {
      true: css.item__table_selected,
      false: ''
    },
    isComingSoon: {
      true: css.item__table_soon,
      false: ''
    },
    isRecentlyCompleted: {
      true: css.item__table_recently,
      false: ''
    }
  },
  defaultVariants: {
    isDisabled: false,
    isSelected: false
  }
})

const MapItem = ({
  mapIdx,
  selectedTableId,
  table,
  tableTime,
  isNoInfo,
  onlyView,
  zoomTo,
  isPickTable,
  clickableDisabled = true,
  withDepositsInfo = false,
  withComingSoon = true,
  withRecentlyCompleted = false,
  deposits = []
}: MapItemProps) => {
  const dispatch = useAppDispatch()
  const [vaulOpen, setVaulOpen] = useState(false)
  const mapMode = useAppSelector(selectMapMode)
  const selectedTables = useAppSelector(selectSelectedTables)
  const tableToChangeTo = useAppSelector(selectTableToChangeTo)
  const timelineTables = useAppSelector(selectSelectedTimelineTables)
  const showDeposits = useAppSelector(selectShowDeposits)

  const {
    layer,
    is_free,
    x_coordinate,
    y_coordinate,
    width,
    height,
    direction,
    map_item
  } = table

  const {
    id,
    item_number,
    item,
    type,
    min_persons_count,
    max_persons_count,
    resize,
    deposit
  } = map_item
  const { image } = item

  const isCurrentSelected =
    Boolean(selectedTables.find((table) => table.id === id)) ||
    id === selectedTableId

  const isCurrentChangeToSelected = Boolean(
    tableToChangeTo.find((table) => table.id === id)
  )

  const { isImgLoaded } = useCSSImageLoading(image)

  const isEmpty = is_free !== undefined ? is_free : true
  const isTable = type === 'place'
  const isFreeTable = isTable && isEmpty

  const isInProgress = tableTime?.status === TableStatusEnum.inProgress
  const isComingSoon =
    tableTime?.status === TableStatusEnum.comingSoon && withComingSoon
  const isRecentlyCompleted =
    tableTime?.status === TableStatusEnum.recentlyCompleted &&
    withRecentlyCompleted

  const isDisabled = (!isFreeTable || isInProgress) && !isCurrentSelected

  const isSelected = isCurrentSelected || isCurrentChangeToSelected

  const isClickable =
    isTable && !(!clickableDisabled && isDisabled) && !onlyView

  const isDeposit =
    isFreeTable &&
    !isSelected &&
    deposits.length > 0 &&
    showDeposits &&
    withDepositsInfo

  const clickHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    if ((!clickableDisabled && isDisabled) || onlyView) return
    e.stopPropagation()

    const isMainTable = selectedTables[0]?.id === id

    if (isCurrentSelected) {
      if (mapMode === MapModeEnum.one) {
        dispatch(unselectTimelineTable())
      }
      dispatch(unselectTable(map_item))
      if (!isMainTable && mapMode === MapModeEnum.change) {
        dispatch(selectTable(map_item))
      }
    } else {
      setVaulOpen(true)
      dispatch(selectTable(map_item))
      dispatch(
        setAvailablePersonsCount({
          min: min_persons_count,
          max: max_persons_count
        })
      )
      if (mapMode === MapModeEnum.one) {
        dispatch(selectTimelineTable(id))
      }
    }
  }

  const tableStyles: CSSProperties = {
    WebkitMask: `url(${image}) no-repeat 50% 50%`,
    mask: `url(${image}) no-repeat 50% 50%`,
    maskSize: 'cover',
    WebkitMaskSize: 'cover',
    width,
    height,
    backgroundColor: isDeposit ? getDepositColor(deposits, deposit) : undefined
  }

  const otherObjStyles: CSSProperties = {
    background: `url(${image})`,
    backgroundSize: resize === 'block' ? 'cover' : 'contain',
    backgroundRepeat: 'no-repeat',
    width,
    height
  }

  const mapItemContent = (
    <div
      id={zoomTo ? `${mapIdx ?? ''}${id}` : undefined}
      className={cx(css.item, { [css.item_hidden]: !isImgLoaded })}
      style={{
        transform: `translate(${x_coordinate}px,${y_coordinate}px) rotate(${direction}deg)`,
        transformOrigin: '0% 0%',
        zIndex: layer,
        pointerEvents: isClickable ? 'auto' : 'none',
        cursor: isTable ? 'pointer' : 'auto'
      }}
    >
      <div
        data-vaul-no-drag
        className={mapItemStyle({
          isDisabled,
          isSelected,
          isComingSoon,
          isRecentlyCompleted
        })}
        style={isTable ? tableStyles : otherObjStyles}
        onClick={clickHandler}
      />
      <div
        className={css.item__info}
        style={{ rotate: `${-direction}deg`, transformOrigin: '0% 0%' }}
      >
        {!isNoInfo && isTable && (
          <MapStatus
            number={item_number}
            isSelected={isSelected}
            percent={tableTime?.timePercent}
            time={tableTime?.time}
            status={tableTime?.status}
          />
        )}
        {isNoInfo && (
          <span
            className={cx(css.item__number, {
              [css.item__number_available]:
                !isDisabled &&
                !isSelected &&
                !isComingSoon &&
                !isDeposit &&
                !isRecentlyCompleted
            })}
          >
            {item_number}
          </span>
        )}
      </div>
    </div>
  )

  const onVaulOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        dispatch(unselectTable(map_item))
      }
      setVaulOpen(open)
    },
    [map_item]
  )

  if (isMobileOnly && !isPickTable) {
    return (
      <Vaul open={vaulOpen} onOpenChange={onVaulOpenChange}>
        <VaulTrigger asChild>{mapItemContent}</VaulTrigger>
        <VaulContent>
          <TableVaulContent
            table={table}
            reservations={timelineTables[0]?.reservations}
            setOpen={setVaulOpen}
          />
        </VaulContent>
      </Vaul>
    )
  }

  return mapItemContent
}

export default memo(MapItem)
