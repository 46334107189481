import { type ComponentPropsWithoutRef } from 'react'

import { cva } from 'class-variance-authority'

import { TableStatusEnum } from '../../model/tableStatus'

import css from './MapStatus.module.css'

const statusStyle = cva(css.status, {
  variants: {
    isSelected: {
      true: css.status_selected,
      false: ''
    },
    isEmpty: {
      true: css.status_empty,
      false: ''
    },
    isComingSoon: {
      true: css.status_soon,
      false: ''
    }
  }
})

const statusIdStyle = cva(css.status__id, {
  variants: {
    isSelected: {
      true: css.status__id_selected,
      false: ''
    },
    isEmpty: {
      true: css.status__id_empty,
      false: ''
    },
    isComingSoon: {
      true: css.status__id_soon,
      false: ''
    }
  }
})

const statusTimeStyle = cva(css.status__time, {
  variants: {
    isSelected: {
      true: css.status__time_selected,
      false: ''
    },
    isEmpty: {
      true: css.status__time_empty,
      false: ''
    },
    isComingSoon: {
      true: css.status__time_soon,
      false: ''
    }
  }
})

const statusIndicatorStyle = cva(css.status__indicator, {
  variants: {
    isSelected: {
      true: css.status__indicator_selected,
      false: ''
    },
    isEmpty: {
      true: css.status__indicator_empty,
      false: ''
    }
  }
})

const statusIndicatorFillStyle = cva(css.status__indicatorFill, {
  variants: {
    isSelected: {
      true: css.status__indicatorFill_selected,
      false: ''
    },
    isEmpty: {
      true: css.status__indicatorFill_empty,
      false: ''
    }
  }
})

type MapStatusProps = ComponentPropsWithoutRef<'div'> & {
  isSelected?: boolean
  number: string
  time?: string
  percent?: number
  status?: string
}

export function MapStatus({
  number,
  isSelected = false,
  time,
  percent,
  status,
  className,
  ...props
}: MapStatusProps) {
  const isEmpty = !status

  const isComingSoon = status === TableStatusEnum.comingSoon

  return (
    <div
      className={statusStyle({ isSelected, isEmpty, isComingSoon, className })}
      {...props}
    >
      <div className={css.status__container}>
        <span className={statusIdStyle({ isSelected, isEmpty, isComingSoon })}>
          №{number}
        </span>
        <span
          className={statusTimeStyle({ isSelected, isEmpty, isComingSoon })}
        >
          {time ?? '00:00'}
        </span>
      </div>
      <div className={statusIndicatorStyle({ isSelected, isEmpty })}>
        <div
          className={statusIndicatorFillStyle({ isSelected, isEmpty })}
          style={{ width: `${percent ?? 0}%` }}
        />
      </div>
    </div>
  )
}
