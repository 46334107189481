/* eslint-disable boundaries/element-types */
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type KeyboardEvent,
  type MouseEvent
} from 'react'

import { cx } from 'class-variance-authority'

import {
  MobileReservationSteps,
  selectMobileReservationStep
} from '@/entities/create-update-reservation'
import { Reservation } from '@/features/create-update-reservation'
import {
  findStartingIntersection,
  formatName,
  formatPhoneNumberRU
} from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { Button, Card, Icon } from '@/shared/ui'

import { GuestTags } from '../GuestTags'

import css from './MobileSearchGuestCard.module.css'

type MobileSearchGuestCardProps = Pick<
  ComponentPropsWithoutRef<'div'>,
  'onClick'
> & {
  guest: Guest
  searchValue: string
}

const MobileSearchGuestCard = forwardRef<
  HTMLDivElement,
  MobileSearchGuestCardProps
>(({ guest, searchValue, ...props }, ref) => {
  const newReservationStep = useAppSelector(selectMobileReservationStep)

  const { first_name, last_name, phone, tags } = guest
  const guestName = formatName(first_name, last_name)
  const isPhone = !Number.isNaN(+searchValue)

  const [matchedName, otherNamePart] = findStartingIntersection(
    guestName,
    searchValue
  )

  const [matchedPhone, otherPhonePart] = findStartingIntersection(
    formatPhoneNumberRU(phone),
    formatPhoneNumberRU(searchValue)
  )

  const isNewReservationProcess =
    newReservationStep !== MobileReservationSteps.ONE

  const cardKeyDownHandler = (e: KeyboardEvent) => {
    if (e.code === 'Space' && !isNewReservationProcess) {
      props.onClick?.(e as unknown as MouseEvent<HTMLDivElement>)
    }
  }

  return (
    <Card
      ref={ref}
      role="button"
      tabIndex={0}
      onKeyDown={cardKeyDownHandler}
      className={css.card}
      {...props}
      onClick={!isNewReservationProcess ? props.onClick : undefined}
    >
      <div className={css.card__guest}>
        <div className={css.card__name}>
          <div
            className={cx(css.card__text, {
              [css.card__text_active]: !searchValue || isPhone
            })}
          >
            {matchedName && (
              <span className={css.card__text_active}>{matchedName}</span>
            )}
            <span>{otherNamePart}</span>
          </div>
          <GuestTags tags={tags} size="xs" showCount={1} onlyFirstOne />
        </div>
        <div className={css.card__text}>
          {matchedPhone && (
            <span className={cx(isPhone && css.card__text_active)}>
              {matchedPhone}
            </span>
          )}
          <span>{otherPhonePart}</span>
        </div>
      </div>
      <div className={css.card__actions}>
        <Reservation.MobileInit
          className={css.bookmark}
          size="icon-sm"
          guest={guest}
        >
          <Icon name="bookmark" />
        </Reservation.MobileInit>
        <Button
          className={css.phone}
          size="icon-sm"
          onClick={(e: MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
          disabled
        >
          <Icon name="phone" />
        </Button>
      </div>
    </Card>
  )
})

MobileSearchGuestCard.displayName = 'MobileSearchGuestCard'

export default MobileSearchGuestCard
