import { useState } from 'react'

import { INFINITE_SCROLL_RES } from '@/app/config'
import { MobileVisitCard, useGuestVisitsQuery } from '@/entities/visits'
import { Error, InfoBox, Loading } from '@/shared/ui'

import css from './GuestVisitsList.module.css'

type GuestVisitsListProps = {
  guestId: number
}

export default function GuestVisitsList({ guestId }: GuestVisitsListProps) {
  const [page] = useState(1)

  const { data, isLoading, isError, refetch } = useGuestVisitsQuery({
    guestId,
    limit: INFINITE_SCROLL_RES,
    offset: (page - 1) * INFINITE_SCROLL_RES
  })

  if (isLoading || !data) {
    return <Loading />
  }

  if (isError) {
    return <Error refetch={refetch} />
  }

  if (data?.results.length === 0) {
    return <InfoBox noIcon>Ничего не найдено</InfoBox>
  }

  return (
    <div className={css.visits}>
      {data.results.map((visit) => (
        <MobileVisitCard key={visit.id} visit={visit} />
      ))}
    </div>
  )
}
