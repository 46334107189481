/* eslint-disable boundaries/element-types */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  add,
  differenceInMinutes,
  isBefore,
  parseISO,
  startOfDay,
  sub
} from 'date-fns'
import { customAlphabet } from 'nanoid'

import { hallsApi, selectHall } from '@/entities/halls'
import { reservationRequestApi } from '@/entities/reservation-request'
import { getClosestNextFifteenMin } from '@/entities/timeline/lib'
import { nowWithTimezone } from '@/shared/lib'

import { updateHoursAndMinutes } from '../lib'

export enum ReservationModeEnum {
  CREATE,
  EDIT,
  TAKE_TABLE,
  FROM_STREET
}

interface AddReservationBody {
  end_date: Date
  guest: Guest
  hall_id: number
  persons_count: number
  places: MapItem[]
  start_date: Date
  duration: number
  status: ReservationStatus
}

interface AddReservationFromEmptySlotBody {
  hall_id: number
  persons_count: number
  tableIds: number[]
  start_date: Date
  duration: number
  guest?: Guest
}

type ReservationSliceState = {
  mode: ReservationModeEnum
  step: NewReservationStep
  skipStep?: ReservationSkipStep
  mobileStep: MobileNewReservationStep
  mobilePickersStep: MobileNewReservationPickersStep
  tables: MapItem[]
  tableIds: number[]
  duration?: number | null
  personsCount?: number
  start?: Date
  end?: Date | null
  hall?: number
  date?: Date
  guest?: BaseGuest
  tags?: SimpleTag[]
  comments?: UserComment[]
  isShowPrompt: boolean
  status?: ReservationStatus
  isNewGuest: boolean
}

export enum ReservationSteps {
  ONE,
  TWO,
  THREE
}

export enum MobileReservationSteps {
  ONE,
  TWO,
  THREE,
  FOUR
}

export enum MobileNewReservationPickersSteps {
  ONE,
  TWO
}

export enum ReservationSkipStep {
  GUEST = 'guest',
  TABLE = 'table'
}

const initialState: ReservationSliceState = {
  mode: ReservationModeEnum.CREATE,
  step: ReservationSteps.ONE,
  mobileStep: MobileReservationSteps.ONE,
  mobilePickersStep: MobileNewReservationPickersSteps.ONE,
  date: startOfDay(nowWithTimezone()),
  tables: [],
  tableIds: [],
  isShowPrompt: true,
  isNewGuest: false
}

export const createUpdateReservationSlice = createSlice({
  name: 'createUpdateReservation',
  initialState,
  reducers: {
    setReservationStep(state, { payload }: PayloadAction<ReservationSteps>) {
      state.step = payload
    },
    setMobileReservationStep(
      state,
      { payload }: PayloadAction<MobileReservationSteps>
    ) {
      state.mobileStep = payload
    },
    setMobileReservationPickersStep(
      state,
      { payload }: PayloadAction<MobileNewReservationPickersSteps>
    ) {
      state.mobilePickersStep = payload
    },
    setReservationMode: (
      state,
      { payload }: PayloadAction<ReservationModeEnum>
    ) => {
      state.mode = payload
    },
    setReservationDate: (
      state,
      { payload }: PayloadAction<Date | undefined>
    ) => {
      state.date = payload
      if (state.start && state.date) {
        state.start = updateHoursAndMinutes(state.date, state.start)
      }
      if (state.end && state.date) {
        state.end = updateHoursAndMinutes(state.date, state.end)
      }
    },
    setReservationPersonsCount: (state, { payload }: PayloadAction<number>) => {
      state.personsCount = payload
    },
    setReservationTables: (state, { payload }: PayloadAction<MapItem[]>) => {
      if (!payload) {
        state.tables = []
      } else {
        state.tables = [...payload]
      }
    },
    setReservationStart: (state, { payload }: PayloadAction<Date>) => {
      state.start = payload
      if (state.duration) {
        state.end = add(state.start, { minutes: state.duration })
      }
    },
    unsetReservationTime: (state) => {
      state.start = undefined
      state.end = undefined
    },
    setReservationEnd: (
      state,
      { payload }: PayloadAction<number | null | undefined>
    ) => {
      state.duration = payload

      if (state.start) {
        if (payload === null) {
          state.end = payload
        } else {
          state.end = payload
            ? add(state.start, { minutes: payload })
            : undefined
        }
      }
    },
    resetReservationDuration: (state) => {
      state.end = undefined
      state.duration = undefined
    },
    setReservationSituationEnd: (state, { payload }: PayloadAction<Date>) => {
      state.duration = undefined
      state.end = payload
    },
    setReservationGuest: (
      state,
      { payload }: PayloadAction<BaseGuest | undefined>
    ) => {
      state.guest = payload
    },
    setReservationIsNewGuest: (state, { payload }: PayloadAction<boolean>) => {
      state.isNewGuest = payload
    },
    setReservationTags: (
      state,
      { payload }: PayloadAction<SimpleTag[] | undefined>
    ) => {
      state.tags = payload
    },
    deleteReservationComment: (state, { payload }: PayloadAction<number>) => {
      state.comments = state.comments?.filter(
        (comment) => comment.id !== payload
      )

      if (state.comments?.length === 0) {
        state.comments = undefined
      }
    },
    createReservationComment: (
      state,
      { payload }: PayloadAction<{ profileData: ProfileData; text: string }>
    ) => {
      const nanoid = customAlphabet('1234567890', 18)

      if (!state.comments) {
        state.comments = []
      }

      state.comments.push({
        id: Number(nanoid()),
        is_author: true,
        created_at: new Date().toISOString(),
        text: payload.text,
        author: {
          id: payload.profileData.id,
          first_name: payload.profileData.first_name,
          last_name: payload.profileData.last_name,
          image: payload.profileData.image
        }
      })
    },
    updateReservationComment: (
      state,
      { payload }: PayloadAction<{ id: number; text: string }>
    ) => {
      state.comments = state.comments?.map((comment) =>
        comment.id === payload.id
          ? {
              ...comment,
              text: payload.text
            }
          : comment
      )
    },
    changeShowPrompt: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowPrompt = payload
    },
    addReservation: (
      state: ReservationSliceState,
      { payload }: PayloadAction<AddReservationBody>
    ) => {
      state.date = payload.start_date
      state.duration = differenceInMinutes(payload.end_date, payload.start_date)
      state.tables = [...payload.places]
      state.tableIds = [...payload.places.map((table) => table.id)]
      state.start = payload.start_date
      state.end = payload.end_date
      state.guest = payload.guest
      state.personsCount = payload.persons_count
      state.hall = payload.hall_id
      state.status = payload.status
    },
    addReservationFromEmptySlot: (
      state: ReservationSliceState,
      { payload }: PayloadAction<AddReservationFromEmptySlotBody>
    ) => {
      const now = nowWithTimezone()

      const startDate = getClosestNextFifteenMin(
        !isBefore(now, payload.start_date) ? now : payload.start_date
      )
      state.personsCount = payload.persons_count
      state.tableIds = [...payload.tableIds]
      state.date =
        startDate.getHours() < 7
          ? sub(payload.start_date, { days: 1 })
          : payload.start_date
      state.start = startDate
      state.end = add(startDate, { minutes: payload.duration })
      state.duration = payload.duration
      state.hall = payload.hall_id
      state.guest = payload?.guest
    },
    setReservationSkipStep: (
      state,
      { payload }: PayloadAction<ReservationSkipStep | undefined>
    ) => {
      state.skipStep = payload
    },
    resetTakeTablesReservation: (state) => {
      state.guest = undefined
      state.duration = undefined
      state.start = undefined
      state.end = undefined
      state.personsCount = undefined
    },
    resetReservation: () => initialState
  },
  extraReducers: (builder) => {
    const unselectTables = (state: ReservationSliceState) => {
      if (state.mode !== ReservationModeEnum.TAKE_TABLE) {
        state.tables = []
        state.tableIds = []
      }
    }

    builder
      .addCase(setReservationDate, unselectTables)
      .addCase(setReservationPersonsCount, unselectTables)
      .addCase(setReservationStart, unselectTables)
      .addCase(setReservationEnd, unselectTables)
      .addCase(
        selectHall,
        (state: ReservationSliceState, { payload }: PayloadAction<number>) => {
          state.hall = payload
          state.tables = []
        }
      )
      .addMatcher(
        hallsApi.endpoints.halls.matchFulfilled,
        (state: ReservationSliceState, { payload }) => {
          state.hall = payload[0]?.id
        }
      )
      .addMatcher(
        reservationRequestApi.endpoints.reservationRequest.matchFulfilled,
        (state: ReservationSliceState, { payload }) => {
          state.date = parseISO(payload.planned_start_dt)
          state.start = parseISO(payload.planned_start_dt)
          state.end = payload.planned_end_dt
            ? parseISO(payload.planned_end_dt)
            : undefined
          state.personsCount = payload.persons_count
        }
      )
  }
})

export const {
  setReservationMode,
  setReservationDate,
  setReservationPersonsCount,
  setReservationTables,
  setReservationStart,
  setReservationEnd,
  setReservationGuest,
  setReservationIsNewGuest,
  resetReservation,
  setReservationStep,
  resetReservationDuration,
  setReservationSkipStep,
  unsetReservationTime,
  setReservationSituationEnd,
  setReservationTags,
  deleteReservationComment,
  createReservationComment,
  updateReservationComment,
  resetTakeTablesReservation,
  changeShowPrompt,
  addReservation,
  addReservationFromEmptySlot,
  setMobileReservationStep,
  setMobileReservationPickersStep
} = createUpdateReservationSlice.actions
