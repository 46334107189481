import cn from 'classnames'

import css from './ColInfo.module.css'

type ColInfoProps = {
  label: string
  text: string | JSX.Element
  highlight?: boolean
}

export function ColInfo({ label, text, highlight = false }: ColInfoProps) {
  return (
    <div className={css.root}>
      <span className={cn('text_xs', 'text_bold', css.root__label)}>
        {label}
      </span>
      <h5
        className={cn('text_xl', 'text_bold', css.root__content, {
          [css.root__content_highlight]: highlight
        })}
      >
        {text}
      </h5>
    </div>
  )
}
