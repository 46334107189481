import { memo } from 'react'

import { isSameDay } from 'date-fns'

import { config } from '@/app/config'
import {
  selectCurDate,
  selectHours,
  selectMinutesLinePosition
} from '@/entities/timeline'
import { nowWithTimezone } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'

import { Hour } from './Hour'

import css from './HoursInterval.module.css'
const { GROUP_HOUR_COEFFICIENT } = config

export const HoursInterval = memo(function HoursInterval() {
  const hours = useAppSelector(selectHours)
  const curDate = useAppSelector(selectCurDate)
  const minutesLinePosition = useAppSelector(selectMinutesLinePosition)
  const now = nowWithTimezone()
  const isToday = isSameDay(curDate, now)

  const groupedHours = hours.filter(
    (hour) => hour.getMinutes() % GROUP_HOUR_COEFFICIENT === 0
  )

  return (
    <div className={css.root}>
      {groupedHours.slice(0, -1).map((hour, idx) => (
        <Hour key={idx} hour={hour} />
      ))}
      {isToday && (
        <div className={css.hour_line} style={{ left: minutesLinePosition }} />
      )}
    </div>
  )
})
