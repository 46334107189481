export const generateRangesColors = (
  colors: string[],
  {
    min,
    max
  }: {
    min: number
    max: number
  }
): Array<{
  from: number
  to: number
  color: string
}> => {
  return colors.map((color: string, idx: number) => {
    const diff = Math.floor((max - min) / colors.length)
    const from = idx * diff + min + (idx !== 0 ? 1 : 0)
    const to = idx * diff + min + diff
    return { from, to, color }
  })
}
