export const PATHS = {
  dashboard: '/dashboard',
  guest: '/guest',
  guests: '/guests',
  mailing: '/mailing',
  reservations: '/reservations',
  archive: '/archive',
  timeline: '/timeline',
  map: '/map',
  waitingList: '/waiting-list',
  settings: '/settings',
  login: '/login',
  signUp: '/sign-up',
  profile: '/profile',
  reservation: '/reservation',
  requests: '/requests',
  newReservation: '/new-reservation',
  addToWaitingList: '/add-to-waiting-list',
  guestCreate: '/guest-create',
  notFound: '/not-found'
}
