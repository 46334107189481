/* eslint-disable boundaries/element-types */
import { cx } from 'class-variance-authority'
import {
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  minutesInHour
} from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { config } from '@/app/config'
import { addReservationFromEmptySlot } from '@/entities/create-update-reservation'
import { selectHall } from '@/entities/halls'
import { MapModeEnum, setMapMode } from '@/entities/map'
import {
  ReservationByIdPageSteps,
  resetReservationByIdState,
  selectReservation,
  setReservationByIdPageStep
} from '@/entities/reservation'
import {
  formatMinToHoursAndMin,
  formatName,
  nowWithTimezone,
  PATHS
} from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Badge, Icon } from '@/shared/ui'

import {
  MAX_SLOT_DURATION_FOR_SUGGESTION,
  MIN_HIDDEN_EMPTY_SLOT,
  MIN_MEDIUM_BOOKED_SLOT,
  MIN_SLOT_DURATION_FOR_SUGGESTION,
  MIN_SMALL_EMPTY_SLOT
} from './config'

import { selectCurDate } from '../model/selectors'

import css from './TimeSlot.module.css'

const {
  MIN_RESERVATION_TIME_MIN,
  MIN_DURATION_INTERVAL_MIN,
  PICKERS_SPLIT_HOUR_BY,
  WORKING_HOURS_START
} = config

type TimeSlotProps = {
  slot: TimeSlot
  showEmptySuggestion: boolean
}

export function TimeSlot({ slot, showEmptySuggestion }: TimeSlotProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const reservationById = useAppSelector(selectReservation)
  const curDate = useAppSelector(selectCurDate)

  const workingHoursStart = new Date(curDate)
  workingHoursStart.setHours(WORKING_HOURS_START)

  const now = nowWithTimezone()

  const {
    id,
    guest,
    persons_count,
    booked,
    status,
    comment,
    spanStart,
    spanEnd,
    start_date,
    end_date
  } = slot

  const isReservationNow = isAfter(now, start_date) && isBefore(now, end_date)

  const isReservationLate =
    isReservationNow && status !== 'in_progress' && status !== 'completed'

  const isSlotInFuture =
    isBefore(now, start_date) ||
    (isBefore(now, end_date) &&
      differenceInMinutes(end_date, now) >
        MIN_DURATION_INTERVAL_MIN + minutesInHour / PICKERS_SPLIT_HOUR_BY)

  const isReservationInPast =
    isAfter(now, end_date) && status && status !== 'completed'

  const minutes = differenceInMinutes(
    end_date,
    isAfter(start_date, workingHoursStart) ? start_date : workingHoursStart
  )

  const isSmall = minutes < MIN_SMALL_EMPTY_SLOT
  const isMedium = minutes < MIN_RESERVATION_TIME_MIN
  const isHidden = minutes < MIN_HIDDEN_EMPTY_SLOT

  const largeEnoughForReservation =
    minutes >=
      MIN_SLOT_DURATION_FOR_SUGGESTION +
        minutesInHour / PICKERS_SPLIT_HOUR_BY &&
    minutes <= MAX_SLOT_DURATION_FOR_SUGGESTION

  const isClickable =
    booked ||
    (isSlotInFuture && showEmptySuggestion && largeEnoughForReservation)

  const timeSlotClassName = cx({
    [css.root]: true,
    [css.root_default]: booked,
    [css.root_empty]: !booked,
    [css.root_empty_now]:
      !booked &&
      isSlotInFuture &&
      largeEnoughForReservation &&
      showEmptySuggestion,
    [css.root_empty_small]: isSmall,
    [css.root_empty_hidden]: isHidden,
    [css.root_default_late]: isReservationLate,
    [css.root_default_medium]: isMedium,
    [css.root_default_finished_late]: isReservationInPast
  })

  const slotClickHandler = () => {
    if (booked) navigate(`${PATHS.reservation}/${id}`)
    if (
      !booked &&
      isSlotInFuture &&
      largeEnoughForReservation &&
      showEmptySuggestion
    ) {
      const guest = reservationById?.guest

      dispatch(selectHall(slot.table.hall.id))

      dispatch(
        addReservationFromEmptySlot({
          start_date: slot.start_date,
          duration: MIN_DURATION_INTERVAL_MIN,
          persons_count: slot.table.min_persons_count,
          tableIds: [slot.table.id],
          hall_id: slot.table.hall.id,
          guest
        })
      )

      if (guest) {
        dispatch(setMapMode(MapModeEnum.one))
        dispatch(resetReservationByIdState())
        dispatch(setReservationByIdPageStep(ReservationByIdPageSteps.EDIT))
      } else {
        navigate(PATHS.newReservation)
      }
    }
  }

  let content

  if (!booked) {
    content = (
      <div className={css.empty}>
        {minutes >= MIN_RESERVATION_TIME_MIN ? (
          <span className={css.empty__time}>
            {formatMinToHoursAndMin(minutes)}
          </span>
        ) : (
          <Icon name="x" size={24} />
        )}
      </div>
    )
  }

  if (booked) {
    const isDefaultSize = minutes > MIN_MEDIUM_BOOKED_SLOT

    content = (
      <div
        className={cx(css.booked, {
          [css.booked_medium]: !isDefaultSize
        })}
      >
        {persons_count && !isMedium && (
          <Badge>
            <Icon name="users" />
            {persons_count}
          </Badge>
        )}
        {isDefaultSize && guest && (
          <span className={css.booked__name}>
            {formatName(guest.first_name, guest.last_name)}
          </span>
        )}
        {!isSmall && (
          <span className={css.booked__time}>
            {format(end_date, `${isDefaultSize ? 'до' : ''} HH:mm`)}
          </span>
        )}
        {isSmall && <Icon name="book" />}
        {comment && (
          <div className={css.booked__comment}>
            <Icon name="comment" size={20} />
          </div>
        )}
      </div>
    )
  }

  return (
    <button
      tabIndex={isClickable ? 0 : -1}
      style={{ gridColumn: `${spanStart} / ${spanEnd}` }}
      className={timeSlotClassName}
      onClick={slotClickHandler}
    >
      {content}
    </button>
  )
}
