/* eslint-disable boundaries/element-types */
import { type ChangeEvent, forwardRef } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { selectSelectedChainId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import { Error, Loading } from '@/shared/ui'

import { useGuestTagsQuery } from '../../api/tagsApi'

import css from './TagsPicker.module.css'

type TagsPickerProps = {
  value: string[]
  onChange: (value: string[]) => void
}

const TagsPicker = forwardRef<HTMLDivElement, TagsPickerProps>(
  ({ value, onChange }, ref) => {
    const chainId = useAppSelector(selectSelectedChainId)
    const { data, isError, isFetching, refetch } = useGuestTagsQuery(
      chainId ? { chainId } : skipToken
    )

    const changeTagsHandler = (e: ChangeEvent<HTMLInputElement>) => {
      const tag = e.target.value
      const isValueInArray = Boolean(value.find((val) => val === tag))
      let newArray = value
      if (isValueInArray) {
        newArray = value.filter((val) => val !== tag)
      } else {
        newArray = [...value]
        newArray.push(tag)
      }
      onChange(newArray)
    }

    if (isError) {
      return <Error refetch={refetch} />
    }

    if (isFetching || !data) {
      return <Loading />
    }

    return (
      <div ref={ref} className={css.tags}>
        {data.map((t) => (
          <label key={t.id} className={css.tags__label} htmlFor={String(t.id)}>
            <input
              id={String(t.id)}
              className={css.tags__input}
              type="checkbox"
              checked={Boolean(
                Array.isArray(value) &&
                  value.find((val) => val === String(t.id))
              )}
              value={String(t.id)}
              onChange={changeTagsHandler}
            />
            {t.tag}
          </label>
        ))}
      </div>
    )
  }
)

export default TagsPicker
