/* eslint-disable boundaries/element-types */
import { selectSelectedHallWithInfo } from '@/entities/halls'
import { useAppSelector } from '@/shared/model'
import { Badge, Icon, VaulHeader } from '@/shared/ui'

import css from './TableCardHeader.module.css'

type TableCardHeaderProps = {
  table: ConfItem
}

export default function TableCardHeader({ table }: TableCardHeaderProps) {
  const selectedHall = useAppSelector(selectSelectedHallWithInfo)
  const { map_item } = table
  const { item_number, min_persons_count, max_persons_count } = map_item

  return (
    <VaulHeader>
      <Badge className={css.header__badge} size="xs">
        {min_persons_count}-{max_persons_count} <Icon name="users" size={12} />
      </Badge>
      <div className={css.header__heading}>
        <h4 className={css.header__title}>Столик №{item_number}</h4>
        {selectedHall && (
          <span className={css.header__subtitle}>{selectedHall.name}</span>
        )}
      </div>
    </VaulHeader>
  )
}
