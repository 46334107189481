import { formatPhoneNumberRU } from '@/shared/lib'
import { Button, Card, Icon } from '@/shared/ui'

import css from './GuestPhone.module.css'

type GuestPhoneProps = {
  phone: string | null
}

export default function GuestPhone({ phone }: GuestPhoneProps) {
  return (
    <Card className={css.card}>
      <h4 className={css.card__phone}>{formatPhoneNumberRU(phone)}</h4>
      <Button className={css.card__call} size="icon" disabled={!phone}>
        <Icon name="phone" />
      </Button>
    </Card>
  )
}
