import { useEffect } from 'react'

import { format, isSameDay, parseISO } from 'date-fns'
import * as qs from 'querystringify'
import { useNavigate } from 'react-router-dom'

import {
  selectCurDate,
  selectTimelineSort,
  setCurTimelineDate,
  setSortType
} from '@/entities/timeline'
import { TimelineSortEnum } from '@/entities/timeline/model/sortTypes'
import { nowWithTimezone } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { DatePicker, Tabs } from '@/shared/ui'

type TabsType = Array<{
  id: TimelineSortEnum
  label: string
}>

const tabsToday: TabsType = [
  { id: TimelineSortEnum.closest, label: 'Ближайшие' },
  { id: TimelineSortEnum.default, label: 'По порядку' },
  { id: TimelineSortEnum.bookedASC, label: 'Самые свободные' }
]

const tabs: TabsType = [
  { id: TimelineSortEnum.default, label: 'По порядку' },
  { id: TimelineSortEnum.bookedASC, label: 'Самые свободные' }
]

export function TimelineFilters() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const sortType = useAppSelector(selectTimelineSort)
  const curDate = useAppSelector(selectCurDate)
  const now = nowWithTimezone()

  const setCurDateHandler = (date: Date) => {
    dispatch(setCurTimelineDate(date))
  }

  useEffect(() => {
    const searchParams = window.location.search
    if (searchParams) {
      const params = qs.parse(searchParams.slice(1)) as {
        sort: TimelineSortEnum
        date: string
      }
      if (params.sort) dispatch(setSortType(params.sort))

      setCurDateHandler(parseISO(params.date))
    }
  }, [])

  useEffect(() => {
    const str = qs.stringify({
      sort: sortType,
      date: format(curDate, 'yyyy-MM-dd')
    })
    navigate(`?${str}`)
  }, [sortType, curDate])

  useEffect(() => {
    if (!isSameDay(now, curDate) && sortType === TimelineSortEnum.closest) {
      dispatch(setSortType(TimelineSortEnum.default))
    }
  }, [curDate])

  return (
    <>
      <Tabs
        tabs={isSameDay(curDate, now) ? tabsToday : tabs}
        activeTab={sortType}
        setActiveTab={(id) => dispatch(setSortType(id))}
      />
      <DatePicker selectedDate={curDate} selectDate={setCurDateHandler} />
    </>
  )
}
