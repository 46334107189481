/* eslint-disable boundaries/element-types */
import { differenceInMinutes, format } from 'date-fns'

import { selectSelectedHallWithInfo } from '@/entities/halls'
import {
  formatDateLabel,
  formatDuration,
  formatName,
  formatPhoneNumberRU,
  formatTablesStr
} from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { ColInfo, Tag } from '@/shared/ui'

import css from './NewReservationSubmitInfo.module.css'

type NewReservationSubmitInfoProps = {
  reservation: ReservationDataWithGuest
}

const reservationSubmitInfoConfig = [
  { name: 'phone', label: 'Телефон' },
  { name: 'get_full_name', label: 'ФИО' },
  { name: 'date', label: 'Дата' },
  { name: 'time', label: 'Время' },
  { name: 'duration', label: 'Длительность' },
  { name: 'deposit', label: 'Депозит' },
  { name: 'hall', label: 'Зал' },
  { name: 'tables', label: 'Столики' }
]

export function NewReservationSubmitInfo({
  reservation
}: NewReservationSubmitInfoProps) {
  const { guest, start, end, tables, date } = reservation
  const selectedHall = useAppSelector(selectSelectedHallWithInfo)

  const serializedReservationInfo = {
    ...reservation,
    phone: formatPhoneNumberRU(guest.phone),
    get_full_name: formatName(guest.first_name, guest.last_name),
    deposit: '-',
    date: formatDateLabel(date),
    time: `${format(start, 'HH:mm')} - ${end ? format(end, 'HH:mm') : '-'}`,
    duration: end
      ? formatDuration(differenceInMinutes(end, start))
      : 'По ситуации',
    hall: selectedHall?.name,
    tables: formatTablesStr(
      tables.map((table) => table.item_number),
      4
    )
  }

  return (
    <div className={css.root}>
      {reservationSubmitInfoConfig.map(({ name, label }) => (
        <ColInfo
          key={label}
          label={label}
          text={
            `${
              serializedReservationInfo[name as keyof NewReservationSubmitInfo]
            }` ?? '-'
          }
        />
      ))}
      {guest.tags && guest.tags.length > 0 && (
        <div className={css.root__badge}>
          <Tag key={guest.tags[0].id} tag={guest.tags[0]} size="lg" />
        </div>
      )}
    </div>
  )
}
