/* eslint-disable import/no-duplicates */
import { type ApexOptions } from 'apexcharts'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { generateHeatmapData, generateRangesColors, weekdays } from '../../lib'
import {
  chartGlobal,
  globalOptions,
  xaxisGlobal,
  yaxisGlobalStyles
} from '../config'
import { ChartTooltip } from '../tooltipConfig'

export const options: ApexOptions = {
  ...globalOptions,
  plotOptions: {
    heatmap: {
      radius: 10,
      enableShades: false,
      colorScale: {
        ranges: generateRangesColors(
          ['#352625', '#603227', '#a0452c', '#f65d32'],
          {
            min: 0,
            max: 40
          }
        )
      }
    }
  },
  legend: {
    show: false
  },
  grid: {
    show: false
  },
  stroke: {
    width: 4,
    colors: ['var(--gray-2)']
  },
  colors: ['#f65d32'],
  chart: {
    type: 'heatmap',
    ...chartGlobal
  },
  yaxis: {
    labels: {
      ...yaxisGlobalStyles
    }
  },
  xaxis: {
    ...xaxisGlobal,
    type: 'category',
    categories: weekdays,
    labels: {
      style: {
        cssClass: 'text_xs text_normal',
        colors: Array(7).fill('var(--gray-4)')
      },
      formatter: (value) => {
        if (!value) return ''
        const date = new Date(value)
        const formattedDate = format(date, 'iiiiii', { locale: ru })
        return formattedDate.slice(0, 1).toUpperCase() + formattedDate.slice(1)
      }
    }
  },
  tooltip: {
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      const avg = Math.floor(
        series[seriesIndex].reduce((acc: number, cur: number) => acc + cur, 0) /
          series[seriesIndex].length
      )
      const cur = series[seriesIndex][dataPointIndex]
      const diff = cur - avg
      const curMessage = `${cur} чел`
      const diffMessage = `${diff > 0 ? '+' : ''}${diff} чел. от средней`

      return ChartTooltip(curMessage, diffMessage)
    }
  },
  states: {
    hover: {
      filter: {
        type: 'none'
      }
    },
    active: {
      filter: {
        type: 'none'
      }
    }
  }
}

export const series = [
  {
    name: '10',
    data: generateHeatmapData(7, {
      min: 0,
      max: 40
    })
  },
  {
    name: '12',
    data: generateHeatmapData(7, {
      min: 0,
      max: 40
    })
  },
  {
    name: '14',
    data: generateHeatmapData(7, {
      min: 0,
      max: 40
    })
  },
  {
    name: '16',
    data: generateHeatmapData(7, {
      min: 0,
      max: 40
    })
  },
  {
    name: '18',
    data: generateHeatmapData(7, {
      min: 0,
      max: 40
    })
  },
  {
    name: '20',
    data: generateHeatmapData(7, {
      min: 0,
      max: 40
    })
  },
  {
    name: '22',
    data: generateHeatmapData(7, {
      min: 0,
      max: 40
    })
  }
]
