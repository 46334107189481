import { GuestPageSteps, setGuestPageStep } from '@/entities/guest'
import { selectCanEditAddress } from '@/entities/session'
import { useIsDevice } from '@/shared/hooks'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon } from '@/shared/ui'

export function EditButton() {
  const dispatch = useAppDispatch()
  const { isMobile } = useIsDevice()
  const canEditAddress = useAppSelector(selectCanEditAddress)

  const editHandler = () => {
    dispatch(setGuestPageStep(GuestPageSteps.EDIT))
  }

  return (
    <Button onClick={editHandler} disabled={!canEditAddress}>
      {!isMobile && 'Редактировать'}
      <Icon name="pencil" />
    </Button>
  )
}
