import { format, parseISO } from 'date-fns'

import { formatDateLabel } from '@/shared/lib'
import { Icon } from '@/shared/ui'

import css from './RequestConfirmSuccessScreen.module.css'

type RequestConfirmSuccessScreenProps = {
  reservation: ReservationRequest
}

export default function RequestConfirmSuccessScreen({
  reservation
}: RequestConfirmSuccessScreenProps) {
  const { planned_start_dt, planned_end_dt } = reservation

  const formattedDate = formatDateLabel(parseISO(planned_start_dt))
  const formattedStart = format(parseISO(planned_start_dt), 'HH:mm')
  const formattedEnd = planned_end_dt
    ? format(parseISO(planned_end_dt), 'HH:mm')
    : 'По ситуации'

  return (
    <div className={css.success}>
      <div className={css.success__container}>
        <div className={css.success__check}>
          <Icon name="check" size={44} />
        </div>
        <div role="heading" aria-level={2} className={css.success__title}>
          Заявка на бронь успешно подтверждена
        </div>
        <p
          className={css.success__text}
        >{`${formattedDate}, ${formattedStart} - ${formattedEnd}`}</p>
      </div>
    </div>
  )
}
