import { Suspense } from 'react'

import { cx } from 'class-variance-authority'
import { Outlet } from 'react-router-dom'

import { isPWA } from '@/shared/lib'
import { Loading } from '@/shared/ui'
import { MobileNav } from '@/widgets/navigation'

import css from './MobileLayout.module.css'

type MobileLayoutProps = {
  withMenu?: boolean
  headerSlot?: JSX.Element
  footerSlot?: JSX.Element
  withBorderRadius?: boolean
}

export default function MobileLayout({
  withMenu = false,
  headerSlot,
  footerSlot,
  withBorderRadius = false
}: MobileLayoutProps) {
  return (
    <div className={css.layout}>
      {headerSlot && (
        <div
          className={cx(css.layout__header, {
            [css['layout__header_border-radius']]: withBorderRadius
          })}
        >
          {headerSlot}
        </div>
      )}
      <Suspense fallback={<Loading fullscreen />}>
        <Outlet />
      </Suspense>
      {withMenu && (
        <div
          className={cx(css.layout__footer, {
            [css.layout__footer_pwa]: isPWA(),
            [css['layout__footer_border-radius']]: withBorderRadius
          })}
        >
          {footerSlot}
          <MobileNav />
        </div>
      )}
    </div>
  )
}
