import { unselectTable } from '@/entities/map'
import { useAppDispatch } from '@/shared/model'
import { Button } from '@/shared/ui'

export function UnselectChangeTablesTo() {
  const dispatch = useAppDispatch()

  const unselectChangeTablesToHandler = () => {
    dispatch(unselectTable())
  }

  return <Button onClick={unselectChangeTablesToHandler}>Отменить</Button>
}
