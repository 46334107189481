/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { isBefore } from 'date-fns'

import { config } from '@/app/config'
import {
  MobileNewReservationPickersSteps,
  MobileReservationSteps,
  selectReservationDate,
  selectReservationDuration,
  selectReservationEnd,
  selectReservationGuest,
  selectReservationPersonsCount,
  selectReservationStart,
  selectReservationTables,
  setMobileReservationPickersStep,
  setMobileReservationStep,
  setReservationEnd,
  setReservationStart
} from '@/entities/create-update-reservation'
import { Reservation } from '@/features/create-update-reservation'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import {
  Card,
  Label,
  Tab,
  TabContent,
  TabsGroup,
  TabsProvider,
  VaulBody
} from '@/shared/ui'

import { ReservationProcessFooter } from '../../ReservationProcessFooter'
import { ReservationProcessHeader } from '../../ReservationProcessHeader'

import css from './ReservationTimeStep.module.css'

const { MIN_RESERVATION_TIME_MIN, PICKERS_INTERVAL } = config

export default function ReservationTimeStep() {
  const dispatch = useAppDispatch()
  const selectedGuest = useAppSelector(selectReservationGuest)
  const selectedDate = useAppSelector(selectReservationDate)
  const selectedStart = useAppSelector(selectReservationStart)
  const selectedEnd = useAppSelector(selectReservationEnd)
  const selectedPersonsCount = useAppSelector(selectReservationPersonsCount)
  const selectedDuration = useAppSelector(selectReservationDuration)
  const selectedTables = useAppSelector(selectReservationTables)

  const isPageDuration =
    !selectedDuration ||
    (selectedDuration >= MIN_RESERVATION_TIME_MIN &&
      selectedDuration <= 4 * 60 &&
      selectedDuration % PICKERS_INTERVAL === 0)

  const backHandler = () => {
    dispatch(
      setMobileReservationPickersStep(MobileNewReservationPickersSteps.ONE)
    )
  }

  const selectStartHandler = (date: Date) => {
    if (!selectedDate) return

    selectedDate.setHours(date.getHours(), date.getMinutes())
    dispatch(setReservationStart(selectedDate))
  }

  const selectEndHandler = (date: Date) => {
    if (!selectedStart) return

    if (isBefore(date, selectedStart)) {
      dispatch(
        setReservationEnd(
          (24 - selectedStart.getHours() + date.getHours()) * 60 +
            date.getMinutes() -
            selectedStart.getMinutes()
        )
      )
    } else {
      dispatch(
        setReservationEnd(
          (date.getHours() - selectedStart.getHours()) * 60 +
            date.getMinutes() -
            selectedStart.getMinutes()
        )
      )
    }
  }

  const nextStepHandler = () => {
    dispatch(
      setMobileReservationStep(
        selectedTables.length > 0
          ? MobileReservationSteps.FOUR
          : MobileReservationSteps.THREE
      )
    )
  }

  const isNextStepAvailable =
    selectedGuest &&
    selectedDate &&
    selectedStart &&
    (selectedEnd || selectedEnd === null) &&
    (selectedDuration || selectedDuration === null) &&
    selectedPersonsCount

  return (
    <VaulBody className={css.step}>
      <ReservationProcessHeader
        backHandler={backHandler}
        title="Выберите время"
      />
      <Card data-vaul-no-drag className={css.step__picker_start}>
        <Label className={css.step__label}>Время старта</Label>
        <Reservation.MobileTimePicker
          selectedValue={selectedStart}
          setSelectedValue={selectStartHandler}
        />
      </Card>
      <Card className={css.step__picker_duration}>
        <TabsProvider
          className={css.tabs}
          defaultValue={isPageDuration ? 'duration' : 'time'}
        >
          <TabsGroup variant="tertiary" className={css.tabs__group}>
            <Tab variant="tertiary" className={css.tabs__tab} value="duration">
              Длительность
            </Tab>
            <Tab variant="tertiary" className={css.tabs__tab} value="time">
              Время завершения
            </Tab>
          </TabsGroup>
          <TabContent value="duration">
            <Reservation.MobileDurationPicker />
          </TabContent>
          <TabContent data-vaul-no-drag value="time">
            <Reservation.MobileTimePicker
              selectedValue={selectedEnd}
              setSelectedValue={selectEndHandler}
            />
          </TabContent>
        </TabsProvider>
      </Card>
      <Card className={css.step__picker_persons}>
        <Reservation.PersonsPicker />
      </Card>
      <ReservationProcessFooter
        disabled={!isNextStepAvailable}
        onClick={nextStepHandler}
      >
        Далее
      </ReservationProcessFooter>
    </VaulBody>
  )
}
