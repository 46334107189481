/* eslint-disable boundaries/element-types */
import { type ComponentPropsWithoutRef, forwardRef } from 'react'

import { cx } from 'class-variance-authority'
import { Link } from 'react-router-dom'

import { MobileGuestWithTime } from '@/entities/guest'
import { MapModeEnum, selectMapMode } from '@/entities/map'
import { PATHS } from '@/shared/lib'
import { useAppSelector } from '@/shared/model'
import { Card } from '@/shared/ui'

import { ReservationCardActions } from './ReservationCardActions'

import { MobileReservationBadgeCarousel } from '../MobileReservationBadgeCarousel'

import css from './ReservationCard.module.css'

type ReservationCardProps = Pick<ComponentPropsWithoutRef<'div'>, 'onClick'> & {
  id?: number
  guest: BaseGuest
  personsCount: number
  startDate: Date
  mainTableNumber: string
  source: ReservationSource
  link: ReservationLink | null
  tags: SimpleTag[]
  status?: ReservationStatus
  endDate: Date | null
  withLink?: boolean
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation>
  ) => void
}

const ReservationCard = forwardRef<HTMLDivElement, ReservationCardProps>(
  (
    {
      id,
      guest,
      personsCount,
      startDate,
      mainTableNumber,
      source,
      link,
      tags,
      status,
      endDate,
      updateReservationsList,
      withLink = false,
      ...props
    },
    ref
  ) => {
    const mapMode = useAppSelector(selectMapMode)

    const isChangingTable = mapMode === MapModeEnum.change

    return (
      <Card
        ref={ref}
        className={css.card}
        onClick={isChangingTable ? undefined : props.onClick}
      >
        <div className={css.container}>
          <MobileGuestWithTime
            className={css.card__guest}
            guest={guest}
            startDate={startDate}
            endDate={endDate}
            status={status}
          />
          <div className={css.card__line} />
        </div>
        <MobileReservationBadgeCarousel
          className={css.card__badges}
          personsCount={personsCount}
          tableNumber={mainTableNumber}
          source={source}
          linkName={link?.name ?? null}
          startDate={startDate}
          tags={tags}
        />
        <div className={cx(css.container, css.actions)}>
          {id && status && (
            <ReservationCardActions
              id={id}
              status={status}
              endDate={endDate}
              updateReservationsList={updateReservationsList}
            />
          )}
        </div>
        {withLink && id && (
          <Link className={css.card__link} to={`${PATHS.reservation}/${id}`} />
        )}
      </Card>
    )
  }
)

ReservationCard.displayName = 'ReservationCard'

export default ReservationCard
