import { useEffect } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'

import { selectSelectedHall } from '@/entities/halls'
import {
  Map,
  MapModeEnum,
  selectTables,
  setMapMode,
  useMapTablesQuery
} from '@/entities/map'
import { selectSelectedAddressId } from '@/entities/session'
import { useTimelineTablesWSQuery } from '@/entities/timeline'
import { formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Error, Loading } from '@/shared/ui'

type MobileChangeReservationTableMapProps = {
  startDate?: Date
  endDate?: Date | null
  personsCount?: number
  places: number[]
}

export default function MobileChangeReservationTableMap({
  startDate,
  endDate,
  personsCount,
  places
}: MobileChangeReservationTableMapProps) {
  const dispatch = useAppDispatch()
  const addressId = useAppSelector(selectSelectedAddressId)
  const selectedHall = useAppSelector(selectSelectedHall)

  const {
    data: tables,
    isFetching: isTablesFetching,
    isError: tablesError,
    refetch: refetchTables
  } = useMapTablesQuery(
    startDate && endDate && personsCount && selectedHall
      ? {
          hallId: selectedHall,
          start_date: formatTimeForRequest(startDate),
          end_date: formatTimeForRequest(endDate),
          persons: personsCount
        }
      : skipToken
  )

  const {
    data: doubleResponse,
    isFetching: isReservationsFetching,
    isError: reservationsError,
    refetch: refetchReservations
  } = useTimelineTablesWSQuery(
    startDate && addressId
      ? {
          addressId,
          date: formatTimeForRequest(startDate).split(' ')[0]
        }
      : skipToken
  )

  useEffect(() => {
    dispatch(setMapMode(MapModeEnum.change))
  }, [])

  useEffect(() => {
    const reservationTables = tables
      ?.filter((table) => places.find((place) => place === table.map_item.id))
      .map((table) => table.map_item)

    if (reservationTables?.length) {
      dispatch(selectTables(reservationTables))
    }
  }, [places, tables])

  if (isTablesFetching || isReservationsFetching || !doubleResponse?.length) {
    return <Loading />
  }

  if (tablesError || reservationsError) {
    return <Error refetch={tablesError ? refetchTables : refetchReservations} />
  }

  return (
    <Map
      tables={tables}
      reservations={doubleResponse?.[doubleResponse.length - 1].reservations}
      isNoInfo
      isPickTable
      withRecentlyCompleted
    />
  )
}
