/* eslint-disable import/no-duplicates */
import { useMemo, useRef } from 'react'

import 'swiper/css'

import { cx } from 'class-variance-authority'
import { eachMonthOfInterval, isEqual, startOfMonth } from 'date-fns'
import { ru } from 'date-fns/locale'
import { DayPicker } from 'react-day-picker'
import { type ItemProps, Virtuoso, type VirtuosoHandle } from 'react-virtuoso'

import { nowWithTimezone } from '@/shared/lib'

import { Card } from '../Card'

import css from './DatePickerList.module.css'

function Footer() {
  return <div className={css.footer} />
}

function WrapperCard(props: ItemProps<Date>) {
  return <div {...props} className={css['wrapper-card']} />
}

type MonthCardProps = {
  month: Date
  selectedDate: Date | undefined
  selectDateHandler: (date: Date | undefined) => void
  startDate: Date
  endDate: Date
}

function MonthCard({
  month,
  selectedDate,
  selectDateHandler,
  startDate,
  endDate
}: MonthCardProps) {
  return (
    <Card className={css.card}>
      <DayPicker
        className={css.calendar}
        classNames={{
          caption_label: cx(css.calendar__caption_label, {
            [css.calendar__caption_label_active]:
              selectedDate && isEqual(month, startOfMonth(selectedDate))
          }),
          head_cell: css.calendar__head_cell,
          table: css.calendar__table,
          cell: css.calendar__cell,
          day: css.calendar__day,
          day_outside: css.calendar__day_outside,
          day_selected: css.calendar__day_selected,
          day_disabled: css.calendar__day_disabled,
          button: css.calendar__button,
          month: css.calendar__month,
          months: css.calendar__months,
          caption_start: css.calendar__caption_start
        }}
        defaultMonth={month}
        mode="single"
        disableNavigation
        selected={selectedDate}
        onSelect={selectDateHandler}
        locale={ru}
        showOutsideDays
        disabled={{ before: startDate, after: endDate }}
      />
    </Card>
  )
}

type DatePickerListProps = {
  selectedDate: Date | undefined
  selectDate: (date: Date | undefined) => void
  startDate: Date
  endDate: Date
}

export default function DatePickerList({
  selectedDate,
  selectDate,
  startDate,
  endDate
}: DatePickerListProps) {
  const months = useMemo(
    () =>
      eachMonthOfInterval({
        start: startDate,
        end: endDate
      }),
    []
  )
  const virtuosoRef = useRef<VirtuosoHandle | null>(null)
  const activeIndex = useMemo(
    () =>
      months.findIndex((date) =>
        isEqual(date, startOfMonth(selectedDate ?? nowWithTimezone()))
      ),
    []
  )

  const selectDateHandler = (date: Date | undefined) => {
    selectDate(date)
  }

  return (
    <div className={css.picker}>
      <Virtuoso
        ref={virtuosoRef}
        totalCount={months.length}
        initialTopMostItemIndex={activeIndex}
        height={391}
        data={months}
        components={{
          Item: WrapperCard,
          Footer
        }}
        itemContent={(_, month) => (
          <MonthCard
            month={month}
            selectedDate={selectedDate}
            selectDateHandler={selectDateHandler}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      />
    </div>
  )
}
