import { type Dispatch, type SetStateAction } from 'react'

import { TableCardContent } from './TableCardContent'
import { TableCardHeader } from './TableCardHeader'

import css from './TableVaulContent.module.css'

type TableVaulContentProps = {
  table: ConfItem
  reservations: TimelineReservation[]
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function TableVaulContent({
  table,
  reservations,
  setOpen
}: TableVaulContentProps) {
  return (
    <div className={css.card}>
      <TableCardHeader table={table} />
      <TableCardContent
        setOpen={setOpen}
        reservations={reservations}
        table={table.map_item}
      />
    </div>
  )
}
