function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const generateHeatmapData = (
  cols: number,
  {
    min,
    max
  }: {
    min: number
    max: number
  }
): number[] => {
  return Array(cols)
    .fill(0)
    .map(() => getRandomIntInclusive(min, max))
}
