import { useState } from 'react'

import { format } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  NewReservationSubmitInfo,
  ReservationModeEnum,
  resetReservation,
  selectReservationMode,
  useNewReservationSubmitAvailable
} from '@/entities/create-update-reservation'
import { useCreateReservationMutation } from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { getTz } from '@/shared/api'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon, Loading, Textarea } from '@/shared/ui'

import css from './SubmitReservationForm.module.css'

type SubmitReservationFormProps = {
  onComplete: () => void
}

export function SubmitReservationForm({
  onComplete
}: SubmitReservationFormProps) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const addressId = useAppSelector(selectSelectedAddressId)
  const createNewReservationMode = useAppSelector(selectReservationMode)

  const tz = getTz()
  const [comment, setComment] = useState('')
  const [isSubmitAvailable, newReservation] = useNewReservationSubmitAvailable()

  const [createReservation, { isLoading: isCreating, isSuccess }] =
    useCreateReservationMutation()

  if (!isSubmitAvailable) {
    dispatch(resetReservation())
    navigate('/')
  }

  const isFromStreet =
    createNewReservationMode === ReservationModeEnum.FROM_STREET

  const { handleSubmit } = useForm()

  const submitHandler = () => {
    if (!addressId || !tz) return

    const body: RequestNewReservation = {
      start_date: format(newReservation.start, 'yyyy-MM-dd HH:mm'),
      end_date: newReservation.end
        ? format(newReservation.end, 'yyyy-MM-dd HH:mm')
        : undefined,
      persons_count: newReservation.personsCount,
      places: newReservation.tables.map((table) => table.id),
      guest: newReservation.guest.id,
      comment,
      status: isFromStreet ? 'in_progress' : undefined,
      source: isFromStreet ? 'street' : 'book'
    }
    createReservation({ addressId, body })
  }

  if (isSuccess) {
    onComplete()
    toast.success('Бронь успешно создана !')
  }

  if (isCreating) {
    return <Loading />
  }

  return (
    <div className={css.root}>
      <form className={css.root__form} onSubmit={handleSubmit(submitHandler)}>
        <div className={css.root__info}>
          <NewReservationSubmitInfo reservation={newReservation} />
          <Textarea
            autoResize
            value={comment}
            setValue={setComment}
            placeholder="Введите комментарий"
          />
        </div>
        <Button variant="primary" type="submit">
          {isFromStreet ? 'Посадить' : 'Забронировать'}
          <Icon name="restaurant" />
        </Button>
      </form>
    </div>
  )
}
