import { useEffect, useState } from 'react'

import { cx } from 'class-variance-authority'
import { add, format, isAfter, isBefore, isEqual } from 'date-fns'

import {
  resetReservationDuration,
  selectReservationDate,
  selectReservationDuration,
  selectReservationStart,
  setReservationStart,
  useWorkingHoursDates
} from '@/entities/create-update-reservation'
import {
  generateNewIntervals,
  selectAllIntervals,
  selectCurDayInterval,
  selectCurDayIntervalIdx,
  setCurDayIntervalIdx
} from '@/entities/reservation-time-picker'
import { nowWithTimezone } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon, Label } from '@/shared/ui'

import css from './TimePicker.module.css'

export function TimePicker() {
  const [isNowActive, setIsNowActive] = useState(false)
  const dispatch = useAppDispatch()
  const curDayIntervalIdx = useAppSelector(selectCurDayIntervalIdx)
  const curDayInterval = useAppSelector(selectCurDayInterval)
  const reservationDate = useAppSelector(selectReservationDate)
  const reservationStart = useAppSelector(selectReservationStart)
  const reservationDuration = useAppSelector(selectReservationDuration)
  const allIntervals = useAppSelector(selectAllIntervals)
  const { workingHoursStart, workingHoursEnd } = useWorkingHoursDates()
  const now = nowWithTimezone()

  useEffect(() => {
    if (reservationDate) dispatch(generateNewIntervals(reservationDate))
  }, [reservationDate])

  const dayIntervalAddHandler = () => {
    dispatch(setCurDayIntervalIdx(curDayIntervalIdx + 1))
  }

  const dayIntervalSubHandler = () => {
    dispatch(setCurDayIntervalIdx(curDayIntervalIdx - 1))
  }

  const selectTimeHandler = (time: Date) => {
    dispatch(setReservationStart(time))
    if (
      reservationDuration &&
      isAfter(
        add(time, { minutes: reservationDuration }),
        add(workingHoursEnd, { days: 1 })
      )
    ) {
      dispatch(resetReservationDuration())
    }
  }

  return (
    <div className={css.picker}>
      <div className={css.picker__header}>
        <Label className={css.picker__title}>Время старта</Label>
        <Button
          size="icon-sm"
          variant="tertiary"
          onClick={dayIntervalSubHandler}
          disabled={curDayIntervalIdx === 0}
        >
          <Icon name="arrowLeft" size={20} />
        </Button>
        <Button
          size="icon-sm"
          variant="tertiary"
          onClick={dayIntervalAddHandler}
          disabled={curDayIntervalIdx === allIntervals.length - 1}
        >
          <Icon name="arrowRight" size={20} />
        </Button>
      </div>
      <div className={css.picker__options}>
        {curDayInterval.hours.map((hour) => {
          const isDisabled =
            isBefore(hour, now) ||
            isBefore(hour, workingHoursStart) ||
            (isAfter(hour, workingHoursEnd) &&
              isBefore(hour, add(workingHoursStart, { days: 1 })))

          const isActive = reservationStart && isEqual(hour, reservationStart)

          return (
            <Button
              key={hour.toISOString()}
              className={cx(css.picker__button, {
                [css.picker__button_active]: isActive && !isNowActive
              })}
              variant="tertiary"
              onClick={() => {
                selectTimeHandler(hour)
                setIsNowActive(false)
              }}
              disabled={isDisabled}
            >
              {format(hour, 'HH:mm')}
            </Button>
          )
        })}
        <Button
          className={cx(css.picker__button, css.picker__button_now, {
            [css.picker__button_active]: isNowActive
          })}
          variant="tertiary"
          onClick={() => {
            selectTimeHandler(nowWithTimezone())
            setIsNowActive(true)
          }}
        >
          Сейчас
        </Button>
      </div>
    </div>
  )
}
