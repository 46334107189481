/* eslint-disable boundaries/element-types */
import { startOfToday } from 'date-fns'

import { config } from '@/app/config'
import { selectReservationDate } from '@/entities/create-update-reservation'
import { useAppSelector } from '@/shared/model'

export const useWorkingHoursDates = (options?: {
  today: boolean
}): {
  workingHoursStart: Date
  workingHoursEnd: Date
} => {
  const reservationDate = useAppSelector(selectReservationDate)
  const today = startOfToday()

  const workingHoursStart =
    !reservationDate || options?.today ? today : new Date(reservationDate)
  const workingHoursEnd =
    !reservationDate || options?.today ? today : new Date(reservationDate)

  // TODO remove hardcoded values and this tmp solution
  workingHoursStart.setHours(config.WORKING_HOURS_START)
  workingHoursEnd.setHours(config.WORKING_HOURS_END)

  return {
    workingHoursStart,
    // TODO remove hardcoded values and this tmp solution
    workingHoursEnd
  }
}
