import { useState } from 'react'

import { Button, Vaul, VaulContent, VaulTrigger } from '@/shared/ui'

import { EditGuestForm } from './EditGuestForm'

import css from './EditGuest.module.css'

type EditGuestProps = {
  guest: Guest
}

export default function EditGuest({ guest }: EditGuestProps) {
  const [open, setOpen] = useState(false)

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Button variant="transparent" className={css.edit}>
          Редактировать
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen>
        <EditGuestForm guest={guest} setOpen={setOpen} />
      </VaulContent>
    </Vaul>
  )
}
