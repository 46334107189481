import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useUpdateReservationSaveAvailable } from '@/entities/create-update-reservation'
import {
  ReservationByIdPageSteps,
  setReservationByIdPageStep,
  useUpdateReservationMutation
} from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { dateToDateWithTimezone, formatTimeForRequest } from '@/shared/lib'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Button, Icon } from '@/shared/ui'

export function SaveReservationButton() {
  const dispatch = useAppDispatch()
  const [isAvailable, updatedReservation] = useUpdateReservationSaveAvailable()
  const { id: reservationId } = useParams()
  const addressId = useAppSelector(selectSelectedAddressId)
  const [update, { isLoading, isSuccess }] = useUpdateReservationMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(setReservationByIdPageStep(ReservationByIdPageSteps.VIEW))
    }
  }, [isSuccess])

  const saveReservationHandler = () => {
    if (!reservationId || Number.isNaN(+reservationId) || !addressId) return
    update({
      addressId,
      reservationId: +reservationId,
      body: {
        start_date: formatTimeForRequest(
          dateToDateWithTimezone(updatedReservation.start)
        ),
        end_date: updatedReservation.end
          ? formatTimeForRequest(dateToDateWithTimezone(updatedReservation.end))
          : undefined,
        places: updatedReservation.tables.map((table) => table.id),
        persons_count: updatedReservation.personsCount
      }
    })
  }

  return (
    <Button
      disabled={!isAvailable || !reservationId || !addressId}
      variant="primary"
      isLoading={isLoading}
      onClick={saveReservationHandler}
    >
      Сохранить
      <Icon name="check" size={18} />
    </Button>
  )
}
