/* eslint-disable boundaries/element-types */

import { skipToken } from '@reduxjs/toolkit/dist/query'

import {
  useDeleteReservationCommentMutation,
  useReservationCommentsQuery,
  useSendReservationCommentMutation,
  useUpdateReservationCommentMutation
} from '@/entities/comments'
import { useSetReservationTagsMutation } from '@/entities/reservation/api/reservationApi'
import { selectSelectedAddressId } from '@/entities/session'
import {
  useCreateReservationTagMutation,
  useReservationTagsQuery
} from '@/entities/tags'
import { useAppSelector } from '@/shared/model'

import MobileTagsAndComments from '../MobileTagsAndComments'

type MobileReservationTagsAndCommentsProps = {
  tags: SimpleTag[]
  reservationId: number
  updateReservationsList?: (
    reservationId: number,
    isDelete: boolean,
    data?: Partial<Reservation>
  ) => void
}

export default function MobileReservationTagsAndComments({
  tags,
  reservationId,
  updateReservationsList
}: MobileReservationTagsAndCommentsProps) {
  const addressId = useAppSelector(selectSelectedAddressId)
  const {
    data: comments,
    isLoading: isLoadingComments,
    isError: isErrorComments
  } = useReservationCommentsQuery(
    { reservationId: +reservationId },
    { refetchOnMountOrArgChange: true }
  )

  const {
    data: tagsKit,
    isLoading: isLoadingTagsKit,
    isError: isErrorTagsKit
  } = useReservationTagsQuery(addressId ? { addressId } : skipToken, {
    refetchOnMountOrArgChange: true
  })

  const [deleteComment] = useDeleteReservationCommentMutation()
  const [createComment] = useSendReservationCommentMutation()
  const [updateComment] = useUpdateReservationCommentMutation()

  const [setReservationTags, { isLoading: isLoadingSetTags }] =
    useSetReservationTagsMutation()
  const [createTag, { isLoading: isLoadingCreateTag }] =
    useCreateReservationTagMutation()

  const deleteCommentHandle = async (id: number) => {
    if (!comments) return

    await deleteComment({
      reservationId,
      commentId: id
    })
    updateReservationsList?.(reservationId, false, {
      comments_count: comments.length - 1
    })
  }

  const updateCommentHandle = async (id: number, text: string) => {
    await updateComment({
      reservationId,
      commentId: id,
      body: {
        text
      }
    })
  }

  const createCommentHandle = async (text: string) => {
    if (!comments) return

    await createComment({
      reservationId,
      body: {
        text
      }
    })
    updateReservationsList?.(reservationId, false, {
      comments_count: comments.length + 1
    })
  }

  const setTagsHandle = async (tags: SimpleTag[]) => {
    if (!addressId) return

    await setReservationTags({
      addressId,
      reservationId,
      body: {
        tags
      }
    })
    updateReservationsList?.(reservationId, false, { tags })
  }

  const createTagKitHandle = async (name: string) => {
    if (!addressId) return

    await createTag({ addressId, body: { name } })
  }

  return (
    <MobileTagsAndComments
      tags={tags}
      tagsKit={tagsKit}
      isErrorTagsKit={isErrorTagsKit}
      isLoadingTagsKit={isLoadingTagsKit}
      isLoadingSetTags={isLoadingSetTags}
      isLoadingCreateTag={isLoadingCreateTag}
      setTagsHandle={setTagsHandle}
      createTagKitHandle={createTagKitHandle}
      comments={comments}
      isLoadingComments={isLoadingComments}
      isErrorComments={isErrorComments}
      deleteCommentHandle={deleteCommentHandle}
      updateCommentHandle={updateCommentHandle}
      createCommentHandle={createCommentHandle}
    />
  )
}
