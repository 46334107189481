import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { Cell } from './Body/Cell/Cell'
dayjs.extend(isBetween)

interface DayData {
  start: number
  end: number
  interval: number
  showingMonth: Date
  selectedInterval: IntervalState
  setIntervalHandler: (date: string) => void
}

interface EmptyDayData {
  start: number
  end: number
  interval: number
}

export const getDaysCellsArray = (data: DayData) => {
  const {
    start,
    end,
    interval,
    showingMonth,
    selectedInterval,
    setIntervalHandler
  } = data

  const checkCurrentDate = (date: number) =>
    dayjs(showingMonth).set('date', date).isSame(dayjs(), 'day')

  const { start_date, end_date } = selectedInterval

  const checkSelectedDate = (date: number) => {
    if (start_date && end_date) {
      return dayjs(showingMonth)
        .set('date', date)
        .isBetween(start_date, end_date, 'day', '[]')
    }

    return false
  }
  const isSelectStart = !!start_date && !end_date

  const checkStartDaySelected = (date: number) =>
    isSelectStart &&
    dayjs(showingMonth).set('date', date).isSame(start_date, 'day')

  const array = []

  const onDayClick = (date?: number) => {
    if (!date) return
    setIntervalHandler(
      dayjs(showingMonth).set('date', date).format('YYYY-MM-DD')
    )
  }

  for (let d = start; d <= end; d = d + interval) {
    const isCurrentDay = checkCurrentDate(d)
    const isSelectedDay = checkSelectedDate(d)
    const isStartDaySelected = checkStartDaySelected(d)

    array.push(
      <Cell
        day={d}
        isCurrentDay={isCurrentDay}
        isSelectedDay={isSelectedDay}
        isSelectStart={isSelectStart}
        isStartDaySelected={isStartDaySelected}
        onClick={onDayClick}
      />
    )
  }
  return array
}

export const getEmptyCellsArray = (data: EmptyDayData) => {
  const { start, end, interval } = data

  const array = []

  if (start === 0 || end === 0) return []

  for (let d = start; d <= end; d = d + interval) {
    array.push(<Cell />)
  }
  return array
}

export const getRowsArray = (totalArray: JSX.Element[], columns = 3) => {
  const rows: JSX.Element[][] = []
  let cells: JSX.Element[] = []

  totalArray.forEach((row, i) => {
    if (i % columns !== 0 || i === 0) {
      cells.push(row)
    } else {
      rows.push(cells)
      cells = []
      cells.push(row)
    }
  })
  rows.push(cells)

  return rows
}
