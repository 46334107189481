import * as React from 'react'

import * as ScrollAreaUI from '@radix-ui/react-scroll-area'
import { cx } from 'class-variance-authority'

import css from './ScrollArea.module.css'

type ScrollBarRef = React.ElementRef<typeof ScrollAreaUI.ScrollAreaScrollbar>

type ScrollBarProps = React.ComponentPropsWithoutRef<
  typeof ScrollAreaUI.ScrollAreaScrollbar
>

const ScrollBar = React.forwardRef<ScrollBarRef, ScrollBarProps>(
  ({ className, orientation = 'vertical', ...props }, ref) => (
    <ScrollAreaUI.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={css.scrollbar}
      {...props}
    >
      <ScrollAreaUI.ScrollAreaThumb className={css.thumb} />
    </ScrollAreaUI.ScrollAreaScrollbar>
  )
)
ScrollBar.displayName = 'ScrollBar'

type ScrollAreaRef = React.ElementRef<typeof ScrollAreaUI.Root>

type ScrollAreaProps = React.ComponentPropsWithoutRef<
  typeof ScrollAreaUI.Root
> & {
  orientation?: 'horizontal' | 'vertical' | 'both'
  viewPortClassName?: string
}

const ScrollArea = React.forwardRef<ScrollAreaRef, ScrollAreaProps>(
  (
    {
      className,
      children,
      orientation = 'vertical',
      viewPortClassName,
      ...props
    },
    ref
  ) => (
    <ScrollAreaUI.Root ref={ref} className={cx(css.root, className)} {...props}>
      <ScrollAreaUI.Viewport className={cx(css.viewport, viewPortClassName)}>
        {children}
      </ScrollAreaUI.Viewport>
      {orientation === 'horizontal' && <ScrollBar orientation="horizontal" />}
      {orientation === 'vertical' && <ScrollBar orientation="vertical" />}
      {orientation === 'both' && (
        <>
          <ScrollBar orientation="horizontal" />
          <ScrollBar orientation="vertical" />
        </>
      )}
      <ScrollAreaUI.Corner />
    </ScrollAreaUI.Root>
  )
)
ScrollArea.displayName = 'ScrollArea'

export { ScrollArea, ScrollBar }
