import {
  eachDayOfInterval,
  eachHourOfInterval,
  endOfToday,
  endOfWeek,
  startOfToday,
  startOfWeek
} from 'date-fns'

const today = startOfToday()
const firstDayOfWeek = startOfWeek(today, { weekStartsOn: 1 })
const lastDayOfWeek = endOfWeek(today, { weekStartsOn: 1 })

export const weekdays = eachDayOfInterval({
  start: firstDayOfWeek,
  end: lastDayOfWeek
}).map((day) => day.toISOString())

export const hours = eachHourOfInterval({
  start: startOfToday(),
  end: endOfToday()
}).map((date) => date.toISOString())
