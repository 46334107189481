import * as React from 'react'

import * as PopoverUI from '@radix-ui/react-popover'
import { cva, type VariantProps } from 'class-variance-authority'

import css from './Popover.module.css'

const Popover = PopoverUI.Root

const PopoverTrigger = PopoverUI.Trigger

const popoverStyles = cva(css.popover, {
  variants: {
    variant: {
      default: css.popover_default,
      glass: css.popover_glass
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverUI.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverUI.Content> &
    VariantProps<typeof popoverStyles>
>(({ className, align = 'center', sideOffset = 4, variant, ...props }, ref) => (
  <PopoverUI.Portal>
    <PopoverUI.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={popoverStyles({ variant, className })}
      {...props}
    />
  </PopoverUI.Portal>
))
PopoverContent.displayName = PopoverUI.Content.displayName

export { Popover, PopoverTrigger, PopoverContent }
