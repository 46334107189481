import { useEffect, useState } from 'react'

import { isTablet } from 'react-device-detect'
import { useNavigate, useParams } from 'react-router-dom'

import { useDeleteGuestMutation } from '@/entities/guest'
import { selectCanEditAddress, selectSelectedChainId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  type ButtonStyleProps,
  Icon
} from '@/shared/ui'

export function DeleteButton(props: ButtonStyleProps) {
  const { id: guestId } = useParams()

  const navigate = useNavigate()
  const chainId = useAppSelector(selectSelectedChainId)
  const [open, setOpen] = useState(false)
  const canEditAddress = useAppSelector(selectCanEditAddress)

  const toggleDialogHandler = () => {
    setOpen((prev) => !prev)
  }

  const [deleteGuest, { isLoading, isSuccess }] = useDeleteGuestMutation()

  const deleteHandler = () => {
    if (!guestId || Number.isNaN(+guestId) || !chainId) return
    deleteGuest({ chainId, guestId: +guestId })
  }

  useEffect(() => {
    if (isSuccess) navigate(-1)
  }, [isSuccess])

  return (
    <AlertDialog open={open}>
      <AlertDialogTrigger asChild>
        <Button
          onClick={toggleDialogHandler}
          disabled={!canEditAddress}
          {...props}
        >
          {!isTablet && 'Удалить'}
          <Icon name="trash" size={18} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Вы уверены ?</AlertDialogTitle>
          <AlertDialogDescription>
            Если вы&nbsp;завершите, то&nbsp;удалите информацию о&nbsp;госте, это
            действие будет невозможно отменить
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              onClick={deleteHandler}
            >
              Удалить
            </Button>
          </AlertDialogAction>
          <AlertDialogCancel disabled={isLoading} onClick={toggleDialogHandler}>
            Отмена
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
