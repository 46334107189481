import { type MouseEvent, type PropsWithChildren, useState } from 'react'

import { useUpdateReservationMutation } from '@/entities/reservation'
import {
  ReservationSortEnum,
  ReservationStatusEnum,
  selectReservationsSortType
} from '@/entities/reservations'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  type ButtonStyleProps
} from '@/shared/ui'

type CompleteReservationProps = ButtonStyleProps &
  PropsWithChildren & {
    reservationId: number | string | undefined
    className?: string
    reservationsQueryParams?: ReservationsParams
    updateReservationsList?: (
      reservationId: number,
      isDelete: boolean,
      data?: Partial<Reservation>
    ) => void
  }

export default function CompleteReservation({
  children,
  reservationId,
  className,
  reservationsQueryParams,
  updateReservationsList,
  ...props
}: CompleteReservationProps) {
  const addressId = useAppSelector(selectSelectedAddressId)
  const sortType = useAppSelector(selectReservationsSortType)
  const [open, setOpen] = useState(false)

  const [completeReservation, { isLoading }] = useUpdateReservationMutation()

  const toggleDialogHandler = (e: MouseEvent) => {
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  const completeReservationHandler = async (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation()
    if (!addressId || !reservationId || Number.isNaN(+reservationId)) return

    try {
      const data = await completeReservation({
        addressId,
        reservationId: +reservationId,
        reservationsQueryParams,
        body: { status: ReservationStatusEnum.completed }
      }).unwrap()
      setOpen(false)
      updateReservationsList?.(
        data.id,
        ReservationSortEnum.default !== sortType,
        { status: data.status }
      )
    } catch {
      // do nothing
    }
  }

  return (
    <AlertDialog open={open}>
      <AlertDialogTrigger asChild>
        <Button onClick={toggleDialogHandler} className={className} {...props}>
          {children}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent onClose={toggleDialogHandler}>
        <AlertDialogHeader>
          <AlertDialogTitle>Вы уверены ?</AlertDialogTitle>
          <AlertDialogDescription>
            Если вы&nbsp;завершите, то&nbsp;бронь перестанет быть активной
            и&nbsp;гость не&nbsp;сможет по&nbsp;ней зайти в&nbsp;зал
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction asChild>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              onClick={completeReservationHandler}
            >
              Завершить
            </Button>
          </AlertDialogAction>
          <AlertDialogCancel disabled={isLoading} onClick={toggleDialogHandler}>
            Отмена
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
