import { differenceInMinutes } from 'date-fns'

import { config } from '@/app/config'

const { SPLITTED_HOUR_INTERVAL } = config

export const computeSpans = (
  slotStart: Date,
  slotEnd: Date,
  startOfTimeline: Date
) => {
  const spanStartDiff = differenceInMinutes(slotStart, startOfTimeline)
  const spanEndDiff = differenceInMinutes(slotEnd, slotStart)

  const spanStart = 1 + spanStartDiff / SPLITTED_HOUR_INTERVAL
  const spanEnd = spanStart + spanEndDiff / SPLITTED_HOUR_INTERVAL

  return [spanStart, spanEnd]
}
