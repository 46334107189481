import { useAppSelector } from '@/shared/model'

import { selectReservation } from '../model/selectors'

export const useUpdateReservationSaveAvailable = (): [
  boolean,
  ReservationData
] => {
  const updatedReservation = useAppSelector(selectReservation)

  const isUpdateReservationSaveAvailable = Boolean(
    updatedReservation.personsCount &&
      updatedReservation.tables.length &&
      updatedReservation.date &&
      updatedReservation.start &&
      updatedReservation.end &&
      updatedReservation.guest
  )

  return [
    isUpdateReservationSaveAvailable,
    updatedReservation as ReservationData
  ]
}
