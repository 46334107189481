import { useEffect, useState } from 'react'

import { skipToken } from '@reduxjs/toolkit/dist/query'
import { type ItemProps, Virtuoso } from 'react-virtuoso'

import { INFINITE_SCROLL_RES } from '@/app/config'
import {
  MobileReservationSteps,
  setMobileReservationStep,
  setReservationGuest
} from '@/entities/create-update-reservation'
import { MobileSimpleSearchGuestCard, useGuestsQuery } from '@/entities/guest'
import {
  SearchGuestSteps,
  selectSearchGuestPhone,
  setSearchGuestPhone,
  setSearchGuestStep
} from '@/entities/search-guests'
import {
  selectSelectedAddressId,
  selectSelectedChainId
} from '@/entities/session'
import { useDebounce } from '@/shared/hooks'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { Error, InfoBox, Loading } from '@/shared/ui'

import css from './MobileGuestsSearchList.module.css'

type Context = {
  isContentLoading: boolean
}

function Header() {
  return <div className={css.header} />
}

function WrapperCard(props: ItemProps<Guest>) {
  return <div {...props} className={css['wrapper-card']} />
}

export function GuestsEmptyPlaceholder({ context }: { context?: Context }) {
  if (context?.isContentLoading) {
    return (
      <div className={css.placeholder}>
        <Loading />
      </div>
    )
  }

  return (
    <InfoBox className={css.placeholder} noIcon>
      Ничего не найдено
    </InfoBox>
  )
}

export default function MobileGuestsSearchList() {
  const dispatch = useAppDispatch()
  const chain = useAppSelector(selectSelectedChainId)
  const address = useAppSelector(selectSelectedAddressId)

  const phoneNum = useAppSelector(selectSearchGuestPhone)
  const debouncedSearchPhone = useDebounce(phoneNum)

  const [page, setPage] = useState(1)
  const [guests, setGuests] = useState<Guest[]>([])

  const { data, isError, isLoading, refetch, isFetching, isSuccess } =
    useGuestsQuery(
      chain && address
        ? {
            chain,
            address,
            limit: INFINITE_SCROLL_RES,
            offset: (page - 1) * INFINITE_SCROLL_RES,
            phone: debouncedSearchPhone,
            ordering: '-is_anonymous'
          }
        : skipToken
    )

  const isAnonymous = Boolean(data?.results[0]?.is_anonymous)

  const selectGuestHandler = (guest: Guest) => {
    dispatch(setReservationGuest(guest))
    dispatch(setMobileReservationStep(MobileReservationSteps.TWO))
  }

  const loadMoreRows = () => {
    if (data?.next) {
      setPage((prevPage) => prevPage + 1)
    }
  }

  useEffect(() => {
    setPage(1)
    setGuests([])
  }, [debouncedSearchPhone])

  useEffect(() => {
    if (isSuccess && data && !isFetching) {
      setGuests((prev) => [...prev, ...data.results])
    }
  }, [isSuccess, data])

  useEffect(() => {
    return () => {
      dispatch(setSearchGuestPhone(''))
    }
  }, [])

  const isContentLoading =
    (isLoading && page > 1) ||
    (isFetching && page === 1) ||
    phoneNum !== debouncedSearchPhone

  if (isError) {
    return <Error refetch={refetch} />
  }

  if (
    debouncedSearchPhone.length === 11 &&
    !isContentLoading &&
    !data?.results.length
  ) {
    const guest = {
      id: -1,
      last_name: 'гость',
      first_name: 'Новый',
      tags: [],
      comment: null,
      phone: debouncedSearchPhone
    }

    return (
      <div className={css.empty}>
        <MobileSimpleSearchGuestCard
          data-vaul-no-drag
          guest={guest}
          searchValue={phoneNum}
          isNewGuest
          onClick={() => dispatch(setSearchGuestStep(SearchGuestSteps.two))}
        />
      </div>
    )
  }

  if (!data?.results.length && !isContentLoading) {
    return <InfoBox noIcon>Ничего не найдено</InfoBox>
  }

  if (!phoneNum && !isContentLoading) {
    return (
      <>
        <div className={css.empty}>
          {isAnonymous && data && (
            <MobileSimpleSearchGuestCard
              data-vaul-no-drag
              guest={data.results[0]}
              searchValue={phoneNum}
              isAnonymous={isAnonymous}
              onClick={() => selectGuestHandler(data.results[0])}
            />
          )}
        </div>
        <InfoBox noIcon className={css.placeholder}>
          Введите номер телефона гостя
        </InfoBox>
      </>
    )
  }

  return (
    <div className={css.container}>
      <Virtuoso
        className={css.guests}
        totalCount={guests.length}
        defaultItemHeight={82}
        data={guests}
        endReached={loadMoreRows}
        increaseViewportBy={200}
        context={{ isContentLoading }}
        components={{
          Header,
          Item: WrapperCard,
          EmptyPlaceholder: GuestsEmptyPlaceholder
        }}
        itemContent={(_, guest) => (
          <MobileSimpleSearchGuestCard
            key={guest.id}
            data-vaul-no-drag
            guest={guest}
            searchValue={phoneNum}
            onClick={() => selectGuestHandler(guest)}
          />
        )}
      />
    </div>
  )
}
