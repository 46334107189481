import {
  type ComponentPropsWithoutRef,
  type PropsWithChildren,
  useEffect
} from 'react'

import { selectSelectedTables, selectTableToChangeTo } from '@/entities/map'
import { useUpdateReservationWithoutInvalidationMutation } from '@/entities/reservation'
import { selectSelectedAddressId } from '@/entities/session'
import { useAppSelector } from '@/shared/model'
import { Button, type ButtonStyleProps } from '@/shared/ui'

type MergeTablesButtonProps = ComponentPropsWithoutRef<'button'> &
  ButtonStyleProps &
  PropsWithChildren & {
    reservationId: number
    onSuccess?: () => void
  }

export function MergeTablesButton({
  children,
  reservationId,
  onSuccess,
  ...props
}: MergeTablesButtonProps) {
  const tableToChangeTo = useAppSelector(selectTableToChangeTo)
  const curTable = useAppSelector(selectSelectedTables)
  const addressId = useAppSelector(selectSelectedAddressId)

  const [update, { isLoading, isSuccess }] =
    useUpdateReservationWithoutInvalidationMutation()

  useEffect(() => {
    if (isSuccess) {
      onSuccess?.()
    }
  }, [isSuccess])

  const mergeTablesHandler = () => {
    if (!reservationId || Number.isNaN(+reservationId) || !addressId) return
    update({
      addressId,
      reservationId: +reservationId,
      body: {
        places: [...curTable, ...tableToChangeTo].map((table) => table.id)
      }
    })
  }

  return (
    <Button {...props} isLoading={isLoading} onClick={mergeTablesHandler}>
      {children}
    </Button>
  )
}
