/* eslint-disable boundaries/element-types */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { reservationApi } from '@/entities/reservation'

import { hallsApi } from '../api/hallsApi'

type HallSliceState = {
  selectedHall: number | null
  halls: Hall[]
}

const initialState: HallSliceState = {
  selectedHall: null,
  halls: []
}

export const hallSlice = createSlice({
  name: 'hall',
  initialState,
  reducers: {
    selectHall: (state, { payload }: PayloadAction<number>) => {
      state.selectedHall = payload
    },
    resetHalls: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        hallsApi.endpoints.halls.matchFulfilled,
        (state: HallSliceState, { payload }) => {
          state.halls = payload
          if (!state.selectedHall) {
            state.selectedHall = payload[0]?.id
          }
        }
      )
      .addMatcher(
        reservationApi.endpoints.reservation.matchFulfilled,
        (state: HallSliceState, { payload }) => {
          state.selectedHall = payload.hall_id
        }
      )
  }
})

export const { selectHall, resetHalls } = hallSlice.actions
