import * as React from 'react'

import * as DialogUI from '@radix-ui/react-dialog'
import { cva, cx, type VariantProps } from 'class-variance-authority'

import styles from './Dialog.module.css'

const Dialog = DialogUI.Root

const DialogTrigger = DialogUI.Trigger

const DialogPortal = DialogUI.Portal

const overlayVariants = cva(styles.overlay, {
  variants: {
    variant: {
      default: styles.overlay_default,
      blurred: styles.overlay_blurred
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

type VariantKey = keyof Pick<VariantProps<typeof overlayVariants>, 'variant'>
type OverlayVariant = VariantProps<typeof overlayVariants>[VariantKey]

type DialogOverlayProps = React.ComponentPropsWithoutRef<
  typeof DialogUI.Overlay
> &
  VariantProps<typeof overlayVariants>

type DialogOverlayRef = React.ElementRef<typeof DialogUI.Overlay>

const DialogOverlay = React.forwardRef<DialogOverlayRef, DialogOverlayProps>(
  ({ className, variant, ...props }, ref) => (
    <DialogUI.Overlay
      ref={ref}
      className={overlayVariants({ variant, className })}
      {...props}
    />
  )
)
DialogOverlay.displayName = 'DialogOverlay'

type DialogContentRef = React.ElementRef<typeof DialogUI.Content>

type DialogContentProps = React.ComponentPropsWithoutRef<
  typeof DialogUI.Content
> & {
  overlayVariant?: OverlayVariant
}

const DialogContent = React.forwardRef<DialogContentRef, DialogContentProps>(
  ({ className, children, overlayVariant, ...props }, ref) => (
    <DialogPortal>
      <DialogOverlay variant={overlayVariant} />
      <DialogUI.Content
        ref={ref}
        className={cx(styles.content, className)}
        {...props}
      >
        {children}
      </DialogUI.Content>
    </DialogPortal>
  )
)
DialogContent.displayName = 'DialogContent'

export { Dialog, DialogContent, DialogOverlay, DialogPortal, DialogTrigger }
