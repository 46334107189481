/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable boundaries/element-types */
import { type ComponentPropsWithoutRef, useEffect, useState } from 'react'

import 'swiper/css'

import { cx } from 'class-variance-authority'
import { A11y, Virtual } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CommentAuthor, useGuestCommentsQuery } from '@/entities/comments'
import { GuestTags } from '@/entities/guest'
import { Reservation } from '@/features/reservation'
import { formatName } from '@/shared/lib'
import {
  Badge,
  Button,
  Card,
  Icon,
  Skeleton,
  Vaul,
  VaulContent,
  VaulTrigger
} from '@/shared/ui'
import { GuestVaulContent } from '@/widgets/guest'

import css from './MobileReservationGuest.module.css'

type MobileReservationGuestProps = ComponentPropsWithoutRef<'div'> & {
  guest: BaseGuest
  personsCount: number
  comment?: string | null
}

export default function MobileReservationGuest({
  guest,
  className
}: MobileReservationGuestProps) {
  const { first_name, last_name, phone, tags, id } = guest
  const [commentsState, setCommentsState] = useState<UserComment[]>([])
  const {
    data: comments,
    isLoading,
    isError
  } = useGuestCommentsQuery({
    guestId: id
  })

  useEffect(() => {
    if (comments && comments.length !== 0) {
      setCommentsState((prev) => [...prev, ...comments])
    }
  }, [comments])

  return (
    <Card className={cx(css.card, className)}>
      <div className={css.card__header}>
        <h4 className={css.card__title}>{formatName(first_name, last_name)}</h4>
        <GuestTags tags={tags} showCount={1} onlyFirstOne />
      </div>
      <div style={{ overflow: 'hidden' }}>
        <Swiper
          modules={[A11y, Virtual]}
          virtual
          slidesPerView={commentsState.length === 1 ? 1 : 1.125}
          spaceBetween={8}
        >
          {isLoading || isError || !comments ? (
            <>
              {Array.from({ length: 3 }).map((_, idx) => (
                <SwiperSlide key={idx}>
                  <Skeleton className={css.skeleton} />
                </SwiperSlide>
              ))}
            </>
          ) : (
            <>
              {commentsState.map((comment) => (
                <SwiperSlide key={comment.id}>
                  <div className={css.card__comment}>
                    <CommentAuthor
                      isAuthor={comment.is_author}
                      author={comment.author}
                    />
                    <p className={css['card__comment-text']}>{comment.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </>
          )}
        </Swiper>
      </div>
      <div className={css.card__actions}>
        {phone ? (
          <Reservation.Phone
            variant="tertiary"
            size="lg"
            className={css.button}
            phone={phone}
            showNumber
          />
        ) : (
          <Badge className={css.badge}>Нет номера телефона</Badge>
        )}
        <Vaul>
          <VaulTrigger asChild>
            <Button
              variant="tertiary"
              size="lg"
              className={cx(css.button, css.button_icon)}
            >
              <Icon name="info" />
            </Button>
          </VaulTrigger>
          <VaulContent fullScreen>
            <GuestVaulContent guestId={id} />
          </VaulContent>
        </Vaul>
      </div>
    </Card>
  )
}
