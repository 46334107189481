/* eslint-disable import/no-duplicates */
import { useState } from 'react'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { DayPicker } from 'react-day-picker'

import { useClickOutside } from '@/shared/hooks'
import { nowWithTimezone } from '@/shared/lib'
import { Button } from '@/shared/ui'

import css from './TimelineDatePicker.module.css'

type TimelineDatePickerProps = {
  selectedDate: Date | undefined
  selectDate: (date: Date) => void
  disablePrevDays?: boolean
}

export default function TimelineDatePicker({
  selectedDate = nowWithTimezone(),
  selectDate,
  disablePrevDays = false
}: TimelineDatePickerProps) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const calendarRef = useClickOutside(() => setIsCalendarOpen(false))

  const selectDateHandler = (date: Date | undefined) => {
    if (date) {
      selectDate(date)
      setIsCalendarOpen(false)
    }
  }

  const toggleCalendarHandler = () => {
    setIsCalendarOpen((prev) => !prev)
  }

  return (
    <div ref={calendarRef} className={css.picker}>
      <Button
        className={css.picker__button}
        variant="transparent"
        size="sm"
        onClick={toggleCalendarHandler}
      >
        {format(selectedDate, 'dd.MM.yyyy')}
      </Button>
      {isCalendarOpen && (
        <div className={css.picker__calendar}>
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={selectDateHandler}
            disabled={disablePrevDays && { before: nowWithTimezone() }}
            locale={ru}
          />
        </div>
      )}
    </div>
  )
}
