import { z } from 'zod'

export const editGuestFormSchema = z.object({
  firstName: z
    .string({ required_error: 'Введите имя' })
    .min(1, { message: 'Слишком короткое имя' }),
  lastName: z
    .string({ required_error: 'Введите фамилию' })
    .min(1, { message: 'Слишком короткая фамилия' })
    .or(z.literal(''))
    .optional(),
  phone: z
    .string({ required_error: 'Введите номер телефона' })
    .min(1, { message: 'Введите номер телефона' }),
  tags: z.array(z.string())
})

export type EditGuestFormSchema = z.infer<typeof editGuestFormSchema>
