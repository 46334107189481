import { useState } from 'react'

import {
  Button,
  Icon,
  Vaul,
  VaulBody,
  VaulContent,
  VaulHeader,
  VaulTrigger
} from '@/shared/ui'

import { CreateGuestForm } from './CreateGuestForm'

import css from './CreateGuest.module.css'

export default function CreateGuest() {
  const [open, setOpen] = useState(false)

  return (
    <Vaul open={open} onOpenChange={setOpen}>
      <VaulTrigger asChild>
        <Button size="xs" variant="primary" className={css.create}>
          <Icon name="plus" /> Добавить гостя
        </Button>
      </VaulTrigger>
      <VaulContent fullScreen>
        <VaulHeader>
          <h4 className={css.header}>Новый гость</h4>
        </VaulHeader>
        <VaulBody>
          <CreateGuestForm successHandler={() => setOpen(false)} />
        </VaulBody>
      </VaulContent>
    </Vaul>
  )
}
