import { Reservation } from '@/features/create-update-reservation'
import { Icon } from '@/shared/ui'

import css from './GuestVisitsActions.module.css'

type GuestVisitsActionsProps = {
  guest: Guest
}

export default function GuestVisitsActions({ guest }: GuestVisitsActionsProps) {
  return (
    <div className={css.actions}>
      <Reservation.MobileInit size="xs" variant="primary" guest={guest}>
        Забронировать
        <Icon name="bookmark" />
      </Reservation.MobileInit>
    </div>
  )
}
