export const selectTimeSliceTables = (state: RootState) => state.map.tables
export const selectSelectedTables = (state: RootState) =>
  state.map.selectedTables

export const selectMapMode = (state: RootState) => state.map.mode
export const selectTableToChangeTo = (state: RootState) =>
  state.map.tableToChangeTo

export const selectIsTakeTable = (state: RootState) => state.map.isTakeTable
export const selectMapDatetime = (state: RootState) => state.map.datetime

export const selectShowDeposits = (state: RootState) => state.map.showDeposits
