/* eslint-disable boundaries/element-types */
import { type Dispatch, type SetStateAction } from 'react'

import { cx } from 'class-variance-authority'
import { isAfter, isBefore, sub } from 'date-fns'

import { config } from '@/app/config'
import { MobileGuestCard } from '@/entities/guest'
import { selectMapDatetime } from '@/entities/map'
import { MobileReservationTimeCard } from '@/entities/reservation'
import { ReservationStatusEnum } from '@/entities/reservations'
import { Reservation } from '@/features/reservation'
import { useAppSelector } from '@/shared/model'
import {
  Button,
  Icon,
  VaulBody,
  VaulContent,
  VaulFooter,
  VaulNested,
  VaulTrigger
} from '@/shared/ui'
import { ReservationVaulContent } from '@/widgets/reservation'

import { EmptyTable } from './EmptyTable'

import css from './TableCardContent.module.css'

const { MIN_RESERVATION_TIME_MIN } = config

type TableCardContentProps = {
  reservations: TimelineReservation[]
  setOpen: Dispatch<SetStateAction<boolean>>
  table: MapItem
}

export default function TableCardContent({
  reservations,
  setOpen,
  table
}: TableCardContentProps) {
  const curDatetime = useAppSelector(selectMapDatetime)

  const currentReservation = reservations.find(
    (res) =>
      isAfter(
        curDatetime,
        sub(res.start_date, { minutes: MIN_RESERVATION_TIME_MIN })
      ) && isBefore(curDatetime, res.end_date)
  )

  const isReservationNow = Boolean(currentReservation)

  const nextReservationTime = reservations.find((res) =>
    isAfter(res.start_date, curDatetime)
  )?.start_date

  const isReservationConfirmed =
    currentReservation?.status === ReservationStatusEnum.confirmed

  return (
    <VaulBody className={cx(css.body, { [css.body_empty]: !isReservationNow })}>
      <div className={css.content}>
        {isReservationNow && currentReservation && (
          <>
            {/* <div className={css.content__actions}>
              <Button size="xs">
                <Icon name="switch" />
                Пересадить
              </Button>
              <Button size="xs">
                <Icon name="merge" />
                Объединить
              </Button>
            </div> */}
            <MobileGuestCard
              guest={currentReservation.guest}
              personsCount={currentReservation.persons_count}
              comment={currentReservation.comment}
            />
            <MobileReservationTimeCard
              status={currentReservation.status}
              startDate={currentReservation.start_date}
              endDate={currentReservation.end_date}
            />
            <VaulFooter>
              <div
                className={cx(css.reservation__actions, {
                  [css.reservation__actions_two]: isReservationConfirmed
                })}
              >
                {isReservationConfirmed && (
                  <Reservation.InProgress
                    reservationId={currentReservation.id}
                    variant="primary"
                    size="xs"
                  >
                    <Icon name="restaurant" size={18} />
                    Гости пришли
                  </Reservation.InProgress>
                )}
                <VaulNested>
                  <VaulTrigger asChild>
                    <Button
                      variant={isReservationConfirmed ? 'default' : 'primary'}
                      size="xs"
                    >
                      <Icon name="bookmark" />
                      <span className={css.reservation__booking}>
                        Бронь №{currentReservation.id}
                      </span>
                    </Button>
                  </VaulTrigger>
                  <VaulContent fullScreen>
                    <ReservationVaulContent
                      reservationId={currentReservation.id}
                    />
                  </VaulContent>
                </VaulNested>
                {/* <Reservation.Delete
                  variant="red"
                  size="icon"
                  reservationId={currentReservation.id}
                >
                  <Icon name="trash" />
                </Reservation.Delete> */}
              </div>
            </VaulFooter>
          </>
        )}
        {!isReservationNow && (
          <EmptyTable
            table={table}
            setOpen={setOpen}
            nextReservationTime={nextReservationTime}
          />
        )}
      </div>
    </VaulBody>
  )
}
